import React, { useState, useContext, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faEdit, faCheck, faCheckDouble, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { Card, Col, Container, Row, Tab, Tabs, Form, Button, Table, Stack, Modal, Spinner } from 'react-bootstrap';
import { CLUB_URL, COUNTRY_URL, FOOTBALLPLAYERENDORSEMENTREQUEST_URL, GET_INSTITUITION_URL, LEAGUES_URL, PLAYERHISTORYCREATE_URL, PLAYERHISTORYTEAMLEAGUEUPDATE_URL, PLAYERHISTORYUPDATE_URL, PLAYERHISTORY_URL, PLAYER_URL, SEARCHCOACH_URL, SEARCHPLAYER_URL, TEAMS_URL, USER_URL, VIDEOCLIP_URL, optionsPosition, optionsPreferredFoot } from '../../services/Constants';
import AuthContext from '../../context/AuthContext'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { ProfileBuilderNavBar } from '../../components/ProfileBuilderNavBar';
import axios from 'axios';

export default function EditPlayerProfile() {

  let {
    user,
    authTokens,
    notifyRegistrationPersonalInfo,
    notifyCustomSuccessMessage,
    notifyError,
    notifyAuthenticateError,
    notifyCustomErrorMessage
  } = useContext(AuthContext)

  const navigate = useNavigate();

  const [key, setKey] = useState('general');

  // State variable of search user profile in Navbar

  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  // const [flag, setFlag] = useState(false);

  // State variable for managing search lov filter

  const [isTeamLOVloading, setIsTeamLOVLoading] = useState(false);
  const [searchTeamLOVData, setSearchTeamLOVData] = useState([]);
  const [isLeagueLOVloading, setIsLeagueLOVLoading] = useState(false);
  const [searchLeagueLOVData, setSearchLeagueLOVData] = useState([]);
  const [isCountryLOVloading, setIsCountryLOVLoading] = useState(false);
  const [searchCountryLOVData, setSearchCountryLOVData] = useState([]);

  // State variable for managing Select options 

  const [countryInfo, setCountryInfo] = useState([]);
  const [optionsCountry, setOptionsCountry] = useState([]);
  const [countryName, setCountryName] = useState('');
  const [leaguesInfo, setLeaguesInfo] = useState([]);
  const [optionsLeague, setOptionsLeague] = useState([]);
  const [teamsInfo, setTeamsInfo] = useState([]);
  const [optionsTeam, setOptionsTeam] = useState([]);

  const optionsRegionType = [
    { value: 'International', label: 'International' },
    { value: 'Domestic', label: 'Domestic' }
  ];

  // State variable to manage form validation

  const [validatedPlayerInfo, setValidatedPlayerInfo] = useState(false);
  const [validatedCareerHistory, setValidatedCareerHistory] = useState(false);
  const [validatedVideos, setValidatedVideos] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  // State varialabe to manage modal

  const [showAddGeneralModal, setShowAddGeneralModal] = useState(false)
  const [showEditGeneralModal, setShowEditGeneralModal] = useState(false)
  const [showAddCareerHistoryModal, setShowAddCareerHistoryModal] = useState(false)
  const [showEditCareerHistoryModal, setShowEditCareerHistoryModal] = useState(false)
  const [showAddVideosModal, setShowAddVideosModal] = useState(false)
  const [showEditVideosModal, setShowEditVideosModal] = useState(false)
  // const [showModalEndorsement, setShowModalEndorsement] = useState(false);

  // State variable to manage user data

  const [userPersonalData, setUserPersonalData] = useState('')

  // State variable to manage sport profile type

  const [selectedPlayerSwitchOption, setSelectedPlayerSwitchOption] = useState(false);
  const [selectedCoachSwitchOption, setSelectedCoachSwitchOption] = useState(false);
  const [selectedAgentSwitchOption, setSelectedAgentSwitchOption] = useState(false);

  // State variable for managing player info

  const [playerInfo, setPlayerInfo] = useState('')
  const [playerId, setPlayerId] = useState('')
  const [primaryPosition, setPrimaryPosition] = useState('')
  const [secondaryPosition, setSecondaryPosition] = useState('')
  const [topSpeed, setTopSpeed] = useState('')
  const [myWorth, setMyWorth] = useState(null)
  const [prefferedFoot, setPrefferedFoot] = useState('')
  // const [teamName, setTeamName] = useState('')
  // const [injuryHistory, setInjuryHistory] = useState('')
  const [checked, setChecked] = useState(false);

  const [positionError, setPositionError] = useState('');
  const [preferredFootError, setPreferredFootError] = useState('');
  // const [clubError, setClubError] = useState('');

  // State variable for managing career history

  const [playerClub, setPlayerClub] = useState([])
  const [playerClubId, setPlayerClubId] = useState(null)
  const [teamName, setTeamName] = useState('')
  const [teamId, setTeamId] = useState(null)
  // const [period, setPeriod] = useState('')
  const [fromYear, setFromYear] = useState('')
  const [toYear, setToYear] = useState('')
  const [summary, setSummary] = useState('')
  const [gamesPlayed, setGamesPlayed] = useState('')
  const [goalsScored, setGoalsScored] = useState('')
  const [assists, setAssists] = useState('')
  const [passes, setPasses] = useState('')
  const [goalsSaved, setGoalsSaved] = useState('')
  const [interceptionsPerGame, setInterceptionsPerGame] = useState('')
  const [tackelsPerGame, setTackelsPerGame] = useState('')
  const [shotsPerGame, setShotsPerGame] = useState('')
  const [keyPassesPerGame, setKeyPassesPerGame] = useState('')
  const [dribblesPerGame, setDribblesPerGame] = useState('')
  const [cleanSheetsPerGame, setCleanSheetsPerGame] = useState('')
  const [yellowCard, setYellowCard] = useState('')
  const [redCard, setRedCard] = useState('')
  const [keyAchievements, setKeyAchievements] = useState('')
  const [leagueType, setLeagueType] = useState('')
  const [leagueName, setLeagueName] = useState('')
  const [leagueId, setLeagueId] = useState(null)
  const [currentRequestStatus, setCurrentRequestStatus] = useState('')
  const [remarks, setRemarks] = useState('')

  const [typeError, setTypeError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [teamError, setTeamError] = useState('');
  const [leagueError, setLeagueError] = useState('');
  // const [periodError, setPeriodError] = useState('');
  const [fromYearError, setFromYearError] = useState('');
  const [toYearError, setToYearError] = useState('');
  const [gamesPlayedError, setGamesPlayedError] = useState('');

  // State variable to manage video clip

  const [videoId, setVideoId] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [videoTitleError, setVideoTitleError] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [videoUrlError, setVideoUrlError] = useState('');
  const [videoClip, setVideoClip] = useState([]);

  // State variable for managing endorsement request

  const [profileTypeError, setProfileTypeError] = useState('');
  const [checkedEndorsement, setCheckedEndorsement] = useState(false);
  // const [personNameEndorsement, setPersonNameEndorsement] = useState([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
  const [personNameEndorsement, setPersonNameEndorsement] = useState('')
  const [sportPersonData, setSportPersonData] = useState([])
  const [personNameError, setPersonNameError] = useState('')
  const [emailId, setEmailId] = useState('')
  const [comments, setComments] = useState('')
  const [playerEndorsementRequest, setPlayerEndorsementRequest] = useState([])
  const [inputFieldDisabled, setInputFieldDisabled] = useState(false)
  const [isEmailFieldVisible, setIsEmailFieldVisible] = useState(false)
  const [isSpinnerLoadingVisible, setIsSpinnerLoadingVisible] = useState(false)

  // function responsible of search data with navigation

  const navigateToSearchDataWithParams = (item) => {
    console.log('navigateToSearchDataWithParams');
    navigate(`/user/viewprofile?user_id=${item.id}`);
  };

  const navigateToSearchAllWithParams = (item) => {
    console.log('navigateToSearchAllWithParams');
    console.log('Search Data:', item);
    navigate("/searchall", { state: { item } });
  };

  // function responsible for getting data from backend

  const getCountries = async () => {
    // e.preventDefault();
    console.log('get countries');
    let response = fetch(`${COUNTRY_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    console.log('response:', response)
    const data = (await response).json()
    console.log('data:', data)
    const status = (await response).status
    if (status === 200) {
      const countryData = (await data);
      console.log('countryData: ', countryData);
      setCountryInfo(countryData);
      const processedOptions = countryData.map(item => ({
        label: item.country_name,
        value: item.country_name
      }));
      console.log('processed Options:', processedOptions)
      setOptionsCountry(processedOptions);
    } else {
      notifyError()
    }
  }

  const getTeams = async () => {
    // e.preventDefault();
    console.log('get leagues');
    let response = fetch(`${TEAMS_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    console.log('response:', response)
    const data = (await response).json()
    console.log('data:', data)
    const status = (await response).status
    if (status === 200) {
      const teamData = (await data);
      console.log('teamData: ', teamData);
      setTeamsInfo(teamData);
      const processedOptions = teamData.map(item => ({
        label: item.club_name,
        value: item.reg_id
      }));
      console.log('processed Options:', processedOptions)
      setOptionsTeam(processedOptions);
    } else {
      notifyError()
    }
  }

  const getLeagues = async () => {
    // e.preventDefault();
    console.log('get leagues');
    let response = fetch(`${LEAGUES_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    console.log('response:', response)
    const data = (await response).json()
    console.log('data:', data)
    const status = (await response).status
    if (status === 200) {
      const leagueData = (await data);
      console.log('leagueData: ', leagueData);
      setLeaguesInfo(leagueData);
      const processedOptions = leagueData.map(item => ({
        label: item.league_name,
        value: item.id
      }));
      console.log('processed Options:', processedOptions)
      setOptionsLeague(processedOptions);
    } else {
      notifyError()
    }
  }

  const getPlayerInfo = async (id) => {
    setPlayerId(id)
    let response = fetch(`${PLAYER_URL}/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    console.log('response:', response)
    const data = (await response).json()
    console.log('data:', data)
    const player = (await data)
    console.log('player info:', player)
    const status = (await response).status
    if (status === 200) {
      setTopSpeed(player.top_speed)
      setMyWorth(player.my_worth)
      setChecked(player.is_open_for_hiring)
      setPrefferedFoot(player.preferred_foot)
      setPrimaryPosition(player.primary_position)
      setSecondaryPosition(player.secondary_position)
    } else {
      notifyError()
    }
  }

  const getPlayerClubInfo = async (id) => {
    setPlayerClubId(id)
    let response = fetch(`${CLUB_URL}/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    console.log('response:', response)
    const data = (await response).json()
    console.log('data:', data)
    const club = (await data)
    console.log('Club History:', club)
    const status = (await response).status
    if (status === 200) {
      // setLeagueType({ "label": club.type, "value": club.type })
      setLeagueType(club.league_type)
      // setCountryName({ "label": club.country, "value": club.country })
      setCountryName(club.country_name)
      // setLeagueName({ "label": club.league, "value": club.league })
      setLeagueName(club.league_name)
      setLeagueId(club.league_id)
      setTeamName(club.club_name)
      setTeamId(club.club_id)
      setFromYear(club.from_year)
      setToYear(club.to_year)
      setGamesPlayed(club.games_played)
      setGoalsScored(club.club_goals)
      setAssists(club.club_assists)
      setPasses(club.club_passes)
      setGoalsSaved(club.club_saved_goals)
      setInterceptionsPerGame(club.interceptions_per_game)
      setTackelsPerGame(club.takles_per_game)
      setShotsPerGame(club.shots_per_game)
      setKeyPassesPerGame(club.key_passes_per_game)
      setDribblesPerGame(club.dribles_completed_per_game)
      setCleanSheetsPerGame(club.clean_sheets_per_game)
      setYellowCard(club.club_yellow_card)
      setRedCard(club.club_red_card)
      setKeyAchievements(club.achievements)
      setSummary(club.summary)
      setCurrentRequestStatus(club.status)
      setRemarks(club.remarks)
      // console.log('endorsement request: ', club.endorsement_request)
      if (club.endorsement_request.length > 0) {
        setInputFieldDisabled(true)
        setCheckedEndorsement(true)
        setPlayerEndorsementRequest(club.endorsement_request)
      } else {
        setInputFieldDisabled(false)
        setCheckedEndorsement(false)
        setPlayerEndorsementRequest([])
      }
      getCountries();
      getTeams();
      getLeagues();
    } else {
      notifyError();
    }
  }

  const getVideoClipInfo = async (id) => {
    setVideoId(id)
    let response = fetch(`${VIDEOCLIP_URL}/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const video = (await data)
    // console.log('video clip:', video)
    const status = (await response).status
    if (status === 200) {
      setVideoTitle(video.title)
      setVideoUrl(video.clip_url)
    } else {
      notifyError()
    }
  }

  const getInstitution = async () => {
    let response = fetch(`${GET_INSTITUITION_URL}${teamId}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response);
    // const data = (await response).json()
    // console.log('data:', data)
    const status = (await response).status;
    if (status === 200) {
      // const user = (await data)
      setIsEmailFieldVisible(false);
      // console.log('user found');
    } else if (status === 404) {
      setIsEmailFieldVisible(true);
    } else {
      notifyError();
    }
  }

  // functions responsible for adding data in backend

  const handleAddPlayerSubmit = async (e) => {
    e.preventDefault();
    setValidatedPlayerInfo(true);
    // setIsLoading(true);
    const formData = new FormData();
    formData.append("user", user.user_id);
    if (primaryPosition === '') {
      setPositionError("Primary position can't be empty.")
      return
    }
    else
      formData.append("primary_position", primaryPosition);
    if (prefferedFoot === '') {
      setPreferredFootError("Preferred foot can't be empty.")
      return
    }
    else
      formData.append("preferred_foot", prefferedFoot);
    formData.append("secondary_position", secondaryPosition);
    if (topSpeed !== null)
      formData.append("top_speed", topSpeed);
    // if (teamName === '') {
    //   setClubError("Club Name can't be empty.")
    //   return
    // }
    // else
    //   formData.append("current_club", teamName);
    if (myWorth !== null)
      formData.append("my_worth", myWorth);
    // formData.append("injury_history", injuryHistory);
    formData.append("is_open_for_hiring", checked);
    let response = fetch(`${PLAYER_URL}/`, {
      method: "POST",
      body: formData,
    });
    console.log('response:', response)
    let data = (await response).json()
    console.log('data:', data)
    let status = (await response).status
    console.log('status:', status)
    if (status === 201) {
      notifyRegistrationPersonalInfo()
      setPrimaryPosition('')
      // setDefaultPrimaryPosition('')
      setSecondaryPosition('')
      // setDefaultSecondaryPosition('')
      setTopSpeed(null)
      setPrefferedFoot('')
      // setDefaultPreferredFoot('')
      // setInjuryHistory('')
      // setTeamName('')
      // setDefaultTeamName('')
      setMyWorth(null)
      setChecked(false)
      setValidatedPlayerInfo(false)
      setShowAddGeneralModal(false)
    } else {
      notifyError()
    }
  };

  // const handleAddPlayerClubHistory = async (e) => {
  //   // console.log('handleAddPlayerClubHistory function called');
  //   setValidatedCareerHistory(true);
  //   const formData = new FormData();
  //   formData.append("sport_type", userPersonalData.sport_type);
  //   if (playerInfo) {
  //     formData.append("players", playerInfo.id);
  //   }
  //   if (leagueType !== '') {
  //     formData.append("league_type", leagueType);
  //     setTypeError('');
  //   } else {
  //     setTypeError('Type cannot be left empty.');
  //     return;
  //   }
  //   if (leagueType === 'Domestic') {
  //     if (countryName === '') {
  //       setCountryError('Country cannot be left empty.');
  //       return;
  //     }
  //     else {
  //       setCountryError('');
  //       formData.append("country_name", countryName);
  //     }
  //   } else {
  //     setCountryError('');
  //     formData.append("country_name", '');
  //   }
  //   if (leagueName !== '') {
  //     formData.append("league_name", leagueName);
  //   }
  //   if (leagueId !== null) {
  //     formData.append("league_id", leagueId);
  //   }else{
  //     formData.append("league_id", '');
  //   }
  //   if (teamName !== '') {
  //     // formData.append("club_id", teamId);
  //     formData.append("club_name", teamName);
  //     setTeamError('');
  //   } else {
  //     setTeamError('Team cannot be left empty.');
  //     return;
  //   }
  //   if (teamId !== null) {
  //     formData.append("club_id", teamId);
  //   } else {
  //     formData.append("club_id", 0);
  //   }
  //   if (fromYear !== '') {
  //     formData.append("from_year", fromYear);
  //     setFromYearError('');
  //   } else {
  //     setFromYearError('From year cannot be left empty.');
  //     return;
  //   }
  //   if (toYear !== '') {
  //     formData.append("to_year", toYear);
  //     setToYearError('');
  //   } else {
  //     setToYearError('To year cannot be left empty.');
  //     return;
  //   }
  //   if (gamesPlayed !== '') {
  //     formData.append("games_played", gamesPlayed);
  //     setGamesPlayedError('');
  //   } else {
  //     setGamesPlayedError('Games played cannot be left empty.');
  //     return;
  //   }
  //   if (goalsScored !== '') {
  //     formData.append("club_goals", goalsScored);
  //   } else {
  //     formData.append("club_goals", 0);
  //   }
  //   if (assists !== '') {
  //     formData.append("club_assists", assists);
  //   } else {
  //     formData.append("club_assists", 0);
  //   }
  //   if (passes !== '') {
  //     formData.append("club_passes", passes);
  //   } else {
  //     formData.append("club_passes", 0);
  //   }
  //   if (goalsSaved !== '') {
  //     formData.append("club_saved_goals", goalsSaved);
  //   } else {
  //     formData.append("club_saved_goals", 0);
  //   }
  //   if (interceptionsPerGame !== '') {
  //     formData.append("interceptions_per_game", interceptionsPerGame);
  //   } else {
  //     formData.append("interceptions_per_game", 0);
  //   }
  //   if (tackelsPerGame !== '') {
  //     formData.append("takles_per_game", tackelsPerGame);
  //   } else {
  //     formData.append("takles_per_game", 0);
  //   }
  //   if (shotsPerGame !== '') {
  //     formData.append("shots_per_game", shotsPerGame);
  //   } else {
  //     formData.append("shots_per_game", 0);
  //   }
  //   if (keyPassesPerGame !== '') {
  //     formData.append("key_passes_per_game", keyPassesPerGame);
  //   } else {
  //     formData.append("key_passes_per_game", 0);
  //   }
  //   if (dribblesPerGame !== '') {
  //     formData.append("dribles_completed_per_game", dribblesPerGame);
  //   } else {
  //     formData.append("dribles_completed_per_game", 0);
  //   }
  //   if (cleanSheetsPerGame != '') {
  //     formData.append("clean_sheets_per_game", cleanSheetsPerGame);
  //   } else {
  //     formData.append("clean_sheets_per_game", 0);
  //   }
  //   if (yellowCard !== '') {
  //     formData.append("club_yellow_card", yellowCard);
  //   } else {
  //     formData.append("club_yellow_card", 0);
  //   }
  //   if (redCard !== '') {
  //     formData.append("club_red_card", redCard);
  //   } else {
  //     formData.append("club_red_card", 0);
  //   }
  //   formData.append("summary", summary);
  //   formData.append("achievements", keyAchievements);
  //   if (personNameEndorsement.length > 0 && personNameEndorsement[0].type !== '') {
  //     formData.append("status", 'pending');
  //     formData.append("endorsement_request", personNameEndorsement);
  //   }else{
  //     formData.append("endorsement_request", []);
  //   }
  //   formData.append("remarks", remarks);
  //   if (teamId === null && teamName !== '' && leagueId === null && leagueName !== '') {
  //     formData.append("flag", 'teamleague');
  //     formData.append("reg_id", uuidv4());
  //     let response = fetch(`${PLAYERHISTORY_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 201) {
  //       notifyRegistrationPersonalInfo()
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowAddCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName !== '') {
  //     formData.append("flag", 'league');
  //     let response = fetch(`${PLAYERHISTORY_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 201) {
  //       notifyRegistrationPersonalInfo()
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowAddCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else if (teamId === null && teamName !== '' && leagueId !== null && leagueName !== '') {
  //     formData.append("flag", 'team');
  //     formData.append("reg_id", uuidv4());
  //     // formData.append("country_name", userPersonalData.citizenship);
  //     let response = fetch(`${PLAYERHISTORY_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 201) {
  //       notifyRegistrationPersonalInfo()
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowAddCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else {
  //     let response = fetch(`${PLAYERHISTORYCREATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 201) {
  //       notifyRegistrationPersonalInfo()
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowAddCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   }
  // };

  const handleAddPlayerClubHistory = async (e) => {
    // console.log('handleAddPlayerClubHistory function called');
    setValidatedCareerHistory(true);
    // const formData = new FormData();
    // formData.append("sport_type", userPersonalData.sport_type);
    // if (playerInfo) {
    //   formData.append("players", playerInfo.id);
    // }

    if (fromYear !== '') {
      // formData.append("from_year", fromYear);
      setFromYearError('');
    } else {
      setFromYearError('From year cannot be empty.');
      return;
    }
    if (toYear !== '') {
      // formData.append("to_year", toYear);
      setToYearError('');
    } else {
      setToYearError('To year cannot be empty.');
      return;
    }
    if (leagueType !== '') {
      // formData.append("league_type", leagueType);
      setTypeError('');
    } else {
      setTypeError('Type cannot be empty.');
      return;
    }
    if (leagueType === 'Domestic') {
      if (countryName === '') {
        setCountryError('Country cannot be empty.');
        return;
      }
      else {
        setCountryError('');
        // formData.append("country_name", countryName);
      }
    } else {
      setCountryError('');
      // formData.append("country_name", '');
    }
    if (teamName !== '') {
      // formData.append("club_id", teamId);
      // formData.append("club_name", teamName);
      setTeamError('');
    } else {
      setTeamError('Team cannot be empty.');
      return;
    }
    // if (leagueName !== '') {
    //   setLeagueError('');
    // } else {
    //   setLeagueError('League Name cannot be empty.');
    //   return;
    // }
    // if (teamId !== null) {
    //   formData.append("club_id", teamId);
    // } else {
    //   formData.append("club_id", 0);
    // }

    if (gamesPlayed !== '') {
      // formData.append("games_played", gamesPlayed);
      setGamesPlayedError('');
    } else {
      setGamesPlayedError('Games played cannot be empty.');
      return;
    }
    if (checkedEndorsement && isEmailFieldVisible) {
      if (emailId === '')
        return;
    }
    setIsSpinnerLoadingVisible(true);
    if (teamId === null && teamName !== '' && leagueId === null && leagueName !== '') {
      // formData.append("flag", 'teamleague');
      // formData.append("reg_id", uuidv4());
      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'teamleague',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            player_career_history: ''
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'teamleague',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORY_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          setCurrentRequestStatus('')
          setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          getLeagues()
          getTeams()
          setPersonNameEndorsement('')
          setCheckedEndorsement(false)
          setEmailId('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName !== '') {
      // formData.append("flag", 'league');
      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'league',
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            player_career_history: '',
            reg_id: teamId
          },
        };
      } else {
        data = {
          career_history: {
            flag: 'league',
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: '',
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORY_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          setCurrentRequestStatus('')
          setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          getLeagues()
          getTeams()
          setPersonNameEndorsement('')
          setCheckedEndorsement(false)
          setEmailId('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName === '') {
      // formData.append("flag", 'league');
      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'leaguenull',
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            player_career_history: '',
            reg_id: teamId
          },
        };
      } else {
        data = {
          career_history: {
            flag: 'leaguenull',
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: '',
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORY_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          setCurrentRequestStatus('')
          setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          getLeagues()
          getTeams()
          setPersonNameEndorsement('')
          setCheckedEndorsement(false)
          setEmailId('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId === null && teamName !== '' && leagueId !== null && leagueName !== '') {
      // formData.append("flag", 'team');
      // formData.append("reg_id", uuidv4());
      // formData.append("country_name", userPersonalData.citizenship);
      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'team',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            player_career_history: ''
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'team',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORY_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          setCurrentRequestStatus('')
          setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          getLeagues()
          getTeams()
          // setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
          setPersonNameEndorsement('')
          setCheckedEndorsement(false)
          setEmailId('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId === null && teamName !== '' && leagueId === null && leagueName === '') {
      // formData.append("flag", 'team');
      // formData.append("reg_id", uuidv4());
      // formData.append("country_name", userPersonalData.citizenship);
      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'teamleaguenull',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            player_career_history: ''
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'teamleaguenull',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORY_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          getLeagues()
          getTeams()
          // setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
          setPersonNameEndorsement('')
          setCheckedEndorsement(false)
          setEmailId('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    } else {
      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            player_career_history: '',
            reg_id: teamId
          }
        };
      } else {
        data = {
          career_history: {
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYCREATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          setCurrentRequestStatus('')
          setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          getLeagues()
          getTeams()
          setPersonNameEndorsement('')
          setCheckedEndorsement(false)
          setEmailId('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });
    }
  };

  const handleSubmitVideoClip = async (event) => {
    event.preventDefault();
    setValidatedVideos(true)
    const formData = new FormData();
    if (videoTitle === '') {
      setVideoTitleError('Video title cannot be empty')
      return;
    } else {
      setVideoTitleError('')
      formData.append("title", videoTitle);
    }
    if (videoUrl === '') {
      setVideoUrlError('Video url cannot be empty')
      return;
    } else {
      setVideoUrlError('')
      formData.append("clip_url", videoUrl);
    }
    formData.append("profile_type", 'player');
    formData.append("user_id", user.user_id);
    let response = fetch(`${VIDEOCLIP_URL}/`, {
      method: "POST",
      body: formData,
    });
    console.log('response:', response)
    let data = (await response).json()
    console.log('data:', data)
    let status = (await response).status
    console.log('status:', status)
    if (status === 201) {
      notifyRegistrationPersonalInfo()
      setVideoTitle('')
      setVideoTitleError('')
      setVideoUrl('')
      setVideoUrlError('')
      // setIsLoading(true)
      setValidatedVideos(false)
      setShowAddVideosModal(false)
    } else {
      notifyError()
    }
  }

  // fuctions responsible for deleting data from backend

  const handlePlayerClubDelete = async (id) => {
    console.log('Handle Profile Delete function called');
    let response = fetch(`${CLUB_URL}/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyRegistrationPersonalInfo();
      const newClubList = playerClub.filter((club) => club.id !== id);
      setPlayerClub(newClubList)
    } else {
      notifyError()
    }
  }

  const handlePlayersEndorsementRequestDelete = async (id) => {
    // console.log('Handle Profile Delete function called');
    let response = fetch(`${FOOTBALLPLAYERENDORSEMENTREQUEST_URL}${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyCustomSuccessMessage('Information deleted successfully.');
      const newList = playerEndorsementRequest.filter((agent) => agent.id !== id);
      setPlayerEndorsementRequest(newList)
    } else {
      notifyError()
    }
  }

  const handleVideoClipDelete = async (id) => {
    console.log('Handle Video Delete function called');
    let response = fetch(`${VIDEOCLIP_URL}/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyCustomSuccessMessage('Successfully deleted the data!')
      const newVideoClip = videoClip.filter((clip) => clip.id !== id);
      setVideoClip(newVideoClip)
      // setIsLoading(true)
    } else {
      notifyError()
    }
  }

  // function responsible for updating data in backend

  const handleUpdatePlayer = async (e) => {
    e.preventDefault();
    setValidatedPlayerInfo(true);
    // setIsLoading(true);
    const formData = new FormData();
    if (primaryPosition === '') {
      setPositionError("Primary position can't be empty.")
      return
    }
    else
      formData.append("primary_position", primaryPosition);
    if (prefferedFoot === '') {
      setPreferredFootError("Preferred foot can't be empty.")
      return
    }
    else
      formData.append("preferred_foot", prefferedFoot);
    formData.append("secondary_position", secondaryPosition);
    if (topSpeed !== null)
      formData.append("top_speed", topSpeed);
    // if (teamName === '') {
    //   setClubError("Club Name can't be empty.")
    //   return
    // }
    // else
    //   formData.append("current_club", teamName);
    if (myWorth !== null)
      formData.append("my_worth", myWorth);
    // formData.append("injury_history", injuryHistory);
    formData.append("is_open_for_hiring", checked);
    let response = fetch(`${PLAYER_URL}/${playerId}/`, {
      method: "PATCH",
      body: formData,
    });
    console.log('response:', response)
    let data = (await response).json()
    console.log('data:', data)
    let status = (await response).status
    console.log('status:', status)
    if (status === 200) {
      notifyRegistrationPersonalInfo()
      setPrimaryPosition('')
      setSecondaryPosition('')
      setTopSpeed(null)
      setPrefferedFoot('')
      // setInjuryHistory('')
      // setTeamName('')
      setMyWorth(null)
      setChecked(false)
      // setDefaultPreferredFoot('')
      // setDefaultPrimaryPosition('')
      // setDefaultSecondaryPosition('')
      // setDefaultTeamName('')
      setValidatedPlayerInfo(false)
      setShowEditGeneralModal(false)
    } else {
      notifyError()
    }
  };

  // const handleUpdatePlayerClubHistory = async (e) => {
  //   // e.preventDefault();
  //   // setIsLoading(true);
  //   setValidatedCareerHistory(true);
  //   const formData = new FormData();
  //   formData.append("sport_type", userPersonalData.sport_type);
  //   formData.append("id", playerClubId);
  //   if (leagueType !== '') {
  //     formData.append("league_type", leagueType);
  //     setTypeError('');
  //   } else {
  //     setTypeError('Type cannot be left empty.');
  //   }
  //   if (leagueType === 'Domestic') {
  //     if (countryName === '') {
  //       setCountryError('Country cannot be left empty.');
  //       return;
  //     }
  //     else {
  //       setCountryError('');
  //       formData.append("country_name", countryName);
  //     }
  //   } else {
  //     setCountryError('');
  //     formData.append("country_name", '');
  //   }
  //   formData.append("league_name", leagueName);
  //   if (leagueId !== null) {
  //     formData.append("league_id", leagueId);
  //   } else {
  //     formData.append("league_id", '');
  //   }
  //   if (teamName !== '') {
  //     // formData.append("club_id", teamId);
  //     formData.append("club_name", teamName);
  //     setTeamError('');
  //   } else {
  //     setTeamError('Team cannot be left empty.');
  //     return;
  //   }
  //   if (teamId !== null) {
  //     formData.append("club_id", teamId);
  //   } else {
  //     formData.append("club_id", 0);
  //   }
  //   if (fromYear !== '') {
  //     formData.append("from_year", fromYear);
  //     setFromYearError('');
  //   } else {
  //     setFromYearError('From year cannot be left empty.');
  //     return;
  //   }
  //   if (toYear !== '') {
  //     formData.append("to_year", toYear);
  //     setToYearError('');
  //   } else {
  //     setToYearError('To year cannot be left empty.');
  //     return;
  //   }
  //   if (gamesPlayed !== '') {
  //     formData.append("games_played", gamesPlayed);
  //     setGamesPlayedError('');
  //   } else {
  //     setGamesPlayedError('Games played cannot be left empty.');
  //     return;
  //   }
  //   if (goalsScored !== '') {
  //     formData.append("club_goals", goalsScored);
  //   } else {
  //     formData.append("club_goals", 0);
  //   }
  //   if (assists !== '') {
  //     formData.append("club_assists", assists);
  //   } else {
  //     formData.append("club_assists", 0);
  //   }
  //   if (passes !== '') {
  //     formData.append("club_passes", passes);
  //   } else {
  //     formData.append("club_passes", 0);
  //   }
  //   if (goalsSaved !== '') {
  //     formData.append("club_saved_goals", goalsSaved);
  //   } else {
  //     formData.append("club_saved_goals", 0);
  //   }
  //   if (interceptionsPerGame !== '') {
  //     formData.append("interceptions_per_game", interceptionsPerGame);
  //   } else {
  //     formData.append("interceptions_per_game", 0);
  //   }
  //   if (tackelsPerGame !== '') {
  //     formData.append("takles_per_game", tackelsPerGame);
  //   } else {
  //     formData.append("takles_per_game", 0);
  //   }
  //   if (shotsPerGame !== '') {
  //     formData.append("shots_per_game", shotsPerGame);
  //   } else {
  //     formData.append("shots_per_game", 0);
  //   }
  //   if (keyPassesPerGame !== '') {
  //     formData.append("key_passes_per_game", keyPassesPerGame);
  //   } else {
  //     formData.append("key_passes_per_game", 0);
  //   }
  //   if (dribblesPerGame !== '') {
  //     formData.append("dribles_completed_per_game", dribblesPerGame);
  //   } else {
  //     formData.append("dribles_completed_per_game", 0);
  //   }
  //   if (cleanSheetsPerGame != '') {
  //     formData.append("clean_sheets_per_game", cleanSheetsPerGame);
  //   } else {
  //     formData.append("clean_sheets_per_game", 0);
  //   }
  //   if (yellowCard !== '') {
  //     formData.append("club_yellow_card", yellowCard);
  //   } else {
  //     formData.append("club_yellow_card", 0);
  //   }
  //   if (redCard !== '') {
  //     formData.append("club_red_card", redCard);
  //   } else {
  //     formData.append("club_red_card", 0);
  //   }
  //   formData.append("achievements", keyAchievements);
  //   formData.append("summary", summary);
  //   formData.append("status", currentRequestStatus);
  //   formData.append("remarks", remarks);
  //   if (teamId === null && teamName !== '' && leagueId === null && leagueName !== '') {
  //     formData.append("flag", 'teamleague');
  //     formData.append("reg_id", uuidv4());
  //     let response = fetch(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       notifyRegistrationPersonalInfo()
  //       setPlayerClubId(null)
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowEditCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName !== '') {
  //     formData.append("flag", 'league');
  //     let response = fetch(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       notifyRegistrationPersonalInfo()
  //       setPlayerClubId(null)
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowEditCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else if (teamId === null && teamName !== '' && leagueId !== null && leagueName !== '') {
  //     formData.append("flag", 'team');
  //     formData.append("reg_id", uuidv4());
  //     // formData.append("country_name", userPersonalData.citizenship);
  //     let response = fetch(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       notifyRegistrationPersonalInfo()
  //       setPlayerClubId(null)
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowEditCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else {
  //     // let response = fetch(`${CLUB_URL}/${playerClubId}/`, {
  //     let response = fetch(`${PLAYERHISTORYUPDATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       notifyRegistrationPersonalInfo()
  //       setPlayerClubId(null)
  //       setTeamName('')
  //       setTeamId(null)
  //       setGamesPlayed('')
  //       setFromYear('')
  //       setToYear('')
  //       setAssists('')
  //       setGoalsSaved('')
  //       setGoalsScored('')
  //       setCleanSheetsPerGame('')
  //       setShotsPerGame('')
  //       setDribblesPerGame('')
  //       setInterceptionsPerGame('')
  //       setKeyPassesPerGame('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setPasses('')
  //       setRedCard('')
  //       setYellowCard('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setTackelsPerGame('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowEditCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   }
  // };

  const handleUpdatePlayerClubHistory = async (e) => {
    // e.preventDefault();
    // setIsLoading(true);
    setValidatedCareerHistory(true);
    // const formData = new FormData();
    // formData.append("sport_type", userPersonalData.sport_type);
    // formData.append("id", playerClubId);
    if (leagueType !== '') {
      // formData.append("league_type", leagueType);
      setTypeError('');
    } else {
      setTypeError('Type cannot be empty.');
    }
    if (leagueType === 'Domestic') {
      if (countryName === '') {
        setCountryError('Country cannot be empty.');
        return;
      }
      else {
        setCountryError('');
        // formData.append("country_name", countryName);
      }
    } else {
      setCountryError('');
      // formData.append("country_name", '');
    }

    if (fromYear !== '') {
      // formData.append("from_year", fromYear);
      setFromYearError('');
    } else {
      setFromYearError('From year cannot be empty.');
      return;
    }
    if (toYear !== '') {
      // formData.append("to_year", toYear);
      setToYearError('');
    } else {
      setToYearError('To year cannot be empty.');
      return;
    }
    if (teamName !== '') {
      // formData.append("games_played", gamesPlayed);
      setTeamError('');
    } else {
      setTeamError('Team name cannot be empty.');
      return;
    }
    // if (leagueName !== '') {
    //   setLeagueError('');
    // } else {
    //   setLeagueError('League name cannot be empty.');
    //   return;
    // }
    if (gamesPlayed !== '') {
      // formData.append("games_played", gamesPlayed);
      setGamesPlayedError('');
    } else {
      setGamesPlayedError('Games played cannot be empty.');
      return;
    }
    if (checkedEndorsement && isEmailFieldVisible) {
      if (emailId === '')
        return;
    }
    setIsSpinnerLoadingVisible(true);
    if (teamId === null && teamName !== '' && leagueId === null && leagueName !== '' && leagueName !== null) {
      // formData.append("flag", 'teamleague');
      // formData.append("reg_id", uuidv4());

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'teamleague',
            reg_id: uuidv4(),
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            player_career_history: playerClubId
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'teamleague',
            reg_id: uuidv4(),
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          // setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          setEmailId('')
          setPersonNameEndorsement('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false)
        });

    } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName !== '' && leagueName !== null) {

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'league',
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            player_career_history: playerClubId,
            reg_id: teamId
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'league',
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          // setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          setEmailId('')
          setPersonNameEndorsement('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName === '' && leagueName === null) {

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'leaguenull',
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            player_career_history: playerClubId,
            reg_id: teamId
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'leaguenull',
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          // setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          setEmailId('')
          setPersonNameEndorsement('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    }else if (teamId === null && teamName !== '' && leagueId !== null && leagueName !== '' && leagueName !== null) {
      // formData.append("flag", 'team');
      // formData.append("reg_id", uuidv4());
      // formData.append("country_name", userPersonalData.citizenship);

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'team',
            reg_id: uuidv4(),
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            player_career_history: playerClubId
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'team',
            reg_id: uuidv4(),
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          // setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          setEmailId('')
          setPersonNameEndorsement('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId === null && teamName !== '' && leagueId === null && leagueName === '' && leagueName === null) {
      // formData.append("flag", 'team');
      // formData.append("reg_id", uuidv4());
      // formData.append("country_name", userPersonalData.citizenship);

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'teamleaguenull',
            reg_id: uuidv4(),
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            player_career_history: playerClubId
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'teamleaguenull',
            reg_id: uuidv4(),
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            players: playerInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          // setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          setEmailId('')
          setPersonNameEndorsement('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });

    } else {

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            player_career_history: playerClubId,
            reg_id: teamId
          }
        };
      } else {
        data = {
          career_history: {
            id: playerClubId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            games_played: gamesPlayed,
            club_goals: goalsScored !== '' ? goalsScored : 0,
            club_assists: assists !== '' ? assists : 0,
            club_passes: passes !== '' ? passes : 0,
            club_saved_goals: goalsSaved !== '' ? goalsSaved : 0,
            interceptions_per_game: interceptionsPerGame !== '' ? interceptionsPerGame : 0,
            takles_per_game: tackelsPerGame !== '' ? tackelsPerGame : 0,
            shots_per_game: shotsPerGame !== '' ? shotsPerGame : 0,
            key_passes_per_game: keyPassesPerGame !== '' ? keyPassesPerGame : 0,
            dribles_completed_per_game: dribblesPerGame !== '' ? dribblesPerGame : 0,
            clean_sheets_per_game: cleanSheetsPerGame !== '' ? cleanSheetsPerGame : 0,
            club_yellow_card: yellowCard !== '' ? yellowCard : 0,
            club_red_card: redCard !== '' ? redCard : 0,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${PLAYERHISTORYUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setGamesPlayed('')
          setFromYear('')
          setToYear('')
          setAssists('')
          setGoalsSaved('')
          setGoalsScored('')
          setCleanSheetsPerGame('')
          setShotsPerGame('')
          setDribblesPerGame('')
          setInterceptionsPerGame('')
          setKeyPassesPerGame('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setPasses('')
          setRedCard('')
          setYellowCard('')
          // setCurrentRequestStatus('')
          // setRemarks('')
          setTackelsPerGame('')
          setCountryName('')
          setKeyAchievements('')
          getLeagues()
          getTeams()
          setEmailId('')
          setPersonNameEndorsement('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage(error.response.data[0]);
          setIsSpinnerLoadingVisible(false);
        });
    }
  };

  const handleUpdateVideoClip = async (event) => {
    event.preventDefault();
    setValidatedVideos(true)
    const formData = new FormData();
    if (videoTitle === '') {
      setVideoTitleError('Video title cannot be empty')
      return;
    } else {
      setVideoTitleError('')
      formData.append("title", videoTitle);
    }
    if (videoUrl === '') {
      setVideoUrlError('Video url cannot be empty')
      return;
    } else {
      setVideoUrlError('')
      formData.append("clip_url", videoUrl);
    }
    let response = fetch(`${VIDEOCLIP_URL}/${videoId}/`, {
      method: "PATCH",
      body: formData,
    });
    console.log('response:', response)
    let data = (await response).json()
    console.log('data:', data)
    let status = (await response).status
    console.log('status:', status)
    if (status === 200) {
      notifyCustomSuccessMessage('Successfully updated the data!')
      setVideoTitle('')
      setVideoUrl('')
      setVideoTitleError('')
      setVideoUrlError('')
      // setIsLoading(true)
      setShowEditVideosModal(false)
    } else {
      notifyError()
    }
  }

  // function responsible for getting user personal information

  const userPersonalInfo = async () => {
    // e.preventDefault();
    console.log('user Personal Information');
    let response = fetch(`${USER_URL}/${user.user_id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    const data = (await response).json()
    console.log('data:', data)
    console.log('response:', response)
    const status = (await response).status
    if (status === 200) {
      const personalInfo = (await data)
      console.log('personalInfo: ', personalInfo)
      setUserPersonalData(personalInfo)
      const videoDetails = (await personalInfo.video_clip)
      setVideoClip(videoDetails)
      const player = (await personalInfo.player[0])
      // console.log('Profile, player Info: ', player)
      const sportProfileType = (await personalInfo.sport_profile_type)
      sportProfileType.map(item => {
        if (item.profile_type === 'Player') {
          if (item.is_active === true)
            setSelectedPlayerSwitchOption(true)
          else
            setSelectedPlayerSwitchOption(false)
        }
        if (item.profile_type === 'Coach') {
          if (item.is_active === true)
            setSelectedCoachSwitchOption(true)
          else
            setSelectedCoachSwitchOption(false)
        }
        if (item.profile_type === 'Agent') {
          if (item.is_active === true)
            setSelectedAgentSwitchOption(true)
          else
            setSelectedAgentSwitchOption(false)
        }
      })
      if (player) {
        setPlayerInfo(player)
        const playerClub = (await player.club)
        console.log('Profile, club: ', playerClub)
        setPlayerClub(playerClub)
      } else {
        setPlayerInfo('')
        setPlayerClub([])
      }
    } else if (status === 401) {
      notifyAuthenticateError()
    } else {
      notifyError()
    }
  }

  // Handle click event

  const handleClick = () => {
    setIsTeamLOVLoading(false);
    setIsLeagueLOVLoading(false);
  };

  // function to handle lov search filter

  const handleSearchCountryFilter = (value) => {
    const res = optionsCountry.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    console.log('res:', res);
    setSearchCountryLOVData(res);
    setIsCountryLOVLoading(true);
    setCountryName(value);
    if (value === "") {
      setSearchCountryLOVData([]);
      setIsCountryLOVLoading(false);
      setCountryName('');
    }
  }

  const handleSelectCountry = (item) => {
    setCountryName(item.value);
    setIsCountryLOVLoading(false);
  }

  const handleSearchFilter = (value) => {
    const res = optionsTeam.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    console.log('res:', res);
    setSearchTeamLOVData(res);
    setIsTeamLOVLoading(true);
    setTeamName(value);
    if (value === "") {
      setSearchTeamLOVData([]);
      setIsTeamLOVLoading(false);
      setTeamName('');
      setTeamId(null);
    }
  }

  const handleSelectTeam = (item) => {
    setTeamName(item.label);
    setTeamId(item.value);
    setIsTeamLOVLoading(false);
  }

  const handleSearchLeagueFilter = (value) => {
    // setIsTeamLOVLoading(false);
    // console.log('value:', value);
    // console.log('optionsLeague:', optionsLeague);
    const res = optionsLeague.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    console.log('res:', res);
    setSearchLeagueLOVData(res);
    setIsLeagueLOVLoading(true);
    setLeagueName(value);
    if (value === "") {
      setSearchLeagueLOVData([]);
      setIsLeagueLOVLoading(false);
      setLeagueName('');
      setLeagueId(null);
    }
  }

  const handleSelectLeague = (item) => {
    setLeagueName(item.label);
    setLeagueId(item.value);
    setIsLeagueLOVLoading(false);
  }


  // handle on Change functions

  const handleOnChangeRegionType = (e) => {
    if (e) {
      const searchType = e.target.value;
      if (searchType === 'Domestic') {
        getCountries();
        getTeams();
        getLeagues();
        setCountryName('');
        setTeamName('');
        setLeagueName('');
      } else {
        getTeams();
        setTeamName('');
        getLeagues();
        setLeagueName('');
      }
      setLeagueType(searchType);
    } else {
      setLeagueType('');
      setTeamName('');
      setLeagueName('');
    }
  }

  // const handleChangeEndorsementType = (e, i) => {
  //   // const { name, value } = e.target
  //   const onchangeVal = [...personNameEndorsement]
  //   onchangeVal[i]['type'] = e.target.value
  //   onchangeVal[i]['from_endorsee'] = user.user_id;
  //   onchangeVal[i]['status'] = 'pending';
  //   // onchangeVal[i]['player_career_history'] = '';
  //   setPersonNameEndorsement(onchangeVal)
  //   console.log('onchangeVal', onchangeVal)
  // }

  // const handleChangeEndorsement = (e, i) => {
  //   const { name, value } = e.target
  //   const onchangeVal = [...personNameEndorsement]
  //   onchangeVal[i][name] = value
  //   setPersonNameEndorsement(onchangeVal)
  //   console.log('onchangeVal', onchangeVal)
  // }

  // const handleOnClickSearchEndorsementFilter = async (e, type, i) => {
  //   setValidatedCareerHistory(true);
  //   let COMPLETE_URL;
  //   let apiEndPoint;
  //   console.log('type :', type);
  //   // const profileTypeEndorsement = type;
  //   if (type === '') {
  //     setProfileTypeError('Profile Type cannot be left empty.');
  //     return;
  //   } else if (type === 'player') {
  //     setProfileTypeError('');
  //     if (personNameEndorsement[i].to_endorser_email !== '') {
  //       // apiEndPoint = 'user__email=' + personNameEndorsement[i].text;
  //       // COMPLETE_URL = SEARCHPLAYER_URL + apiEndPoint;
  //       const splitPersonName = personNameEndorsement[i].to_endorser_email(" ");
  //       // console.log('splitPersonName:', splitPersonName);
  //       if (splitPersonName.length > 1) {
  //         const firstName = splitPersonName[0];
  //         // console.log('firstName:', firstName);
  //         const lastName = splitPersonName[1];
  //         // console.log('lastName:', lastName);
  //         apiEndPoint = 'user__first_name=' + firstName + '&user__last_name=' + lastName;
  //       } else {
  //         const firstName = splitPersonName[0];
  //         apiEndPoint = 'user__first_name=' + firstName;
  //       }
  //       COMPLETE_URL = SEARCHPLAYER_URL + apiEndPoint;
  //     } else {
  //       COMPLETE_URL = SEARCHPLAYER_URL
  //     }
  //     let response = fetch(`${COMPLETE_URL}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     console.log('response:', response)
  //     const data = (await response).json()
  //     console.log('data:', data)
  //     const status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       setSportPersonData(await data);
  //     }
  //     // setShowModalEndorsement(true);
  //   } else if (type === 'coach') {
  //     setProfileTypeError('');
  //     // let COMPLETE_URL;
  //     // let apiEndPoint;
  //     if (personNameEndorsement[i].to_endorser_email !== '') {
  //       // apiEndPoint = 'user__email=' + personNameEndorsement[i].text;
  //       // COMPLETE_URL = SEARCHCOACH_URL + apiEndPoint;
  //       const splitPersonName = personNameEndorsement[i].to_endorser_email(" ");
  //       // console.log('splitPersonName:', splitPersonName);
  //       if (splitPersonName.length > 1) {
  //         const firstName = splitPersonName[0];
  //         // console.log('firstName:', firstName);
  //         const lastName = splitPersonName[1];
  //         // console.log('lastName:', lastName);
  //         apiEndPoint = 'user__first_name=' + firstName + '&user__last_name=' + lastName;
  //       } else {
  //         const firstName = splitPersonName[0];
  //         apiEndPoint = 'user__first_name=' + firstName;
  //       }
  //       // console.log('apiEndPoint:', apiEndPoint);
  //       COMPLETE_URL = SEARCHCOACH_URL + apiEndPoint;
  //     } else {
  //       COMPLETE_URL = SEARCHCOACH_URL
  //     }
  //     // console.log('COMPLETE_URL:', COMPLETE_URL);
  //     let response = fetch(`${COMPLETE_URL}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     console.log('response:', response)
  //     const data = (await response).json()
  //     console.log('data:', data)
  //     const status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       setSportPersonData(await data);
  //     }
  //   }
  //   // console.log('reached at end');
  //   // setShowAddCareerHistoryModal(false);
  //   setShowModalEndorsement(true);
  // }

  // const handleOnClickSportPersonEndorsement = (data, i) => {
  //   // const { name, value } = e.target
  //   // const name = 'text';
  //   const onchangeVal = [...personNameEndorsement]
  //   onchangeVal[i]['to_endorser_email'] = data.user.email;
  //   onchangeVal[i]['to_endorser'] = data.user.id;
  //   // onchangeVal[i]['from_endorsee'] = user.user_id;
  //   // onchangeVal[i]['endorsee'] = personId;
  //   // onchangeVal[i]['status'] = 'pending';
  //   // onchangeVal[i]['agent_players_coaches_under_me'] = agentPlayersAndCoachesId;
  //   setPersonNameEndorsement(onchangeVal);
  //   // setPersonEndorsementId(value.user.id);
  //   setShowModalEndorsement(false);
  // }

  // const handleAddFieldEndorsement = () => {
  //   setPersonNameEndorsement([...personNameEndorsement, { to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
  // }

  // const handleDeleteEndorsement = (i) => {
  //   const deleteVal = [...personNameEndorsement]
  //   deleteVal.splice(i, 1)
  //   setPersonNameEndorsement(deleteVal)
  // }

  // const onChangeHandleComments = (e) => {
  //   // const { name, value } = e.target
  //   // const name = 'text';
  //   setComments(e.target.value)
  //   const onchangeVal = [...personNameEndorsement]
  //   onchangeVal.map(item => {
  //     item.comments = e.target.value
  //   })
  //   console.log('onchangeVal', onchangeVal)
  //   setPersonNameEndorsement(onchangeVal);
  // }

  const handleCheckedEndorsement = () => {
    setCheckedEndorsement(!checkedEndorsement);
    if (!checkedEndorsement) {
      if (teamId !== null)
        getInstitution();
      else
        setIsEmailFieldVisible(true)
    } else {
      setIsEmailFieldVisible(false)
    }
  }

  // handle on Close Modal

  const handleAddGenralModalClose = () => {
    setShowAddGeneralModal(false);
  }

  const handleEditGenralModalClose = () => {
    setShowEditGeneralModal(false);
  }

  const handleAddCareerHistoryModalClose = () => {
    setShowAddCareerHistoryModal(false);
    setTeamName('')
    setTeamId(null)
    setGamesPlayed('')
    setFromYear('')
    setToYear('')
    setAssists('')
    setGoalsSaved('')
    setGoalsScored('')
    setCleanSheetsPerGame('')
    setShotsPerGame('')
    setDribblesPerGame('')
    setInterceptionsPerGame('')
    setKeyPassesPerGame('')
    setLeagueName('')
    setLeagueId(null)
    setLeagueType('')
    setPasses('')
    setRedCard('')
    setYellowCard('')
    setCurrentRequestStatus('')
    setRemarks('')
    setTackelsPerGame('')
    setCountryName('')
    setKeyAchievements('')
    getLeagues()
    getTeams()
    setPersonNameEndorsement('')
    setCheckedEndorsement(false)
    setEmailId('')
    setValidatedCareerHistory(false)
    setFromYearError('')
    setToYearError('')
    setTypeError('')
    setGamesPlayedError('')
    setTeamError('')
    setLeagueError('')
  }

  const handleEditCareerHistoryModalClose = () => {
    setShowEditCareerHistoryModal(false);
    setTeamName('')
    setTeamId(null)
    setGamesPlayed('')
    setFromYear('')
    setToYear('')
    setAssists('')
    setGoalsSaved('')
    setGoalsScored('')
    setCleanSheetsPerGame('')
    setShotsPerGame('')
    setDribblesPerGame('')
    setInterceptionsPerGame('')
    setKeyPassesPerGame('')
    setLeagueName('')
    setLeagueId(null)
    setLeagueType('')
    setPasses('')
    setRedCard('')
    setYellowCard('')
    setCurrentRequestStatus('')
    setRemarks('')
    setTackelsPerGame('')
    setCountryName('')
    setKeyAchievements('')
    getLeagues()
    getTeams()
    setPersonNameEndorsement('')
    setCheckedEndorsement(false)
    setEmailId('')
    setIsEmailFieldVisible(false)
    setValidatedCareerHistory(false)
  }

  const handleAddVideosModalClose = () => {
    setShowAddVideosModal(false);
    setVideoTitle('')
    setVideoTitleError('')
    setVideoUrl('')
    setVideoUrlError('')
  }

  const handleEditVideosModalClose = () => {
    setShowEditVideosModal(false);
    setVideoTitle('')
    setVideoTitleError('')
    setVideoUrl('')
    setVideoUrlError('')
  }

  // const handleShowEndorsementModalClose = () => {
  //   setShowModalEndorsement(false);
  // }

  // useEffect event triggered on page load

  useEffect(() => {
    userPersonalInfo();

    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        setIsTeamLOVLoading(false);
        setIsLeagueLOVLoading(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showAddGeneralModal, showAddCareerHistoryModal, showAddVideosModal, showEditGeneralModal, showEditCareerHistoryModal, showEditVideosModal]);

  // const playerClubList = playerClub.map((club) => {
  //   let currentStatus;
  //   if (club.status === 'Submitted') {
  //     currentStatus = 'Waiting for Approval';
  //     return (
  //       <tr key={club.id}>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={faCheck}
  //             color='#0f0'
  //           />
  //         </td>
  //         <td>{club.club_name}</td>
  //         <td>{club.from_year}</td>
  //         <td>{club.to_year}</td>
  //         <td>{club.league_name}</td>
  //         <td>{club.games_played}</td>
  //         <td>{club.club_goals}</td>
  //         <td>{club.club_saved_goals}</td>
  //         <td>{currentStatus}</td>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={faTrashCan}
  //             color='#ff001b5c'
  //           />
  //         </td>
  //       </tr>
  //     );
  //   } else if (club.status === 'Draft') {
  //     currentStatus = 'Draft';
  //   } else if (club.status === 'Approved') {
  //     currentStatus = 'Approved';
  //     return (
  //       <tr key={club.id}>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={faCheckDouble}
  //             color='#0f0'
  //           />
  //         </td>
  //         <td>{club.club_name}</td>
  //         <td>{club.from_year}</td>
  //         <td>{club.to_year}</td>
  //         <td>{club.league_name}</td>
  //         <td>{club.games_played}</td>
  //         <td>{club.club_goals}</td>
  //         <td>{club.club_saved_goals}</td>
  //         <td>{currentStatus}</td>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={faTrashCan}
  //             color='#ff001b5c'
  //           />
  //         </td>
  //       </tr>
  //     );
  //   } else if (club.status === 'Rejected') {
  //     currentStatus = 'Rejected'
  //   }
  //   return (
  //     <tr key={club.id}>
  //       <td>
  //         <FontAwesomeIcon
  //           icon={faEdit}
  //           color='#2222e785'
  //           style={{ cursor: 'pointer' }}
  //           onClick={() => {
  //             getPlayerClubInfo(club.id);
  //             setShowEditCareerHistoryModal(true);
  //           }}
  //         />
  //       </td>
  //       <td>{club.club_name}</td>
  //       <td>{club.from_year}</td>
  //       <td>{club.to_year}</td>
  //       <td>{club.league_name}</td>
  //       <td>{club.games_played}</td>
  //       <td>{club.club_goals}</td>
  //       <td>{club.club_saved_goals}</td>
  //       <td>{currentStatus}</td>
  //       <td>
  //         <FontAwesomeIcon
  //           icon={faTrashCan}
  //           color='red'
  //           style={{ cursor: 'pointer' }}
  //           onClick={() => handlePlayerClubDelete(club.id)}
  //         />
  //       </td>
  //     </tr>
  //   );
  // })

  const playerClubList = playerClub.map((club) => {
    return (
      <tr key={club.id}>
        <td>
          <FontAwesomeIcon
            icon={faEdit}
            color='#2222e785'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              getPlayerClubInfo(club.id);
              setShowEditCareerHistoryModal(true);
            }}
          />
        </td>
        <td>{club.club_name}</td>
        <td>{club.from_year}</td>
        <td>{club.to_year}</td>
        <td>{club.league_name}</td>
        <td>{club.games_played}</td>
        <td>{club.club_goals}</td>
        <td>{club.club_saved_goals}</td>
        <td>
          <FontAwesomeIcon
            icon={faTrashCan}
            color='red'
            style={{ cursor: 'pointer' }}
            onClick={() => handlePlayerClubDelete(club.id)}
          />
        </td>
      </tr>
    );
  })

  const personalAchievementList = playerClub.map((club) => {
    const parsedData = club.achievements !== null && club.achievements !== '' ? club.achievements.split('\n') : null;
    if (club.achievements !== null && club.achievements !== '')
      return (
        <tr key={club.id}>
          {/* <td>{club.achievements}</td> */}
          <td>{club.from_year}</td>
          <td>{club.to_year}</td>
          <td>
            {parsedData && parsedData.length > 0 &&
              <ul>
                {parsedData.map((item, index) => (
                  <li key={index} className='card-text'>{item}</li>
                ))}
              </ul>
            }
          </td>
        </tr>
      );
  })

  const videoClipList = videoClip.map((clip) => {
    if (clip.profile_type === 'player') {
      return (
        <tr key={clip.id}>
          <td>
            <FontAwesomeIcon
              icon={faEdit}
              color='#2222e785'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                getVideoClipInfo(clip.id);
                setShowEditVideosModal(true);
              }}
            />
          </td>
          <td>{clip.title}</td>
          <td>
            <a href={'https://' + clip.clip_url} target="_blank" rel="noopener noreferrer">
              {clip.clip_url}
            </a>
          </td>
          <td>
            <FontAwesomeIcon
              icon={faTrashCan}
              color='red'
              style={{ cursor: 'pointer' }}
              onClick={() => handleVideoClipDelete(clip.id)}
            />
          </td>
        </tr>
      );
    }
  })

  // const sportPersonEndorsementList = sportPersonData.map((data) => {
  //   return (
  //     <li className='list-element' key={data.id} onClick={() => handleOnClickSportPersonEndorsement(data, personNameEndorsement.length - 1)}>
  //       <div className='d-flex justify-content-between'>
  //         <span>
  //           {data.user.first_name} {data.user.last_name}
  //         </span>
  //         <span>
  //           {data.user.email}
  //         </span>
  //       </div>
  //       <div>
  //         {data.user.sport_type}
  //       </div>
  //     </li>
  //   );
  // })

  const playersEndorsementRequestList = playerEndorsementRequest.map((item) => {
    return (
      <tr key={item.id}>
        <td>
          {item.to_endorser.first_name === '' && item.to_endorser.last_name === '' ?
            item.to_endorser.email : item.to_endorser.first_name + ' ' + item.to_endorser.last_name
          }
        </td>
        <td>{item.type}</td>
        <td>{item.status}</td>
        <td>{item.remarks}</td>
        <td>
          <FontAwesomeIcon
            icon={faTrashCan}
            color='red'
            style={{ cursor: 'pointer' }}
            onClick={() => handlePlayersEndorsementRequestDelete(item.id)}
          />
        </td>
      </tr>
    );
  })

  return (
    <>

      {/* <Modal show={showModalEndorsement} onHide={handleShowEndorsementModalClose} scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>List of Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className='content-list' style={{ padding: 10 }}>
            {sportPersonData && sportPersonData.length > 0 ? sportPersonEndorsementList : 'No data found'}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShowEndorsementModalClose}>Close</Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={showAddGeneralModal} onHide={handleAddGenralModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Add player informantion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedPlayerInfo}>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Primary Position
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Select
                  size='lg'
                  value={primaryPosition}
                  onChange={(e) => setPrimaryPosition(e.target.value)}
                  // style={{ fontSize: 11, padding: 10 }}
                  required
                >
                  <option value="">Open this select menu</option>
                  {optionsPosition.map((position) => (
                    <option value={position.value}>
                      {position.label}
                    </option>
                  ))}
                </Form.Select>
                <span className="text-danger">
                  {positionError}
                </span>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Secondary Position</Form.Label>
                <Form.Select
                  size='lg'
                  value={secondaryPosition}
                  onChange={(e) => setSecondaryPosition(e.target.value)}
                // style={{ fontSize: 11, padding: 10 }}
                >
                  <option value="">Open this select menu</option>
                  {optionsPosition.map((position) => (
                    <option value={position.value}>
                      {position.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Preferred Foot
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Select
                  size='lg'
                  value={prefferedFoot}
                  onChange={(e) => setPrefferedFoot(e.target.value)}
                  // style={{ fontSize: 11, padding: 10 }}
                  required
                >
                  <option value="">Open this select menu</option>
                  {optionsPreferredFoot.map((foot) => (
                    <option value={foot.value}>
                      {foot.label}
                    </option>
                  ))}
                </Form.Select>
                <span className="text-danger">
                  {preferredFootError}
                </span>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Top Speed</Form.Label>
                <Form.Group as={Row}>
                  <Col sm="9">
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      placeholder="Enter the top speed of player"
                      value={topSpeed}
                      onChange={(e) => setTopSpeed(e.target.value)}
                    />
                  </Col>
                  <Form.Label column sm="3">
                    in km/hr
                  </Form.Label>
                </Form.Group>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className='custom-label'>My Worth</Form.Label>
                <Form.Group as={Row}>
                  <Col sm="9">
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      placeholder="Enter the worth price"
                      value={myWorth}
                      onChange={(e) => setMyWorth(e.target.value)}
                    />
                  </Col>
                  <Form.Label column sm="3">
                    in millions
                  </Form.Label>
                </Form.Group>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Open for Hiring</Form.Label>
                <Form.Check
                  type="checkbox"
                  size='lg'
                  label="Is open"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddGenralModalClose}>Close</Button>
          <Button variant="primary" onClick={handleAddPlayerSubmit}>Save Changes</Button>
        </Modal.Footer>
      </Modal >

      <Modal show={showEditGeneralModal} onHide={handleEditGenralModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Edit player informantion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedPlayerInfo}>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Primary Position
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Select
                  size='lg'
                  value={primaryPosition}
                  onChange={(e) => setPrimaryPosition(e.target.value)}
                  // style={{ fontSize: 11, padding: 10 }}
                  required
                >
                  <option value="">Open this select menu</option>
                  {optionsPosition.map((position) => (
                    <option value={position.value}>
                      {position.label}
                    </option>
                  ))}
                </Form.Select>
                <span className="text-danger">
                  {positionError}
                </span>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Secondary Position</Form.Label>
                <Form.Select
                  size='lg'
                  value={secondaryPosition}
                  onChange={(e) => setSecondaryPosition(e.target.value)}
                // style={{ fontSize: 11, padding: 10 }}
                >
                  <option value="">Open this select menu</option>
                  {optionsPosition.map((position) => (
                    <option value={position.value}>
                      {position.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Preferred Foot
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Select
                  size='lg'
                  value={prefferedFoot}
                  onChange={(e) => setPrefferedFoot(e.target.value)}
                  // style={{ fontSize: 11, padding: 10 }}
                  required
                >
                  <option value="">Open this select menu</option>
                  {optionsPreferredFoot.map((foot) => (
                    <option value={foot.value}>
                      {foot.label}
                    </option>
                  ))}
                </Form.Select>
                <span className="text-danger">
                  {preferredFootError}
                </span>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Top Speed</Form.Label>
                <Form.Group as={Row}>
                  <Col sm="9">
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      placeholder="Enter the top speed of player"
                      value={topSpeed}
                      onChange={(e) => setTopSpeed(e.target.value)}
                    />
                  </Col>
                  <Form.Label column sm="3">
                    in km/hr
                  </Form.Label>
                </Form.Group>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className='custom-label'>My Worth</Form.Label>
                <Form.Group as={Row}>
                  <Col sm="9">
                    <Form.Control
                      className='input'
                      type="number"
                      size='lg'
                      placeholder="Enter your worth price"
                      value={myWorth}
                      onChange={(e) => setMyWorth(e.target.value)}
                    />
                  </Col>
                  <Form.Label column sm="3">
                    in millions
                  </Form.Label>
                </Form.Group>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Open for Hiring</Form.Label>
                <Form.Check
                  type="checkbox"
                  size='lg'
                  label="Is open"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditGenralModalClose}>Close</Button>
          <Button variant="primary" onClick={handleUpdatePlayer}>Save Changes</Button>
        </Modal.Footer>
      </Modal >

      <Modal show={showAddCareerHistoryModal} onHide={handleAddCareerHistoryModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Add Career History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isSpinnerLoadingVisible ?
            <div className='text-center'>
              <Button variant="primary" size='lg' disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading. Please wait...
              </Button>
            </div>
            :
            <Form noValidate validated={validatedCareerHistory}>
              <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>From Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2012"
                    value={fromYear}
                    onChange={(e) => setFromYear(e.target.value)}
                    onClick={handleClick}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {fromYearError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>To Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2013"
                    value={toYear}
                    onChange={(e) => setToYear(e.target.value)}
                    onClick={handleClick}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {toYearError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm='4'>
                  <Form.Label className='custom-label'>Type
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Select
                    className='input'
                    size='lg'
                    value={leagueType}
                    // onChange={(e) => setLeagueType(e.target.value)}
                    onChange={handleOnChangeRegionType}
                    // style={{ fontSize: 11, padding: 10 }}
                    onClick={handleClick}
                    required
                  >
                    <option value=''>Open this select menu</option>
                    <option value="International">International</option>
                    <option value="Domestic">Domestic</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {typeError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Summary</Form.Label>
                  <Form.Control
                    className='input'
                    as="textarea"
                    rows={5}
                    placeholder="Enter the summary of your career"
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                {leagueType === 'Domestic' &&
                  <Form.Group as={Col} sm="4">
                    <Form.Label className='custom-label'>Country Name
                      <span className='text-danger'> * </span>
                    </Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      placeholder="Select the name of a country"
                      value={countryName}
                      onChange={e => handleSearchCountryFilter(e.target.value)}
                      onClick={handleClick}
                      required
                    />
                    {isCountryLOVloading &&
                      <div className='lov-content mt-1'>
                        <ul className='lov-list' style={{ padding: 5 }}>
                          {
                            searchCountryLOVData.length > 0 ?
                              searchCountryLOVData.map((item) => (
                                <li className='lov-list-element' key={item.id} onClick={() => handleSelectCountry(item)}>
                                  {item.label}
                                </li>
                              ))

                              : <li className='lov-list-element' onClick={() => { setCountryName(''); setIsCountryLOVLoading(false); }}>
                                No data found. Please select a valid country.
                              </li>
                          }
                        </ul>
                      </div>
                    }
                    <Form.Control.Feedback type="invalid">
                      {countryError}
                    </Form.Control.Feedback>
                  </Form.Group>
                }

                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Club Name
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Select the name of a club"
                    value={teamName}
                    onChange={e => handleSearchFilter(e.target.value)}
                    onClick={handleClick}
                    required
                  />
                  {isTeamLOVloading &&
                    <div className='lov-content mt-1'>
                      <ul className='lov-list' style={{ padding: 5 }}>
                        {
                          searchTeamLOVData.length > 0 ?
                            searchTeamLOVData.map((item) => (
                              <li className='lov-list-element' key={item.id} onClick={() => handleSelectTeam(item)}>
                                {item.label}
                              </li>
                            ))

                            // : <li className='lov-list-element'>No data found</li>
                            : setIsTeamLOVLoading(false)
                        }
                      </ul>
                    </div>
                  }
                  <Form.Control.Feedback type="invalid">
                    {teamError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>League
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Select the name of a league"
                    value={leagueName}
                    onChange={e => handleSearchLeagueFilter(e.target.value)}
                    onClick={handleClick}
                  />
                  {isLeagueLOVloading &&
                    <div className='lov-content mt-1'>
                      <ul className='lov-list' style={{ padding: 5 }}>
                        {
                          searchLeagueLOVData.length > 0 ?
                            searchLeagueLOVData.map((item) => (
                              <li className='lov-list-element' key={item.id} onClick={() => handleSelectLeague(item)}>
                                {item.label}
                              </li>
                            ))

                            // : <li className='lov-list-element'>No data found</li>
                            : setIsLeagueLOVLoading(false)
                        }
                      </ul>
                    </div>
                  }
                  <Form.Control.Feedback type="invalid">
                    {leagueError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Games Played
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of games played"
                    value={gamesPlayed}
                    onChange={(e) => setGamesPlayed(e.target.value)}
                    onClick={handleClick}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {gamesPlayedError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Goals Scored</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of goals scored"
                    value={goalsScored}
                    onChange={(e) => setGoalsScored(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Assists</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of assists"
                    value={assists}
                    onChange={(e) => setAssists(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Passes</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of games played"
                    value={passes}
                    onChange={(e) => setPasses(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Goals Saved</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of goals saved"
                    value={goalsSaved}
                    onChange={(e) => setGoalsSaved(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Interceptions</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of interceptions made in per game"
                    value={interceptionsPerGame}
                    onChange={(e) => setInterceptionsPerGame(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Tackels</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of takles made per game"
                    value={tackelsPerGame}
                    onChange={(e) => setTackelsPerGame(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Shots</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of takels made in per game"
                    value={shotsPerGame}
                    onChange={(e) => setShotsPerGame(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Key Passes</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of interceptions made in per game"
                    value={keyPassesPerGame}
                    onChange={(e) => setKeyPassesPerGame(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Dribbles Completed</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of takles made per game"
                    value={dribblesPerGame}
                    onChange={(e) => setDribblesPerGame(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Clean Sheets</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the clean sheets made in per game"
                    value={cleanSheetsPerGame}
                    onChange={(e) => setCleanSheetsPerGame(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col} column sm="4">
                  <Form.Label className='custom-label'>Yellow Card</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of yellow card"
                    value={yellowCard}
                    onChange={(e) => setYellowCard(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} column sm="4">
                  <Form.Label className='custom-label'>Red Card</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of red card"
                    value={redCard}
                    onChange={(e) => setRedCard(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Key Achievement</Form.Label>
                  <Form.Control
                    className='input'
                    as="textarea"
                    rows={5}
                    placeholder="Enter the key achievements"
                    value={keyAchievements}
                    onChange={(e) => setKeyAchievements(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>

              {/* <Row className='mb-3'>
                <Form.Group as={Col} column sm="4">
                  <Form.Label className='custom-label'>Do you want endorsement?</Form.Label>
                  <Form.Check
                    type="checkbox"
                    size='lg'
                    label="Yes"
                    checked={checkedEndorsement}
                    onChange={() => setCheckedEndorsement(!checkedEndorsement)}
                  />
                </Form.Group> */}

              {/* {checkedEndorsement && teamId === null &&
                  <Form.Group as={Col} column sm="4">
                    <Form.Label className='custom-label'>Email</Form.Label>
                    <Form.Control
                      className='input'
                      type="email"
                      size='lg'
                      placeholder="Enter the email address"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </Form.Group>
                } */}

              <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Do you want Endorsement?</Form.Label>
                  <Form.Check
                    type="checkbox"
                    size='lg'
                    label="Yes"
                    checked={checkedEndorsement}
                    // onChange={() => setCheckedEndorsement(!checkedEndorsement)}
                    onChange={handleCheckedEndorsement}
                  />
                </Form.Group>

                {checkedEndorsement && isEmailFieldVisible &&
                  <Form.Group as={Col} sm="4">
                    <Form.Label className='custom-label'>Email
                      <span className='text-danger'> * </span>
                    </Form.Label>
                    <Form.Control
                      className='input'
                      type="email"
                      size='lg'
                      placeholder="Enter the email address"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      required
                    />
                    <Form.Text muted>
                      As your club is not registered in bscoutd, please enter the email address of the club.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      Email cannot be empty
                    </Form.Control.Feedback>
                  </Form.Group>
                }
              </Row>

              {checkedEndorsement &&
                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>Comments</Form.Label>
                    <Form.Control
                      // className='input'
                      // type="text"
                      // size='lg'
                      placeholder="Enter the comments"
                      // name="text"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                      // onChange={(e) => onChangeHandleComments(e)}
                      as="textarea"
                      rows={5}
                    />
                  </Form.Group>
                </Row>
              }

              {/* {checkedEndorsement &&
                <Card>
                  <Card.Header as="h5" style={{ backgroundColor: '#e3f2fd' }}>Endorsement</Card.Header>
                  <Card.Body>
                    {personNameEndorsement.map((val, i) =>
                      <Row>
                        <Form.Group as={Col} sm="4">
                          <Form.Label className='custom-label'>Profile Type
                            <span className='text-danger'> * </span>
                          </Form.Label>
                          <Form.Select
                            className='input'
                            size='lg'
                            value={val.type}
                            onChange={(e) => handleChangeEndorsementType(e, i)}
                            required
                          >
                            <option value=''>Open this select menu</option>
                            <option value="player">Player</option>
                            <option value="coach">Coach</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {profileTypeError}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} sm="4">
                          <Form.Label>Name
                            <span style={{ color: 'red', fontSize: 16 }}> * </span>
                          </Form.Label>
                          <Stack direction="horizontal" gap={2}>
                            <Form.Control
                              className='input'
                              type="text"
                              size='lg'
                              placeholder="Enter the name of a person"
                              name="to_endorser_email"
                              value={val.to_endorser_email}
                              onChange={(e) => handleChangeEndorsement(e, i)}
                            />
                            <Button
                              variant="primary"
                              size='lg'
                              onClick={(e) => handleOnClickSearchEndorsementFilter(e, val.type, i)}
                            >
                              Search
                            </Button>
                          </Stack>
                          <Form.Control.Feedback type="invalid">
                            {personNameError}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm="4">
                          <div className='d-flex justify-content-end mt-5'>
                            <div className='ms-2'>
                              <FontAwesomeIcon
                                icon={faPlus}
                                color='green'
                                style={{ padding: 11, border: '1px solid green', borderRadius: 5, cursor: 'pointer' }}
                                onClick={handleAddFieldEndorsement}
                              />
                            </div>
                            <div className='ms-2'>
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                color='red'
                                style={{ padding: 11, border: '1px solid red', borderRadius: 5, cursor: 'pointer' }}
                                onClick={() => handleDeleteEndorsement(i)}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Row>
                    )}
                  </Card.Body>
                </Card>
              } */}
              {/* </Row> */}

            </Form>
          }
        </Modal.Body>
        <Modal.Footer>
          {!isSpinnerLoadingVisible &&
            <>
              <Button variant="secondary" onClick={handleAddCareerHistoryModalClose}>Close</Button>
              <Button variant="primary" onClick={handleAddPlayerClubHistory}>Save Changes</Button>
            </>
          }
        </Modal.Footer>
      </Modal >

      <Modal show={showEditCareerHistoryModal} onHide={handleEditCareerHistoryModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>
            Edit Career History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isSpinnerLoadingVisible ?
            <div className='text-center'>
              <Button variant="primary" size='lg' disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading. Please wait...
              </Button>
            </div>
            :
            <Form noValidate validated={validatedCareerHistory}>
              <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>From Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2012"
                    value={fromYear}
                    onChange={(e) => setFromYear(e.target.value)}
                    required
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fromYearError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>To Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2013"
                    value={toYear}
                    onChange={(e) => setToYear(e.target.value)}
                    required
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                  <Form.Control.Feedback type="invalid">
                    {toYearError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm='4'>
                  <Form.Label className='custom-label'>Type
                    <span className='custom-label'> * </span>
                  </Form.Label>
                  <Form.Select
                    className='input'
                    size='lg'
                    value={leagueType}
                    // onChange={(e) => setLeagueType(e.target.value)}
                    onChange={handleOnChangeRegionType}
                    // style={{ fontSize: 11, padding: 10 }}
                    required
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  >
                    <option value=''>Open this select menu</option>
                    <option value="International">International</option>
                    <option value="Domestic">Domestic</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {typeError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Summary</Form.Label>
                  <Form.Control
                    className='input'
                    as="textarea"
                    rows={5}
                    placeholder="Enter the summary regarding your career as player"
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                {leagueType === 'Domestic' &&
                  <Form.Group as={Col} sm="4">
                    <Form.Label className='custom-label'>Country Name
                      <span className='text-danger'> * </span>
                    </Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      placeholder="Select the name of a country"
                      value={countryName}
                      onChange={e => handleSearchCountryFilter(e.target.value)}
                      required
                      disabled={inputFieldDisabled}
                      onClick={handleClick}
                    />
                    {isCountryLOVloading &&
                      <div className='lov-content mt-1'>
                        <ul className='lov-list' style={{ padding: 5 }}>
                          {
                            searchCountryLOVData.length > 0 ?
                              searchCountryLOVData.map((item) => (
                                <li className='lov-list-element' key={item.id} onClick={() => handleSelectCountry(item)}>
                                  {item.label}
                                </li>
                              ))

                              : <li className='lov-list-element' onClick={() => { setCountryName(''); setIsCountryLOVLoading(false); }}>
                                No data found. Please select a valid country.
                              </li>
                          }
                        </ul>
                      </div>
                    }
                    <Form.Control.Feedback type="invalid">
                      {countryError}
                    </Form.Control.Feedback>
                  </Form.Group>
                }

                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Club Name
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Select the name of a club"
                    value={teamName}
                    onChange={e => handleSearchFilter(e.target.value)}
                    required
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                  {isTeamLOVloading &&
                    <div className='lov-content mt-1'>
                      <ul className='lov-list' style={{ padding: 5 }}>
                        {
                          searchTeamLOVData.length > 0 ?
                            searchTeamLOVData.map((item) => (
                              <li className='lov-list-element' key={item.id} onClick={() => handleSelectTeam(item)}>
                                {item.label}
                              </li>
                            ))

                            // : <li className='lov-list-element'>No data found</li>
                            : setIsTeamLOVLoading(false)
                        }
                      </ul>
                    </div>
                  }
                  <Form.Control.Feedback type="invalid">
                    {teamError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>League
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Select the name of a league"
                    value={leagueName}
                    onChange={e => handleSearchLeagueFilter(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                  {isLeagueLOVloading &&
                    <div className='lov-content mt-1'>
                      <ul className='lov-list' style={{ padding: 5 }}>
                        {
                          searchLeagueLOVData.length > 0 ?
                            searchLeagueLOVData.map((item) => (
                              <li className='lov-list-element' key={item.id} onClick={() => handleSelectLeague(item)}>
                                {item.label}
                              </li>
                            ))

                            // : <li className='lov-list-element'>No data found</li>
                            : setIsLeagueLOVLoading(false)
                        }
                      </ul>
                    </div>
                  }
                  <Form.Control.Feedback type="invalid">
                    {leagueError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Games Played
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of games played"
                    value={gamesPlayed}
                    onChange={(e) => setGamesPlayed(e.target.value)}
                    required
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                  <Form.Control.Feedback type="invalid">
                    {gamesPlayedError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Goals Scored</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of goals scored"
                    value={goalsScored}
                    onChange={(e) => setGoalsScored(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Assists</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of assists"
                    value={assists}
                    onChange={(e) => setAssists(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Passes</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of games played"
                    value={passes}
                    onChange={(e) => setPasses(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Goals Saved</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of goals saved"
                    value={goalsSaved}
                    onChange={(e) => setGoalsSaved(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Interceptions</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of interceptions made in per game"
                    value={interceptionsPerGame}
                    onChange={(e) => setInterceptionsPerGame(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Tackels</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of takles made per game"
                    value={tackelsPerGame}
                    onChange={(e) => setTackelsPerGame(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Shots</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of takels made in per game"
                    value={shotsPerGame}
                    onChange={(e) => setShotsPerGame(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Key Passes</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of interceptions made in per game"
                    value={keyPassesPerGame}
                    onChange={(e) => setKeyPassesPerGame(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Dribbles Completed</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of takles made per game"
                    value={dribblesPerGame}
                    onChange={(e) => setDribblesPerGame(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Clean Sheets</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the clean sheets made in per game"
                    value={cleanSheetsPerGame}
                    onChange={(e) => setCleanSheetsPerGame(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
                <Form.Group as={Col} column sm="4">
                  <Form.Label className='custom-label'>Yellow Card</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of yellow card"
                    value={yellowCard}
                    onChange={(e) => setYellowCard(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} column sm="4">
                  <Form.Label className='custom-label'>Red Card</Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the number of red card"
                    value={redCard}
                    onChange={(e) => setRedCard(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Key Achievement</Form.Label>
                  <Form.Control
                    className='input'
                    as="textarea"
                    rows={5}
                    placeholder="Enter the key achievements"
                    value={keyAchievements}
                    onChange={(e) => setKeyAchievements(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Do you want Endorsement?</Form.Label>
                  <Form.Check
                    type="checkbox"
                    size='lg'
                    label="Yes"
                    checked={checkedEndorsement}
                    // onChange={() => setCheckedEndorsement(!checkedEndorsement)}
                    onChange={handleCheckedEndorsement}
                    disabled={inputFieldDisabled}
                  />
                </Form.Group>
                {checkedEndorsement && isEmailFieldVisible &&
                  <Form.Group as={Col} sm="4">
                    <Form.Label className='custom-label'>Email
                      <span className='text-danger'> * </span>
                    </Form.Label>
                    <Form.Control
                      className='input'
                      type="email"
                      size='lg'
                      placeholder="Enter the email address"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      required
                    />
                    <Form.Text muted>
                      As your club is not registered in bscoutd, please enter the email address of the club.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      Email cannot be empty
                    </Form.Control.Feedback>
                  </Form.Group>
                }
              </Row>

              {checkedEndorsement &&
                <Card>
                  <Card.Header as="h4" style={{ backgroundColor: '#e3f2fd' }}>Endorsement</Card.Header>
                  <Card.Body>

                    <Table striped responsive bordered hover>
                      <thead>
                        <tr>
                          <th>To Endorser</th>
                          <th>Type</th>
                          <th>Status</th>
                          <th>Remarks</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {playerEndorsementRequest && playerEndorsementRequest.length > 0 ?
                          playersEndorsementRequestList :
                          <tr>
                            <th colSpan={5} className='text-center'>
                              No rows found
                            </th>
                          </tr>
                        }
                      </tbody>
                    </Table>

                    {/* {personNameEndorsement.map((val, i) =>
                    <Row>
                      <Form.Group as={Col} sm="4">
                        <Form.Label className='custom-label'>Profile Type
                          <span className='text-danger'> * </span>
                        </Form.Label>
                        <Form.Select
                          className='input'
                          size='lg'
                          value={val.type}
                          onChange={(e) => handleChangeEndorsementType(e, i)}
                          required
                        >
                          <option value=''>Open this select menu</option>
                          <option value="player">Player</option>
                          <option value="coach">Coach</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {profileTypeError}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} sm="4">
                        <Form.Label className='custom-label'>Name
                          <span className='text-danger'> * </span>
                        </Form.Label>
                        <Stack direction="horizontal" gap={2}>
                          <Form.Control
                            className='input'
                            type="text"
                            size='lg'
                            placeholder="Enter the name of a person"
                            name="to_endorser_email"
                            value={val.to_endorser_email}
                            onChange={(e) => handleChangeEndorsement(e, i)}
                          />
                          <Button
                            variant="primary"
                            size='lg'
                            onClick={(e) => handleOnClickSearchEndorsementFilter(e, val.type, i)}
                          >
                            Search
                          </Button>
                        </Stack>
                        <Form.Control.Feedback type="invalid">
                          {personNameError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} sm="4">
                        <div className='d-flex justify-content-end mt-5'>
                          <div className='ms-2'>
                            <FontAwesomeIcon
                              icon={faPlus}
                              color='green'
                              style={{ padding: 11, border: '1px solid green', borderRadius: 5, cursor: 'pointer' }}
                              onClick={handleAddFieldEndorsement}
                            />
                          </div>
                          <div className='ms-2'>
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              color='red'
                              style={{ padding: 11, border: '1px solid red', borderRadius: 5, cursor: 'pointer' }}
                              onClick={() => handleDeleteEndorsement(i)}
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </Row>
                  )} */}

                  </Card.Body>
                </Card>
              }

              {checkedEndorsement && !inputFieldDisabled &&
                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>Comments</Form.Label>
                    <Form.Control
                      // className='input'
                      // type="text"
                      // size='lg'
                      placeholder="Enter the comments"
                      // name="text"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                      // onChange={(e) => onChangeHandleComments(e)}
                      as="textarea"
                      rows={5}
                      disabled={inputFieldDisabled}
                    />
                  </Form.Group>
                </Row>
              }

            </Form>
          }
        </Modal.Body>
        <Modal.Footer>
          {!isSpinnerLoadingVisible &&
            <>
              <Button variant="secondary" onClick={handleEditCareerHistoryModalClose}>Close</Button>
              {!inputFieldDisabled &&
                <Button variant="primary" onClick={handleUpdatePlayerClubHistory}>Save Changes</Button>
              }
            </>
          }
        </Modal.Footer>
      </Modal >

      <Modal show={showAddVideosModal} onHide={handleAddVideosModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Add Video Clip</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validatedVideos}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Title
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the title of the video clip"
                  value={videoTitle}
                  onChange={(e) => setVideoTitle(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoTitleError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Url
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the url of the video clip posted on youtube"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoUrlError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddVideosModalClose}>Close</Button>
            <Button variant="primary" onClick={handleSubmitVideoClip}>Save Changes</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEditVideosModal} onHide={handleEditVideosModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Edit Video Clip</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validatedVideos}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Title
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the title of the video clip"
                  value={videoTitle}
                  onChange={(e) => setVideoTitle(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoTitleError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Url
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the url of the video clip posted on youtube"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoUrlError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditVideosModalClose}>Close</Button>
            <Button variant="primary" onClick={handleUpdateVideoClip}>Save Changes</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={true} />
      {
        loading &&
        <div className='overlay'>
          <div className="container">
            <div className='search-content' style={{ marginTop: 60, marginLeft: 40, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
              <ul className='content-list' style={{ padding: 10 }}>
                {
                  searchData.length > 0 ?
                    searchData.slice(0, 3).map((item) => (
                      <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                        {item.first_name} {item.last_name}
                      </li>
                    ))

                    : <li className='list-element'>No data found</li>
                }
                {searchData.length > 0 ?
                  <>
                    <hr />
                    <li className='see-all-btn'>
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ width: 280 }}
                        onClick={() => navigateToSearchAllWithParams(searchData)}
                      >
                        See all
                      </button>
                    </li>
                  </> :
                  null}
              </ul>
            </div>
          </div>
        </div>
      }

      <Container>
        <Row>

          <ProfileBuilderNavBar
            playerIsActive={selectedPlayerSwitchOption}
            coachIsActive={selectedCoachSwitchOption}
            agentIsActive={selectedAgentSwitchOption}
          />

          <Col xs={9}>
            <Card className='mt-3'>
              <Card.Body>
                <h2 className='text-center'>Player</h2>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                  justify
                  variant='underline'
                >
                  <Tab eventKey="general" title="GENERAL">
                    <Card>
                      <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Player Information</Card.Header>
                      <Card.Body>
                        <Table striped responsive bordered hover>
                          <thead>
                            <tr>
                              <th>Edit</th>
                              <th>Name</th>
                              <th>Primary Position</th>
                              <th>Secondary Position</th>
                              <th>Preferred Foot</th>
                              <th>Top Speed</th>
                              <th>My Worth</th>
                              <th>Is Open for Hiring</th>
                            </tr>
                          </thead>
                          <tbody>
                            {playerInfo !== '' ?
                              <tr>
                                <td>
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    color='#2222e785'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      getPlayerInfo(playerInfo.id);
                                      setShowEditGeneralModal(true);
                                    }}
                                  />
                                </td>
                                <td>{userPersonalData.first_name} {userPersonalData.last_name}</td>
                                <td>{playerInfo.primary_position}</td>
                                <td>{playerInfo.secondary_position}</td>
                                <td>{playerInfo.preferred_foot}</td>
                                <td>{playerInfo.top_speed}</td>
                                <td>{playerInfo.my_worth}</td>
                                <td>{playerInfo.is_open_for_hiring ? 'Yes' : 'No'}</td>
                              </tr>
                              :
                              <tr>
                                <td colSpan={8} className='text-center'>
                                  No rows found
                                </td>
                              </tr>
                            }
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Tab>

                  <Tab eventKey="careerhistory" title="CAREER HISTORY">
                    <Stack direction="horizontal" gap={2}>
                      <div className="p-2 ms-auto">
                        <Button
                          variant="primary"
                          onClick={() => setShowAddCareerHistoryModal(true)}
                        >
                          Create
                        </Button>
                      </div>
                    </Stack>
                    <div style={{ height: '350px', overflowY: 'scroll' }}>
                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of Career Histories</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>Club Name</th>
                                <th>From Year</th>
                                <th>To Year</th>
                                <th>League Name</th>
                                <th>Games Played</th>
                                <th>Goals Scored</th>
                                <th>Goals Saved</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {playerClub && playerClub.length > 0 ?
                                playerClubList :
                                <tr>
                                  <td colSpan={9} className='text-center'>
                                    No rows found
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>

                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of personal achievements</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>From Year</th>
                                <th>To Year</th>
                                <th>Achievement Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {playerClub && playerClub.length > 0 ?
                                personalAchievementList :
                                <tr>
                                  <th colSpan={3} className='text-center'>
                                    No rows found
                                  </th>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>

                  <Tab eventKey="videos" title="VIDEOS">
                    <Stack direction="horizontal" gap={2}>
                      <div className="p-2 ms-auto">
                        <Button
                          variant="primary"
                          onClick={() => setShowAddVideosModal(true)}
                        >
                          Create
                        </Button>
                      </div>
                    </Stack>
                    <div style={{ height: '350px', overflowY: 'scroll' }}>
                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of videos</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>Video Title</th>
                                <th>URL</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                videoClip && videoClip.length > 0 ?
                                  videoClipList
                                  :
                                  <tr>
                                    <td colSpan={4} className='text-center'>
                                      No rows found
                                    </td>
                                  </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>

                </Tabs>
              </Card.Body>
            </Card>

          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  )
}
