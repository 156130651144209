import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { doc, setDoc } from "firebase/firestore";
// import { db } from '../utils/Firebase';
import {
    BASE_URL,
    LOGIN_URL,
    TOKEN_REFRESH_URL,
    REGISTER_URL,
    USER_URL,
    SPORTPROFILETYPE_URL,
    GETPOSTITEM_URL,
    NEWS_URL,
    NEWS_ALL_URL,
    FACEBOOK_LOGIN_URL,
    GOOGLE_LOGIN_URL
} from "../services/Constants";
import axios from "axios";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {

    const [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    const [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null)
    const [loading, setLoading] = useState(true)
    // const [firstLogin, setFirstLogin] = useState(false)
    const [userPersonalData, setUserPersonalData] = useState('')
    const [playerInfo, setPlayerInfo] = useState('')
    const [playerCurrentClub, setPlayerCurrentClub] = useState('')
    const [coachCurrentClub, setCoachCurrentClub] = useState('')
    const [coachInfo, setCoachInfo] = useState('')
    const [userSportProfile, setUserSportProfile] = useState([])
    const [userList, setUserList] = useState([])
    const [sportProfileList, setSportProfileList] = useState([])
    const [sportProfileType, setSportProfileType] = useState('')
    const [postData, setPostData] = useState([])
    const [news, setNews] = useState([])
    const [allNews, setAllNews] = useState([])
    const [isRegisterLoading, setIsRegisterLoading] = useState(false);
    // const [permanentAddress, setPermanentAddress] = useState('');
    // const [presentAddress, setPresentAddress] = useState([]);

    const navigate = useNavigate()

    const notifyRegistration = () => toast.success("Registration successfull!", {
        theme: "colored",
    });

    const notifyRegistrationPersonalInfo = () => toast.success("Information updated successfully!", {
        theme: "colored",
    });

    const notifyRequestSendSuccessfully = () => toast.success("Request send successfully! Please check your mail.", {
        theme: "colored",
    });

    const notifyPasswordChangedSuccessfully = () => toast.success("Password changed successfully!", {
        theme: "colored",
    });

    const notifyError = () => toast.error("Something went Wrong! Please try again later.", {
        theme: "colored",
    });

    const notifyAuthenticateError = () => toast.error("Credentials are incorrect! Please provide correct email and password.", {
        theme: "colored",
    });

    const notifyClubWarning = () => toast.warning("Current Team is not boarded. So cannot send the approval request.", {
        theme: "colored",
    });

    const notifyEmailError = () => toast.error("We couldn't find an account associated with that email. Please try a different e-mail address.", {
        theme: "colored",
    });

    const notifyEmailExistError = () => toast.error("User with this email already exist. Please try a different e-mail address.", {
        theme: "colored",
    });

    const notifyResetPasswordTokenError = () => toast.error("Token is not valid. Or token has expired.", {
        theme: "colored",
    });

    const notifyPasswordError = () => toast.error("Old password is incorrect! Please provide correct password.", {
        theme: "colored",
    });

    const notifyCustomPasswordError = (passwordError) => toast.error(passwordError, {
        theme: "colored",
    });

    const notifyCustomSuccessMessage = (message) => toast.success(message, {
        theme: "colored",
    });

    const notifyCustomErrorMessage = (message) => toast.error(message, {
        theme: "colored",
    });

    const notifyCustomWarningMessage = (message) => toast.warn(message, {
        theme: "colored",
    });

    const handleLoginWithGoogle = async (response) => {
        // console.log("id_token", response.credential)
        const payload = response.credential
        try {
            // const server_res = await axios.post("http://localhost:8000/api/auth/google/", { "access_token": payload })
            const server_res = await axios.post(GOOGLE_LOGIN_URL, { "access_token": payload })
            console.log(server_res)
            // if (server_res.status === 200) {
            const tokens = server_res.data
            setAuthTokens(tokens)
            const jwtDecode = jwt_decode(server_res.data.access)
            setUser(jwtDecode)
            localStorage.setItem('authTokens', JSON.stringify(tokens))
            // navigate('/');
            console.log('jwt token decode: ', jwtDecode)
            if (jwtDecode.is_flag)
                navigate('/');
            else {
                // navigate('/', { state: { item } });
                // navigate(`/chooseprofile?flag=${responseData.is_flag}`);
                navigate('/chooseprofile');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response) {
                    console.error('Response data:', error.response.data);
                    // console.error('Response data DETAIL:', error.response.data.detail);
                    console.error('Response status:', error.response.status);
                    // console.error('Response headers:', error.response.headers);
                    // alert(`Error: ${error.response.status} - ${error.response.data.message || 'An error occurred'}`);
                    notifyCustomErrorMessage(error.response.data.detail);
                    notifyCustomErrorMessage(error.response.data.access_token[0]);
                }
                // The request was made but no response was received
                else if (error.request) {
                    console.error('Request data:', error.request);
                    // alert('Error: No response received from server.');
                    notifyCustomErrorMessage('Error: No response received from server.');
                }
                // Something happened in setting up the request that triggered an Error
                else {
                    console.error('Error message:', error.message);
                    // alert(`Error: ${error.message}`);
                    notifyCustomErrorMessage(`Error: ${error.message}`);
                }
            } else {
                // Handle non-Axios errors
                console.error('Non-Axios error:', error);
                //   alert('An unexpected error occurred.');
                notifyCustomErrorMessage('An unexpected error occurred.');
            }
        }
    }

    const handleLoginWithFacebook = async (response) => {
        // console.log("id_token", response.credential)
        const payload = response.data.accessToken
        try {
            // const server_res = await axios.post("http://localhost:8000/api/auth/facebook/", { "auth_token": payload })
            const server_res = await axios.post(FACEBOOK_LOGIN_URL, { "auth_token": payload })
            console.log(server_res)
            const tokens = server_res.data
            setAuthTokens(tokens)
            const jwtDecode = jwt_decode(server_res.data.access)
            setUser(jwtDecode)
            localStorage.setItem('authTokens', JSON.stringify(tokens))
            // navigate('/');
            if (jwtDecode.is_flag)
                navigate('/');
            else {
                // navigate('/', { state: { item } });
                // navigate(`/chooseprofile?flag=${responseData.is_flag}`);
                navigate('/chooseprofile');
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response) {
                    console.error('Response data:', error.response.data);
                    console.error('Response status:', error.response.status);
                    console.error('Response headers:', error.response.headers);
                    // alert(`Error: ${error.response.status} - ${error.response.data.message || 'An error occurred'}`);
                    notifyCustomErrorMessage(error.response.data.auth_token[0]);
                }
                // The request was made but no response was received
                else if (error.request) {
                    console.error('Request data:', error.request);
                    // alert('Error: No response received from server.');
                    notifyCustomErrorMessage('Error: No response received from server.');
                }
                // Something happened in setting up the request that triggered an Error
                else {
                    console.error('Error message:', error.message);
                    // alert(`Error: ${error.message}`);
                    notifyCustomErrorMessage(`Error: ${error.message}`);
                }
            } else {
                // Handle non-Axios errors
                console.error('Non-Axios error:', error);
                //   alert('An unexpected error occurred.');
                notifyCustomErrorMessage('An unexpected error occurred.');
            }
        }
    }

    // const handleAxiosError = (error) => {
    //     if (axios.isAxiosError(error)) {
    //         // The request was made and the server responded with a status code
    //         // that falls out of the range of 2xx
    //         if (error.response) {
    //             console.error('Response data:', error.response.data);
    //             console.error('Response status:', error.response.status);
    //             console.error('Response headers:', error.response.headers);
    //             // alert(`Error: ${error.response.status} - ${error.response.data.message || 'An error occurred'}`);
    //             notifyCustomErrorMessage(error.response.data.auth_token[0]);
    //         }
    //         // The request was made but no response was received
    //         else if (error.request) {
    //             console.error('Request data:', error.request);
    //             // alert('Error: No response received from server.');
    //             notifyCustomErrorMessage('Error: No response received from server.');
    //         }
    //         // Something happened in setting up the request that triggered an Error
    //         else {
    //             console.error('Error message:', error.message);
    //             // alert(`Error: ${error.message}`);
    //             notifyCustomErrorMessage(`Error: ${error.message}`);
    //         }
    //     } else {
    //         // Handle non-Axios errors
    //         console.error('Non-Axios error:', error);
    //         //   alert('An unexpected error occurred.');
    //         notifyCustomErrorMessage('An unexpected error occurred.');
    //     }
    // };

    const loginUser = async (e) => {
        e.preventDefault();
        // setSpinnerLoading(true);
        console.log('Form Submitted');
        let response = fetch(`${LOGIN_URL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'email': e.target.email.value, 'password': e.target.password.value })
        })
        // let data = (await response).json()
        let data = (await response).json()
        let status = (await response).status
        let responseData = (await data)
        console.log('response:', response)
        console.log('data:', responseData)
        console.log('data tokens:', responseData.tokens)
        // console.log('response status:', status)
        if (status === 200) {
            // setSpinnerLoading(false);
            setAuthTokens(responseData.tokens)
            const jwtDecode = jwt_decode(responseData.tokens.access)
            setUser(jwtDecode)
            localStorage.setItem('authTokens', JSON.stringify(responseData.tokens))
            if (jwtDecode.is_flag)
                navigate('/');
            else {
                if (jwtDecode.account_type === 'institute') {
                    // const isFlag = false;
                    // navigate(`/editprofileinstituition?isFlag=${isFlag}`);
                    navigate('/editprofileinstituition');
                }
                else
                    navigate('/chooseprofile');
            }
            // notifyRequestSendSuccessfully();
            // setTimeout(() => {
            //     navigate('/verification', { state: { tokens: tokens } })
            // }, 4000);
            // const userInfoResponse = getUserPersonalInfo(jwtDecode.user_id, tokens);
            // console.log('userInfoResponse:', userInfoResponse)
            // const responseData = (await userInfoResponse).json()
            // console.log('responseData:', responseData)
            // const responseStatus = (await userInfoResponse).status
            // console.log('responseStatus:', responseStatus)
            // if (responseStatus === 200) {
            //     const personalInfo = (await responseData);
            //     console.log('personalInfo:', personalInfo)
            //     const sportProfile = (await personalInfo.sport_profile_type);
            //     console.log('sportProfile:', sportProfile)
            //     if (sportProfile && sportProfile.length > 0) {
            //         sportProfile.forEach((personalData) => {
            //             if (personalData.is_active && personalData.profile_type === 'Player') {
            //                 if (personalInfo.player && personalInfo.player.length > 0) {
            //                     navigate('/');
            //                 } else {
            //                     // navigate('/editplayerprofile');
            //                     if (personalInfo.sport_type === 'Football')
            //                         navigate('/football/player/profile', { state: { login: 'first' } });
            //                     else if (personalInfo.sport_type === 'Cricket')
            //                         navigate('/cricket/player/profile', { state: { login: 'first' } });
            //                     else if (personalInfo.sport_type === 'Basketball')
            //                         navigate('/basketball/player/profile', { state: { login: 'first' } });
            //                 }
            //             } else if (personalData.is_active && personalData.profile_type === 'Coach') {
            //                 if (personalInfo.coach && personalInfo.coach.length > 0) {
            //                     navigate('/');
            //                 } else {
            //                     // navigate('/editcoachprofile');
            //                     if (personalInfo.sport_type === 'Football')
            //                         navigate('/football/coach/profile', { state: { login: 'first' } });
            //                     else if (personalInfo.sport_type === 'Cricket')
            //                         navigate('/cricket/coach/profile', { state: { login: 'first' } });
            //                     else if (personalInfo.sport_type === 'Basketball')
            //                         navigate('/basketball/coach/profile', { state: { login: 'first' } });
            //                 }
            //             } else if (personalData.is_active && personalData.profile_type === 'Agent') {
            //                 if (personalInfo.first_name && personalInfo.last_name) {
            //                     navigate('/');
            //                 } else {
            //                     // navigate('/editagentprofile');
            //                     if (personalInfo.sport_type === 'Football')
            //                         navigate('/football/agent/profile', { state: { login: 'first' } });
            //                     else if (personalInfo.sport_type === 'Cricket')
            //                         navigate('/cricket/agent/profile', { state: { login: 'first' } });
            //                     else if (personalInfo.sport_type === 'Basketball')
            //                         navigate('/basketball/agent/profile', { state: { login: 'first' } });
            //                 }
            //             } else if (personalData.is_active && personalData.profile_type === 'Instituition') {
            //                 if (personalInfo.club && personalInfo.club.length > 0) {
            //                     navigate('/');
            //                 } else {
            //                     // navigate('/editinstituitionprofile');
            //                     if (personalInfo.sport_type === 'Football')
            //                         navigate('/football/instituition/profile', { state: { login: 'first' } });
            //                     else if (personalInfo.sport_type === 'Cricket')
            //                         navigate('/cricket/instituition/profile', { state: { login: 'first' } });
            //                     else if (personalInfo.sport_type === 'Basketball')
            //                         navigate('/basketball/instituition/profile', { state: { login: 'first' } });
            //                 }
            //             }
            //         }
            //         )
            //     }
            //     else {
            //         navigate('/myprofile');
            //     }
            // } else if (responseStatus === 401) {
            //     // alert('User is not Authenticated!')
            //     notifyAuthenticateError()
            //     logoutUser()
            // } else {
            //     notifyError()
            //     logoutUser()
            // }
        } else if (status === 401) {
            // notifyAuthenticateError()
            // setSpinnerLoading(false);
            // setTimeout(() => {
            //     window.location.reload(false);
            // }, 4000);
            notifyCustomErrorMessage(responseData.detail);
        } else {
            // notifyError()
            notifyCustomErrorMessage('Invalid Credentials');
            // setTimeout(() => {
            //     window.location.reload(false);
            // }, 4000);
        }
        // return response;
    }

    const logoutUser = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        navigate('/login')
    }

    const updateToken = async () => {
        // e.preventDefault();
        console.log('Update Token Called!');
        let response = fetch(`${TOKEN_REFRESH_URL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'refresh': authTokens.refresh })
        })
        // let data = (await response).json()
        let data = (await response).json()
        let status = (await response).status
        let tokens = (await data)
        // console.log('data:', tokens)
        // console.log('data tokens:', (await data))
        // console.log('response:', response)
        // console.log('response status:', (await response).status)
        if (status === 200) {
            // console.log('We are inside the 200 status response true')
            setAuthTokens(tokens)
            setUser(jwt_decode(tokens.access))
            // console.log('jwt token decode: ', jwt_decode(tokens.access))
            localStorage.setItem('authTokens', JSON.stringify(tokens))
            // navigate('/')
        } else {
            logoutUser()
        }
    }

    const registerUser = async (e) => {
        e.preventDefault();
        setIsRegisterLoading(true);
        console.log('Form Register Submitted');
        const randomstring = require('randomstring');
        const randomString2 = randomstring.generate(7);
        // const randomCode = randomstring.generate(7);
        // const randomOtp = Math.floor(Math.random() * (1000 - 9999 + 1)) + 1000;
        let response = fetch(`${REGISTER_URL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'email': e.target.email.value,
                'password': e.target.pwd.value,
                'password2': e.target.pwd2.value,
                // 'contact_no': e.target.phone.value,
                'username': randomString2,
                // 'auth_provider': 'email',
                // 'otp': randomCode,
                // 'sport_type': e.target.sporttype.value
            })
        })
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        let emailStatusResponse = null;
        if ((await data).email !== undefined) {
            console.log('response status email:', (await data).email)
            // const emailStatusResponse = (await data).email;
            emailStatusResponse = 401
        }
        let passwordStatusResponse = [];
        if ((await data).password !== undefined) {
            console.log('response status password:', (await data).password)
            passwordStatusResponse = (await data).password.password;
            console.log('passwordStatusResponse:', passwordStatusResponse)
        }
        if (status === 200) {
            // setFirstLogin(true)
            // notifyRegistration()
            notifyCustomSuccessMessage('Successfully registered. Please check your mail for verification.')
            // setTimeout(() => {
            //     window.location.reload(false);
            // }, 1000);
        } else if (status === 400) {
            if (emailStatusResponse === 401) {
                notifyEmailExistError()
                // setTimeout(() => {
                //     window.location.reload(false);
                // }, 4000);
            }
            else if (passwordStatusResponse && passwordStatusResponse.length > 0) {
                // passwordStatusResponse.forEach((passwordStatus) => {
                //     notifyCustomPasswordError(passwordStatus);
                // })
                notifyCustomPasswordError(passwordStatusResponse[0]);
                // setTimeout(() => {
                //     window.location.reload(false);
                // }, 4000);
            } else {
                notifyCustomPasswordError("Password and Confirm Password doesn't match");
                // setTimeout(() => {
                //     window.location.reload(false);
                // }, 4000);
            }
        } else {
            notifyError()
        }
        setIsRegisterLoading(false);
    }

    const userPersonalInfo = async () => {
        // e.preventDefault();
        // console.log('user Personal Information');
        let response = fetch(`${USER_URL}/${user.user_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        const data = (await response).json()
        // console.log('data:', data)
        // console.log('response:', response)
        const status = (await response).status
        if (status === 200) {
            const personalInfo = (await data);
            setUserPersonalData(personalInfo);
            const sportProfile = (await personalInfo.sport_profile_type);
            setUserSportProfile(sportProfile);
            const player = (await personalInfo.player[0]);
            if (player) {
                setPlayerInfo(player);
                setPlayerCurrentClub(player.current_club_inside_name);
            }
            const coach = (await personalInfo.coach[0]);
            if (coach) {
                setCoachInfo(coach);
                setCoachCurrentClub(coach.current_team);
            }
        } else if (status === 401) {
            // alert('User is not Authenticated!')
            logoutUser()
        } else {
            alert('something went wrong!')
        }
    }

    const getUserPersonalInfo = async (userId, token) => {
        // e.preventDefault();
        // console.log('user Personal Information');
        let response = fetch(`${USER_URL}/${userId}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(token.access)
            },
        })
        return response;
    }

    const userSportProfileInfo = async () => {
        // e.preventDefault();
        // console.log('user Personal Information');
        let response = fetch(`${SPORTPROFILETYPE_URL}/${user.user_id}/request_list/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        // console.log('response:', response)
        const data = (await response).json()
        // console.log('data:', data)
        const status = (await response).status
        if (status === 200) {
            const profileInfo = (await data)
            setSportProfileList(profileInfo)
            if (profileInfo.length > 0) {
                profileInfo.forEach((data) => {
                    if (data.is_active && data.status === 'Current') {
                        setSportProfileType(data.profile_type);
                    }
                });
            }
        } else {
            notifyError();
        }
    }

    const getUserList = async () => {
        // e.preventDefault();
        // console.log('user List');
        let response = fetch(`${USER_URL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        const data = (await response).json()
        // console.log('data:', data)
        // console.log('response:', response)
        const status = (await response).status
        if (status === 200) {
            const list = (await data)
            setUserList(list)
        } else if (status === 401) {
            // alert('User is not Authenticated!')
            logoutUser()
        } else {
            // alert('something went wrong!')/
            notifyError()
        }
    }

    const getPostData = async () => {
        // e.preventDefault();
        // console.log('user Personal Information');
        let response = fetch(`${GETPOSTITEM_URL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const data = (await response).json()
        // console.log('data:', data)
        // console.log('response:', response)
        const status = (await response).status
        if (status === 200) {
            const post = (await data)
            // console.log('post: ', post)
            setPostData(post)
        } else {
            // alert('something went wrong!')
            notifyError()
        }
    }

    const getNews = async () => {
        // e.preventDefault();
        // console.log('user Personal Information');
        let response = fetch(`${NEWS_URL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        const data = (await response).json()
        // console.log('data:', data)
        // console.log('response:', response)
        const status = (await response).status
        if (status === 200) {
            const newsData = (await data)
            // console.log('newsData: ', newsData)
            setNews(newsData)
            // return personalInfo;
        } else {
            // alert('something went wrong!')
            notifyError()
        }
    }

    const getallNews = async () => {
        // e.preventDefault();
        // console.log('user Personal Information');
        let response = fetch(`${NEWS_ALL_URL}/${user.user_id}/request_list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        const data = (await response).json()
        // console.log('data:', data)
        // console.log('response:', response)
        const status = (await response).status
        if (status === 200) {
            const newsData = (await data)
            // console.log('newsData: ', newsData)
            setAllNews(newsData)
            // return personalInfo;
        } else {
            // alert('something went wrong!')
            notifyError()
        }
    }


    let contextData = {
        user: user,
        currentUser: user,
        authTokens: authTokens,
        userPersonalData: userPersonalData,
        userSportProfile: userSportProfile,
        playerInfo: playerInfo,
        playerCurrentClub: playerCurrentClub,
        coachInfo: coachInfo,
        coachCurrentClub: coachCurrentClub,
        userList: userList,
        sportProfileList: sportProfileList,
        sportProfileType: sportProfileType,
        postData: postData,
        news: news,
        allNews: allNews,
        isRegisterLoading: isRegisterLoading,
        handleLoginWithGoogle: handleLoginWithGoogle,
        handleLoginWithFacebook: handleLoginWithFacebook,
        loginUser: loginUser,
        logoutUser: logoutUser,
        registerUser: registerUser,
        getUserPersonalInfo: getUserPersonalInfo,
        userPersonalInfo: userPersonalInfo,
        userSportProfileInfo: userSportProfileInfo,
        getUserList: getUserList,
        getPostData: getPostData,
        getNews: getNews,
        getallNews: getallNews,
        notifyRegistrationPersonalInfo: notifyRegistrationPersonalInfo,
        notifyRequestSendSuccessfully: notifyRequestSendSuccessfully,
        notifyPasswordChangedSuccessfully: notifyPasswordChangedSuccessfully,
        notifyError: notifyError,
        notifyClubWarning: notifyClubWarning,
        notifyEmailError: notifyEmailError,
        notifyAuthenticateError: notifyAuthenticateError,
        notifyResetPasswordTokenError: notifyResetPasswordTokenError,
        notifyPasswordError: notifyPasswordError,
        notifyCustomPasswordError: notifyCustomPasswordError,
        notifyCustomSuccessMessage: notifyCustomSuccessMessage,
        notifyCustomErrorMessage: notifyCustomErrorMessage,
        notifyCustomWarningMessage: notifyCustomWarningMessage
    }

    useEffect(() => {
        let fourMinutes = 1000 * 60 * 4
        let interval = setInterval(() => {
            if (authTokens) {
                updateToken()
                getPostData()
            }
        }, fourMinutes)
        return () => clearInterval(interval)
    }, [authTokens, loading])

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}