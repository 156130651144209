import React, { useContext, useEffect, useState } from 'react';
// import { NetworkContext } from '../context/NetworkContext';
// import { UserConnections } from './CustomComponents';
import AuthContext from '../context/AuthContext';
import { CustomEventCard } from './CustomComponents';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

export default function EventsPosted() {

    let {
        allNews,
        getallNews
    } = useContext(AuthContext)

    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [searchVal, setSearchVal] = useState('');
    const [sortVal, setSortVal] = useState('Recently added');

    const navigate = useNavigate();

    const renderConnectedCard = allNews.filter((item) => {
        return searchVal.toLowerCase() === ''
            ? item
            : item.title.toLowerCase().includes(searchVal);
    }).map((item) => {
        return (
            <CustomEventCard
                item={item}
                onClickEvent={() => navigate("/news", { state: { item: item, from: 'event' } })}
            />
        );
    })

    const renderSortedCard = allNews.sort((a, b) => {
        return sortVal === 'Start date'
            ? a.start_date > b.start_date ? 1 : -1
            : sortVal === 'End date'
                ? a.end_date > b.end_date ? 1 : -1
                : a.id < b.id ? 1 : -1;
    }).filter((item) => {
        return searchVal.toLowerCase() === ''
            ? item
            : item.title.toLowerCase().includes(searchVal);
    }).map((item) => {
        return (
            <CustomEventCard
                item={item}
                onClickEvent={() => navigate("/news", { state: { item: item, from: 'event' } })}
            />
        );
    })

    useEffect(() => {
        getallNews();
        setSpinnerLoading(false);
    }, [])

    return (
        <div className='col'>
            <div className="card my-3">
                <div className="card-header" style={{ backgroundColor: '#2222e785', fontWeight: 600, color: '#fff', fontSize: 15, }}>
                    <div className="row mb-3">
                        <div className="col">
                            {allNews.length} events
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex">
                            <span>Sort by : </span>
                            <form>
                                <select
                                    class="form-select form-select-sm mx-1"
                                    aria-label="Small select example"
                                    value={sortVal}
                                    onChange={(e) => setSortVal(e.target.value)}
                                >
                                    <option value="Recently added" selected>Recently added</option>
                                    <option value="Start date">Start date</option>
                                    <option value="End date">End date</option>
                                </select>
                            </form>
                        </div>
                        <div className="col">
                            <form>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="search contacts"
                                    aria-label="search"
                                    // value={searchVal}
                                    onChange={e => setSearchVal(e.target.value)} />
                            </form>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {spinnerLoading ?
                        <div className='text-center'>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                        :
                        <div className="row g-4">
                            {
                                sortVal === 'Recently added'
                                    ? renderConnectedCard
                                    : renderSortedCard
                            }
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}
