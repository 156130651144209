import React, { useState, useContext, useEffect } from 'react'
import Navbar from '../components/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useLocation } from 'react-router-dom';
import { ADDRESS_URL, INSTITUITION_INFO_URL, SPORTPROFILETYPECHANGEUSERSTATUS_URL, SPORTPROFILE_URL, USER_URL, optionsCountry } from '../services/Constants';
import AuthContext from '../context/AuthContext'
import { Card, Col, Container, Row, Tab, Tabs, Form, Button, Table, Stack, Modal, Alert } from 'react-bootstrap';
// import '../components/css/SideNetworkBar.css';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db } from '../utils/Firebase';
import { doc, setDoc } from "firebase/firestore";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleChangePresentAddressSubmit, handleSetPresentAddressSubmit } from '../services/ApiServices';
import ProfileBuilderInstitutionNavbar from '../components/ProfileBuilderInstitutionNavbar';

export default function EditProfileInstituition() {

    let {
        user,
        authTokens,
        notifyRegistrationPersonalInfo,
        notifyCustomSuccessMessage,
        notifyError,
        notifyAuthenticateError,
        notifyCustomErrorMessage,
    } = useContext(AuthContext)

    const navigate = useNavigate();

    // const location = useLocation();
    // const params = new URLSearchParams(location.search);
    // const param = params.get('isFlag');

    // console.log('isFlag:', param);

    const [key, setKey] = useState('general');

    // State variable of search user profile in Navbar

    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    // const [flag, setFlag] = useState(param === undefined ? true : !param ? true : false);
    // const [profileType, setProfileType] = useState('institute');

    // State variable of User Personal Information

    const [userPersonalData, setUserPersonalData] = useState('')
    const [clubName, setClubName] = useState("");
    const [clubNameError, setClubNameError] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [contactNoError, setContactNoError] = useState("");
    const [founded, setFounded] = useState("");
    const [foundedError, setFoundedError] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [profilePhotoUrl, setProfilePhotoUrl] = useState('');

    // State variable of User address

    const [permanentAddress, setPermanentAddress] = useState('');
    const [pmAddressId, setPmAddressId] = useState(null);
    const [isPresentAddressChanged, setIsPresentAddressChanged] = useState(false);

    // State variable of present address
    const [presentAddress, setPresentAddress] = useState([]);
    const [addressId, setAddressId] = useState(null);
    const [addressLane, setAddressLane] = useState('');
    const [addressLaneError, setAddressLaneError] = useState('');
    const [landmark, setLandmark] = useState('');
    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState('');
    const [pin, setPin] = useState('');
    const [pinError, setPinError] = useState('');
    const [state, setState] = useState('');
    const [stateError, setStateError] = useState('');
    const [country, setCountry] = useState('');
    const [countryError, setCountryError] = useState('');
    const [addressType, setAddressType] = useState('');
    const [addressTypeError, setAddressTypeError] = useState('');

    // State variable to manage profile description

    const [profileDescription, setProfileDescription] = useState('')
    const [profileDescriptionError, setProfileDescriptionError] = useState('')

    // State varible to manage profile activation

    const [selectedSwitchOption, setSelectedSwitchOption] = useState(false);

    // State variable used to mange the behaviour of model

    const [showEditPersonalModal, setShowEditPersonalModal] = useState(false);
    const [showEditProfileDescriptionModal, setShowEditProfileDescriptionModal] = useState(false);
    const [showCreateAddressModal, setShowCreateAddressModal] = useState(false);
    const [showEditAddressModal, setShowEditAddressModal] = useState(false);

    // State variable to manage form validation

    const [validatedPersonalInfo, setValidatedPersonalInfo] = useState(false);
    const [validatedAboutMe, setValidatedAboutMe] = useState(false);
    const [validatedAddresses, setValidatedAddresses] = useState(false);

    // function responsible for handling navigation with params

    const navigateToSearchDataWithParams = (item) => {
        if (item.account_type === 'user')
            navigate(`/user/viewprofile?user_id=${item.id}`);
        else
            navigate(`/institute/viewprofile?institute_id=${item.id}`);
    };

    const navigateToSearchAllWithParams = (item) => {
        console.log('navigateToSearchAllWithParams');
        console.log('Search Data:', item);
        navigate("/searchall", { state: { item } });
    };

    // Get User Info function

    const getUserPersonalInfo = async (id) => {
        let response = fetch(`${USER_URL}/${user.user_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        console.log('response:', response)
        const data = (await response).json()
        console.log('data:', data)
        const userData = (await data)
        console.log('User info:', userData)
        const status = (await response).status
        if (status === 200) {
            setClubName(userData.club_name)
            setFounded(userData.dob)
            setContactNo(userData.contact_no)
            setProfileDescription(userData.bio)
        } else {
            notifyError();
        }
    }

    const getAddressInfo = async (id) => {
        setAddressId(id)
        let response = fetch(`${ADDRESS_URL}/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        console.log('response:', response)
        const data = (await response).json()
        console.log('data:', data)
        const address = (await data)
        console.log('address:', address)
        const status = (await response).status
        if (status === 200) {
            setAddressLane(address.address_lane)
            setLandmark(address.landmark)
            setCity(address.city)
            setState(address.state)
            setCountry(address.country)
            setPin(address.pin)
            setAddressType(address.address_type)
        } else {
            notifyError();
        }
    }

    const getPresentAddressInfo = async (id) => {
        setAddressId(id)
        let response = fetch(`${ADDRESS_URL}/${id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        console.log('response:', response)
        const data = (await response).json()
        console.log('data:', data)
        const address = (await data)
        console.log('address:', address)
        const status = (await response).status
        if (status === 200) {
            setAddressLane(address.address_lane)
            setLandmark(address.landmark)
            setCity(address.city)
            setState(address.state)
            setCountry(address.country)
            setPin(address.pin)
            setAddressType(address.address_type)
        } else {
            notifyError();
        }
    }

    // handle form submit

    const handleSubmit = async (event) => {
        event.preventDefault();
        // setIsLoading(true);
        setValidatedPersonalInfo(true);
        const formData = new FormData();
        if (clubName !== '') {
            formData.append("club_name", clubName);
            setClubNameError('')
        } else {
            setClubNameError('Club name cannot be empty')
            return;
        }
        if (founded !== null) {
            // console.log('dob', founded);
            formData.append("dob", founded);
            setFoundedError('')
        } else {
            // console.log('dob', founded);
            setFoundedError('Date of Foundation cannot be empty')
            return;
        }
        if (contactNo !== '') {
            formData.append("contact_no", contactNo);
            setContactNoError('')
        } else {
            setContactNoError('Contact number cannot be empty')
            return;
        }
        if (selectedFile !== null) {
            formData.append("profile_photo", selectedFile);

            // Create the file metadata
            /** @type {any} */
            const metadata = {
                contentType: 'image/jpeg'
            };

            // Upload file and metadata to the object 'images/mountains.jpg'
            const storageRef = ref(storage, 'images/' + selectedFile.name);
            const uploadTask = uploadBytesResumable(storageRef, selectedFile, metadata);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    notifyError()
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        console.log('File available at', downloadURL);
                        formData.append("profile_photo_url", downloadURL);
                        setProfilePhotoUrl(downloadURL);
                        // Log the key/value pairs
                        // for (const pair of formData.entries()) {
                        //   console.log(pair[0] + ': ' + pair[1]);
                        // }
                        // Django Rest Api
                        let response = fetch(`${INSTITUITION_INFO_URL}/${user.user_id}/`, {
                            method: "PATCH",
                            headers: {
                                // 'Content-Type': 'application/json',
                                // 'Content-Type': 'multipart/form-data',
                                'Authorization': 'Bearer ' + String(authTokens.access)
                            },
                            body: formData,
                        });
                        console.log('response:', response)
                        let data = (await response).json()
                        console.log('data:', data)
                        let status = (await response).status
                        console.log('status:', status)
                        if (status === 200) {
                            // Add a new document with a generated id in collection "users"
                            // const newUserRef = doc(collection(db, "users"));
                            await setDoc(doc(db, "users", userPersonalData.username), {
                                uid: userPersonalData.username,
                                name: clubName,
                                email: userPersonalData.email,
                                photoURL: downloadURL,
                                connected: [],
                            });

                            await setDoc(doc(db, "userChats", userPersonalData.username), {});
                            notifyRegistrationPersonalInfo()
                            setClubName('')
                            setClubNameError('')
                            setContactNo('')
                            setContactNoError('')
                            setFounded('')
                            setFoundedError('')
                            setSelectedFile(null)
                            setValidatedPersonalInfo(false)
                            setShowEditPersonalModal(false)
                        } else {
                            notifyError()
                        }
                    });
                }
            );
            return;
        }
        formData.append("profile_photo_url", profilePhotoUrl);
        // Log the key/value pairs
        // for (const pair of formData.entries()) {
        //   console.log(pair[0] + ': ' + pair[1]);
        // }
        let response = fetch(`${INSTITUITION_INFO_URL}/${user.user_id}/`, {
            method: "PATCH",
            headers: {
                // 'Content-Type': 'application/json',
                // 'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
            body: formData,
        });
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 200) {
            // Add a new document with a generated id in collection "users"
            // const newUserRef = doc(collection(db, "users"));
            await setDoc(doc(db, "users", userPersonalData.username), {
                uid: userPersonalData.username,
                name: clubName,
                email: userPersonalData.email,
                photoURL: profilePhotoUrl,
                connected: [],
            });
            await setDoc(doc(db, "userChats", userPersonalData.username), {});
            notifyRegistrationPersonalInfo()
            setClubName('')
            setClubNameError('')
            setContactNo('')
            setContactNoError('')
            setFounded('')
            setFoundedError('')
            setSelectedFile(null)
            setValidatedPersonalInfo(false)
            setShowEditPersonalModal(false)
        } else {
            notifyError()
        }
    }

    const handleSubmitPresentAddress = async (event) => {
        event.preventDefault();
        // setIsLoading(true);
        // setValidatedPresentAddress(true);
        setValidatedAddresses(true);
        const formData = new FormData();
        if (addressLane === '') {
            setAddressLaneError('Address lane cannot be empty')
            return;
        }
        else {
            setAddressLaneError('')
            formData.append("address_lane", addressLane);
        }
        if (city === '') {
            setCityError('City cannot be empty')
            return;
        }
        else {
            setCityError('')
            formData.append("city", city);
        }
        if (pin === '') {
            setPinError('Zip code cannot be empty')
            return;
        }
        else {
            setPinError('')
            formData.append("pin", pin);
        }
        if (state === '') {
            setStateError('State cannot be empty')
            return;
        }
        else {
            setStateError('')
            formData.append("state", state);
        }
        if (country === '') {
            setCountryError('Country cannot be empty')
            return;
        }
        else {
            setCountryError('')
            formData.append("country", country);
        }
        if (addressType === '') {
            setAddressTypeError('Address type cannot be empty')
            return;
        }
        else {
            setAddressTypeError('')
            formData.append("address_type", addressType);
        }
        formData.append("landmark", landmark);
        formData.append("present_user_id", [user.user_id]);
        let response = fetch(`${ADDRESS_URL}/`, {
            method: "POST",
            headers: {
                // 'Content-Type': 'application/json',
                // 'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
            body: formData,
        });
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 201) {
            notifyRegistrationPersonalInfo()
            setAddressLane('')
            setAddressLaneError('')
            setLandmark('')
            setCity('')
            setCityError('')
            setPin('')
            setPinError('')
            setState('')
            setStateError('')
            setCountry('')
            setCountryError('')
            setAddressType('')
            setAddressTypeError('')
            setValidatedAddresses(false)
            setShowCreateAddressModal(false)
        } else {
            notifyError()
        }
    }

    // handle update function

    const handleUpdateProfileDescription = async (event) => {
        event.preventDefault();
        setValidatedAboutMe(true)
        // setIsLoading(true)
        const formData = new FormData();
        if (profileDescription === '') {
            setProfileDescriptionError('Profile description cannot be empty')
            return;
        } else {
            setProfileDescriptionError('')
            formData.append("bio", profileDescription);
        }
        let response = fetch(`${INSTITUITION_INFO_URL}/${user.user_id}/`, {
            method: "PATCH",
            headers: {
                // 'Content-Type': 'application/json',
                // 'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
            body: formData,
        });
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 200) {
            notifyRegistrationPersonalInfo()
            setProfileDescription('')
            setProfileDescriptionError('')
            // setIsLoading(false)
            setValidatedAboutMe(false)
            setShowEditProfileDescriptionModal(false)
        } else {
            notifyError()
        }
    }

    const handleUpdateAddress = async (event) => {
        event.preventDefault();
        // setIsLoading(true);
        // setValidatedPermanentAddress(true);
        setValidatedAddresses(true);
        const formData = new FormData();
        if (addressLane === '') {
            setAddressLaneError('Address Lane cannot be empty')
            return;
        } else {
            setAddressLaneError('')
            formData.append("address_lane", addressLane);
        }
        if (city === '') {
            setCityError('City cannot be empty')
            return;
        } else {
            setCityError('')
            formData.append("city", city);
        }
        if (pin === '') {
            setPinError('Zip code cannot be empty')
            return;
        } else {
            setPinError('')
            formData.append("pin", pin);
        }
        if (state === '') {
            setStateError('State cannot be empty')
            return;
        } else {
            setStateError('')
            formData.append("state", state);
        }
        if (country === '') {
            setCountryError('Country cannot be empty')
            return;
        } else {
            setCountryError('')
            formData.append("country", country);
        }
        if (addressType === '') {
            setAddressTypeError('Address type cannot be empty')
            return;
        } else {
            setAddressTypeError('')
            formData.append("address_type", addressType);
        }
        formData.append("landmark", landmark);
        let response = fetch(`${ADDRESS_URL}/${addressId}/`, {
            method: "PATCH",
            body: formData,
        });
        console.log('response:', response)
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 200) {
            notifyRegistrationPersonalInfo()
            setAddressLane('')
            setAddressLaneError('')
            setLandmark('')
            setCity('')
            setCityError('')
            setPin('')
            setPinError('')
            setState('')
            setStateError('')
            setCountry('')
            setCountryError('')
            setAddressType('')
            setAddressTypeError('')
            setValidatedAddresses(false)
            setShowEditAddressModal(false)
        } else {
            notifyError()
        }
    }

    // Delete function to handle User Data

    const handleDelete = async (id, type) => {
        // alert(id)
        console.log('Handle Delete function called');
        let response = fetch(`${ADDRESS_URL}/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        console.log('response:', response)
        let status = (await response).status
        if (status === 204) {
            notifyCustomSuccessMessage('Address deleted successfully!');
            if (type === 'permanent') {
                setPermanentAddress('')
            } else {
                const newAddresses = presentAddress.filter((address) => address.id !== id);
                setPresentAddress(newAddresses)
            }
        } else {
            // alert('Something went wrong!')
            notifyError()
        }
    }

    //  handle form close

    const handleEditPersonalModalClose = () => {
        setShowEditPersonalModal(false);
        setValidatedPersonalInfo(false);
        setClubName('');
        setClubNameError('');
        setFounded('');
        setFoundedError('');
        setContactNo('');
        setContactNoError('');
        setSelectedFile(null);
    }

    const handleEditProfileDescriptionModalClose = () => {
        setShowEditProfileDescriptionModal(false);
        setValidatedAboutMe(false);
        setProfileDescription('');
        setProfileDescriptionError('');
    }

    const handleCreateAddressModalClose = () => {
        setShowCreateAddressModal(false);
        setValidatedAddresses(false);
        setAddressId(null);
        setAddressLane('');
        setAddressLaneError('');
        setAddressType('');
        setAddressTypeError('');
        setCity('');
        setCityError('');
        setState('');
        setStateError('');
        setPin('');
        setPinError('');
        setCountry('');
        setCountryError('');
    }

    const handleEditAddressModalClose = () => {
        setShowEditAddressModal(false);
        setValidatedAddresses(false);
        setAddressId(null);
        setAddressLane('');
        setAddressLaneError('');
        setAddressType('');
        setAddressTypeError('');
        setCity('');
        setCityError('');
        setState('');
        setStateError('');
        setPin('');
        setPinError('');
        setCountry('');
        setCountryError('');
    }

    // handle change address from other's to present

    const handleChangePresentAddress = async (psAddressId) => {
        // setAddressStatus(event.target.checked);
        console.log('Address Id:', psAddressId);
        let response;
        if (pmAddressId)
            response = handleChangePresentAddressSubmit(psAddressId, pmAddressId, user.user_id);
        else
            response = handleSetPresentAddressSubmit(psAddressId, user.user_id);
        let data = (await response).json()
        console.log('data:', data)
        let status = (await response).status
        console.log('status:', status)
        if (status === 200) {
            notifyCustomSuccessMessage('Address changed successfully.');
            setIsPresentAddressChanged(true);
        } else {
            notifyError();
        }
    }

    //  handle on Change profile activation

    // Handle switch toggle
    const handleSwitchToggle = () => {
        console.log('handleSwitchToggle function called')

        // setSelectedPlayerStatusOption({
        //     ...selectedPlayerStatusOption,
        //     'is_active': selectedSwitchOption,
        // });
        setSelectedSwitchOption(!selectedSwitchOption)
        // console.log('selections:', selectedPlayerStatusOption)
        console.log('selected switch options:', selectedSwitchOption)

        // Prepare the data to be sent to the server
        const postData = {
            profile_type: 'Institution',
            status: !selectedSwitchOption ? 'Current' : 'Not current',
            is_active: !selectedSwitchOption,
            user: user.user_id
        };

        console.log('postData:', JSON.stringify(postData));

        handlePostSportProfile(postData);
    };

    const handlePostSportProfile = (postData) => {
        // Make a POST request to the server
        fetch(`${SPORTPROFILE_URL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // Add any additional headers if needed
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                // Check if response is ok
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Handle successful response
                // console.log(response.json());
                notifyCustomSuccessMessage('Data saved successfully');
                // setFlag(true);
                // setIsLoading(true);
            })
            .catch(error => {
                // Handle fetch errors
                // console.error('Error posting data:', error);
                notifyCustomErrorMessage(error);
            });
    }

    // useEffect 

    // handle user personal information

    const userPersonalInfo = async () => {
        // e.preventDefault();
        console.log('user Personal Information');
        let response = fetch(`${USER_URL}/${user.user_id}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        const data = (await response).json()
        console.log('data:', data)
        console.log('response:', response)
        const status = (await response).status
        if (status === 200) {
            const personalInfo = (await data)
            console.log('personalInfo: ', personalInfo)
            setUserPersonalData(personalInfo)
            // setFlag(personalInfo.is_flag)
            setClubName(personalInfo.club_name)
            setFounded(personalInfo.dob)
            setContactNo(personalInfo.contact_no)
            const pmAddress = (await personalInfo.permanent_address[0])
            // console.log('Profile, permenent address: ', pmAddress)
            if (pmAddress) {
                setPermanentAddress(pmAddress)
                setPmAddressId(pmAddress.id)
            } else {
                setPermanentAddress('')
            }
            const psAddress = (await personalInfo.present_address)
            // console.log('Profile, present address: ', psAddress)
            setPresentAddress(psAddress)
            const sportProfileType = (await personalInfo.sport_profile_type)
            sportProfileType.map(item => {
                if (item.profile_type === 'Institution') {
                    if (item.is_active === true)
                        setSelectedSwitchOption(true)
                    else
                        setSelectedSwitchOption(false)
                }
            })
        } else if (status === 401) {
            notifyAuthenticateError();
        } else {
            notifyError();
        }
    }

    useEffect(() => {
        userPersonalInfo();
    }, [showEditPersonalModal, showCreateAddressModal, showEditAddressModal, showEditProfileDescriptionModal, isPresentAddressChanged]);

    const presentAddressList = presentAddress.map((address) => {
        return (
            <tr key={address.id}>
                <td>
                    <FontAwesomeIcon
                        icon={faEdit}
                        color='#2222e785'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            getPresentAddressInfo(address.id);
                            setShowEditAddressModal(true);
                        }}
                    />
                </td>
                <td>{address.address_lane}</td>
                <td>{address.landmark}</td>
                <td>{address.city}</td>
                <td>{address.pin}</td>
                <td>{address.state}</td>
                <td>{address.country}</td>
                <td>{address.address_type}</td>
                <td>
                    <Form>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            // checked={addressStatus}
                            onChange={(e) => handleChangePresentAddress(address.id)}
                        />
                    </Form>
                </td>
                <td>
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        color='red'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDelete(address.id, 'others')}
                    />
                </td>
            </tr>
        );
    })

    return (
        <>

            <Modal show={showEditPersonalModal} onHide={handleEditPersonalModalClose} size="xl">
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Institution Information</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validatedPersonalInfo}>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Club Name
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter the club name"
                                    value={clubName}
                                    onChange={(e) => setClubName(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {clubNameError}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Founded
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="date"
                                    size='lg'
                                    placeholder="Enter the founded date"
                                    value={founded}
                                    onChange={(e) => setFounded(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {foundedError}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Contact Number
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="number"
                                    size='lg'
                                    placeholder="Enter the contact number"
                                    value={contactNo}
                                    onChange={(e) => setContactNo(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {contactNoError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row className="mb-4">
                            <Form.Group as={Col} controlId="formFile">
                                <Form.Label className='custom-label'>Profile Photo</Form.Label>
                                <Form.Control
                                    type="file"
                                    size='lg'
                                    // value={selectedFile}
                                    onChange={(e) => setSelectedFile(e.target.files[0])}
                                />
                            </Form.Group>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleEditPersonalModalClose}>Close</Button>
                        <Button variant="primary" onClick={handleSubmit}>Save Changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showEditProfileDescriptionModal} onHide={handleEditProfileDescriptionModalClose} size="xl">
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Club Description</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validatedAboutMe}>
                    <Modal.Body>
                        <Row className="mb-4">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Description
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    as="textarea"
                                    rows={6}
                                    placeholder="Enter the description"
                                    value={profileDescription}
                                    onChange={(e) => setProfileDescription(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {profileDescriptionError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleEditProfileDescriptionModalClose}>Close</Button>
                        <Button variant="primary" onClick={handleUpdateProfileDescription}>Save Changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showCreateAddressModal} onHide={handleCreateAddressModalClose} size="xl">
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Address</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validatedAddresses}>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Address Lane
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type='text'
                                    size='lg'
                                    placeholder="Enter your address lane"
                                    value={addressLane}
                                    onChange={(e) => setAddressLane(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {addressLaneError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Landmark</Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your landmark"
                                    value={landmark}
                                    onChange={(e) => setLandmark(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>City
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your city"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {cityError}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Zip Code
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your zip code"
                                    value={pin}
                                    onChange={(e) => setPin(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {pinError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>State
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your state"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {stateError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Country
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Select
                                    size='lg'
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    required
                                >
                                    <option value="">Open this select menu</option>
                                    {optionsCountry.map((country) => (
                                        <option value={country.value}>
                                            {country.label}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {countryError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Address Type
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Select
                                    size='lg'
                                    value={addressType}
                                    onChange={(e) => setAddressType(e.target.value)}
                                    required
                                >
                                    <option value="">Open this select menu</option>
                                    <option value="Home">Home</option>
                                    <option value="Work">Work</option>
                                    <option value="Both">Both</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {addressTypeError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCreateAddressModalClose}>Close</Button>
                        <Button variant="primary" onClick={handleSubmitPresentAddress}>Save Changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={showEditAddressModal} onHide={handleEditAddressModalClose} size="xl">
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Address</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validatedAddresses}>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Form.Group as={Col} sm="4">
                                <Form.Label className='custom-label'>Address Lane
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type='text'
                                    size='lg'
                                    placeholder="Enter your address lane"
                                    value={addressLane}
                                    onChange={(e) => setAddressLane(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {addressLaneError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Landmark</Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your landmark"
                                    value={landmark}
                                    onChange={(e) => setLandmark(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>City
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your city"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {cityError}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Zip Code
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your zip code"
                                    value={pin}
                                    onChange={(e) => setPin(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {pinError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-4">
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>State
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Control
                                    className='input'
                                    type="text"
                                    size='lg'
                                    placeholder="Enter your state"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {stateError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Country
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Select
                                    size='lg'
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    required
                                >
                                    <option value="">Open this select menu</option>
                                    {optionsCountry.map((country) => (
                                        <option value={country.value}>
                                            {country.label}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {countryError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='custom-label'>Address Type
                                    <span className='text-danger'> * </span>
                                </Form.Label>
                                <Form.Select
                                    size='lg'
                                    value={addressType}
                                    onChange={(e) => setAddressType(e.target.value)}
                                    required
                                >
                                    <option value="">Open this select menu</option>
                                    <option value="Home">Home</option>
                                    <option value="Work">Work</option>
                                    <option value="Both">Both</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {addressTypeError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleEditAddressModalClose}>Close</Button>
                        <Button variant="primary" onClick={handleUpdateAddress}>Save Changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={user.is_flag} profileType={'institute'} />
            {
                loading &&
                <div className='overlay'>
                    <div className="container">
                        <div className='search-content' style={{ marginTop: 60, marginLeft: 40, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
                            <ul className='content-list' style={{ padding: 10 }}>
                                {
                                    searchData.length > 0 ?
                                        searchData.slice(0, 3).map((item) => (
                                            <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                                                {item.account_type === 'user' ? item.first_name + ' ' + item.last_name : item.club_name}
                                            </li>
                                        ))

                                        : <li className='list-element'>No data found</li>
                                }
                                {searchData.length > 0 ?
                                    <>
                                        <hr />
                                        <li className='see-all-btn'>
                                            <button
                                                type="button"
                                                className="btn btn-outline-dark"
                                                style={{ width: 280 }}
                                                onClick={() => navigateToSearchAllWithParams(searchData)}
                                            >
                                                See all
                                            </button>
                                        </li>
                                    </> :
                                    null}
                            </ul>
                        </div>
                    </div>
                </div>
            }

            <Container>

                <Row>
                    <ProfileBuilderInstitutionNavbar instituitionIsActive={selectedSwitchOption} />

                    <Col xs={9}>
                        {!user.is_flag &&
                            <Alert variant="success" className='mt-3'>
                                Please wait for the activation of your account. We will reach out to you as soon as possible.
                            </Alert>
                        }
                        <Card className='mt-3'>
                            <Card.Body>
                                <h2 className='text-center'>Institute Information</h2>
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="mb-3"
                                    fill
                                    variant='underline'
                                >
                                    <Tab eventKey="general" title="GENERAL">
                                        <Card>
                                            <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Institute Information</Card.Header>
                                            <Card.Body>
                                                <Table striped responsive bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Edit</th>
                                                            <th>Club Name</th>
                                                            <th>Email</th>
                                                            <th>Contact No</th>
                                                            <th>Founded</th>
                                                            <th>Profile Photo</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    icon={faEdit}
                                                                    color='#2222e785'
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        getUserPersonalInfo(userPersonalData.id);
                                                                        setShowEditPersonalModal(true);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>{userPersonalData.club_name}</td>
                                                            <td>{userPersonalData.email}</td>
                                                            <td>{userPersonalData.contact_no}</td>
                                                            <td>{userPersonalData.dob}</td>
                                                            <td>
                                                                {userPersonalData.profile_photo !== null ?
                                                                    <img src={userPersonalData.profile_photo} alt={userPersonalData.club_name} style={{ maxWidth: '50px' }} />
                                                                    : null
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Tab>

                                    <Tab eventKey="aboutme" title="ABOUT ME">
                                        <Card>
                                            <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Summary</Card.Header>
                                            <Card.Body>
                                                <Table striped responsive bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Edit</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    icon={faEdit}
                                                                    color='#2222e785'
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        getUserPersonalInfo(userPersonalData.id);
                                                                        setShowEditProfileDescriptionModal(true);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>{userPersonalData.bio}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Tab>

                                    <Tab eventKey="addresses" title="ADDRESSES">
                                        <Stack direction="horizontal">
                                            <div className="p-2 ms-auto">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => setShowCreateAddressModal(true)}
                                                >
                                                    Create
                                                </Button>
                                            </div>
                                        </Stack>
                                        <div style={{ height: '350px', overflowY: 'scroll' }}>
                                            <Card>
                                                <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Permanent Address</Card.Header>
                                                <Card.Body>
                                                    <Table striped responsive bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Edit</th>
                                                                <th>Address Lane</th>
                                                                <th>Landmark</th>
                                                                <th>City</th>
                                                                <th>Zip Code</th>
                                                                <th>State</th>
                                                                <th>Country</th>
                                                                <th>Address Type</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {permanentAddress !== '' ?
                                                                <tr>
                                                                    <td>
                                                                        <FontAwesomeIcon
                                                                            icon={faEdit}
                                                                            color='#2222e785'
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                getAddressInfo(permanentAddress.id);
                                                                                setShowEditAddressModal(true);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>{permanentAddress.address_lane}</td>
                                                                    <td>{permanentAddress.landmark}</td>
                                                                    <td>{permanentAddress.city}</td>
                                                                    <td>{permanentAddress.pin}</td>
                                                                    <td>{permanentAddress.state}</td>
                                                                    <td>{permanentAddress.country}</td>
                                                                    <td>{permanentAddress.address_type}</td>
                                                                    <td>
                                                                        <FontAwesomeIcon
                                                                            icon={faTrashCan}
                                                                            color='red'
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => handleDelete(permanentAddress.id, 'permanent')}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                : <tr>
                                                                    <td className='text-center' colSpan={9}>
                                                                        No data found
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>

                                            <Card>
                                                <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Other Addresses</Card.Header>
                                                <Card.Body>
                                                    <Table striped responsive bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Edit</th>
                                                                <th>Address Lane</th>
                                                                <th>Landmark</th>
                                                                <th>City</th>
                                                                <th>Zip Code</th>
                                                                <th>State</th>
                                                                <th>Country</th>
                                                                <th>Address Type</th>
                                                                <th>Mark as Permanent</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                presentAddress && presentAddress.length > 0 ?
                                                                    presentAddressList
                                                                    :
                                                                    <tr>
                                                                        <td className='text-center' colSpan={10}>
                                                                            No data found
                                                                        </td>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="selectprofiles" title="SELECT PROFILE">
                                        <Card>
                                            <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Create Profile</Card.Header>
                                            <Card.Body>
                                                <Table striped responsive bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Profile Type</th>
                                                            <th>Active/Inactive</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Institution</td>
                                                            <td>
                                                                <Form.Check
                                                                    type="switch"
                                                                    id="custom-switch-player"
                                                                    label={selectedSwitchOption ? 'Active' : 'Inactive'}
                                                                    checked={selectedSwitchOption}
                                                                    onChange={handleSwitchToggle}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </>
    )
}
