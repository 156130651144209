import React, { useState, useContext, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faEdit, faCheck, faCheckDouble, faL } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { Card, Col, Container, Row, Tab, Tabs, Form, Button, Table, Stack, Modal, Spinner } from 'react-bootstrap';
import { COACHCAREERHISTORY_URL, COACHLICENSE_URL, COACH_URL, COUNTRY_URL, FOOTBALLCOACHCAREERHISTORYCREATE_URL, FOOTBALLCOACHCAREERHISTORYLEAGUECREATE_URL, FOOTBALLCOACHCAREERHISTORYLEAGUEUPDATE_URL, FOOTBALLCOACHCAREERHISTORYTEAMLEAGUEUPDATE_URL, FOOTBALLCOACHCAREERHISTORYUPDATE_URL, FOOTBALLCOACHENDORSEMENTREQUEST_URL, FOOTBALLCOACHLICENSECREATE_URL, FOOTBALLCOACHLICENSEUPDATE_URL, GET_INSTITUITION_URL, LEAGUES_URL, SPORTLICENSE_URL, TEAMS_URL, USER_URL, VIDEOCLIP_URL } from '../../services/Constants';
import AuthContext from '../../context/AuthContext'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { ProfileBuilderNavBar } from '../../components/ProfileBuilderNavBar';
import axios from 'axios';

export default function EditCoachProfile() {

  let {
    user,
    authTokens,
    notifyRegistrationPersonalInfo,
    notifyCustomSuccessMessage,
    notifyError,
    notifyAuthenticateError,
    notifyCustomErrorMessage
  } = useContext(AuthContext)

  const navigate = useNavigate();

  const [key, setKey] = useState('general');

  // State variable of search user profile in Navbar

  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  // State variable for managing search lov filter

  const [isTeamLOVloading, setIsTeamLOVLoading] = useState(false);
  const [searchTeamLOVData, setSearchTeamLOVData] = useState([]);
  const [isLeagueLOVloading, setIsLeagueLOVLoading] = useState(false);
  const [searchLeagueLOVData, setSearchLeagueLOVData] = useState([]);
  const [isCountryLOVloading, setIsCountryLOVLoading] = useState(false);
  const [searchCountryLOVData, setSearchCountryLOVData] = useState([]);
  const [searchLicenseLOVData, setSearchLicenseLOVData] = useState([]);
  const [isLicenseLOVloading, setIsLicenseLOVLoading] = useState(false);

  // State variable for managing Select options 

  // const [countryInfo, setCountryInfo] = useState([]);
  const [optionsCountry, setOptionsCountry] = useState([]);
  const [countryName, setCountryName] = useState('');
  // const [leaguesInfo, setLeaguesInfo] = useState([]);
  const [optionsLeague, setOptionsLeague] = useState([]);
  // const [teamsInfo, setTeamsInfo] = useState([]);
  const [optionsTeam, setOptionsTeam] = useState([]);
  const [optionsLicense, setOptionsLicense] = useState([]);

  // State variable to manage form validation

  const [validatedGeneral, setValidatedGeneral] = useState(false);
  const [validatedLicense, setValidatedLicense] = useState(false);
  const [validatedCareerHistory, setValidatedCareerHistory] = useState(false);
  const [validatedVideos, setValidatedVideos] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  // State variable to manage user data

  const [userPersonalData, setUserPersonalData] = useState('')

  // State variable to manage sport profile type

  const [selectedPlayerSwitchOption, setSelectedPlayerSwitchOption] = useState(false);
  const [selectedCoachSwitchOption, setSelectedCoachSwitchOption] = useState(false);
  const [selectedAgentSwitchOption, setSelectedAgentSwitchOption] = useState(false);

  // State variable for managing coach info

  const [coachInfo, setCoachInfo] = useState('')
  const [coachId, setCoachId] = useState(null)
  const [licenseName, setLicenseName] = useState('')
  const [licenseId, setLicenseId] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null);
  const [coachLicense, setCoachLicense] = useState([])
  const [coachLicenseId, setCoachLicenseId] = useState(null)
  const [coachHistory, setCoachHistory] = useState([])
  const [coachCareerHistoryId, setCoachCareerHistoryId] = useState(null)
  const [teamName, setTeamName] = useState('')
  const [teamId, setTeamId] = useState(null)
  // const [period, setPeriod] = useState('')
  const [fromYear, setFromYear] = useState('')
  const [toYear, setToYear] = useState('')
  const [summary, setSummary] = useState('')
  const [keyAchievements, setKeyAchievements] = useState('')
  const [leagueType, setLeagueType] = useState('')
  const [leagueName, setLeagueName] = useState('')
  const [leagueId, setLeagueId] = useState(null)
  const [currentRequestStatus, setCurrentRequestStatus] = useState('')
  const [remarks, setRemarks] = useState('')

  const [typeError, setTypeError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [teamError, setTeamError] = useState('');
  const [leagueError, setLeagueError] = useState('');
  const [fromYearError, setFromYearError] = useState('');
  const [toYearError, setToYearError] = useState('');
  const [licenseError, setLicenseError] = useState('');
  const [fileTypeError, setFileTypeError] = useState('');

  // State variable to manage video clip

  const [videoId, setVideoId] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [videoTitleError, setVideoTitleError] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [videoUrlError, setVideoUrlError] = useState('');
  const [videoClip, setVideoClip] = useState([]);

  // State variable for managing endorsement request

  const [profileTypeError, setProfileTypeError] = useState('');
  const [checkedEndorsement, setCheckedEndorsement] = useState(false);
  // const [personNameEndorsement, setPersonNameEndorsement] = useState([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", player_career_history: "" }])
  const [personNameEndorsement, setPersonNameEndorsement] = useState('')
  const [sportPersonData, setSportPersonData] = useState([])
  const [personNameError, setPersonNameError] = useState('')
  const [emailId, setEmailId] = useState('')
  const [comments, setComments] = useState('')
  const [coachEndorsementRequest, setCoachEndorsementRequest] = useState([])
  const [inputFieldDisabled, setInputFieldDisabled] = useState(false)
  const [isEmailFieldVisible, setIsEmailFieldVisible] = useState(false)
  const [isSpinnerLoadingVisible, setIsSpinnerLoadingVisible] = useState(false)

  // State varialabe to manage modal

  const [showAddGeneralModal, setShowAddGeneralModal] = useState(false)
  const [showEditGeneralModal, setShowEditGeneralModal] = useState(false)
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(false)
  const [showEditLicenseModal, setShowEditLicenselModal] = useState(false)
  const [showAddCareerHistoryModal, setShowAddCareerHistoryModal] = useState(false)
  const [showEditCareerHistoryModal, setShowEditCareerHistoryModal] = useState(false)
  const [showAddVideosModal, setShowAddVideosModal] = useState(false)
  const [showEditVideosModal, setShowEditVideosModal] = useState(false)

  // function responsible of search data with navigation

  const navigateToSearchDataWithParams = (item) => {
    console.log('navigateToSearchDataWithParams');
    navigate(`/user/viewprofile?user_id=${item.id}`);
  };

  const navigateToSearchAllWithParams = (item) => {
    console.log('navigateToSearchAllWithParams');
    console.log('Search Data:', item);
    navigate("/searchall", { state: { item } });
  };

  // function responsible for getting data from backend

  const getCoachLicense = async () => {
    // e.preventDefault();
    // console.log('get license');
    let response = fetch(`${SPORTLICENSE_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const status = (await response).status
    if (status === 200) {
      const licenseData = (await data);
      // console.log('licenseData: ', licenseData);
      // setCountryInfo(licenseData);
      const processedOptions = licenseData.map(item => ({
        label: item.license_name,
        value: item.id
      }));
      // console.log('processed Options:', processedOptions)
      setOptionsLicense(processedOptions);
    } else {
      notifyError()
    }
  }

  const getCountries = async () => {
    // e.preventDefault();
    // console.log('get countries');
    let response = fetch(`${COUNTRY_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const status = (await response).status
    if (status === 200) {
      const countryData = (await data);
      // console.log('countryData: ', countryData);
      // setCountryInfo(countryData);
      const processedOptions = countryData.map(item => ({
        label: item.country_name,
        value: item.country_name
      }));
      // console.log('processed Options:', processedOptions)
      setOptionsCountry(processedOptions);
    } else {
      notifyError()
    }
  }

  const getTeams = async () => {
    // e.preventDefault();
    // console.log('get leagues');
    let response = fetch(`${TEAMS_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const status = (await response).status
    if (status === 200) {
      const teamData = (await data);
      // console.log('teamData: ', teamData);
      // setTeamsInfo(teamData);
      const processedOptions = teamData.map(item => ({
        label: item.club_name,
        value: item.reg_id
      }));
      // console.log('processed Options:', processedOptions)
      setOptionsTeam(processedOptions);
    } else {
      notifyError()
    }
  }

  const getLeagues = async () => {
    // e.preventDefault();
    // console.log('get leagues');
    let response = fetch(`${LEAGUES_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const status = (await response).status
    if (status === 200) {
      const leagueData = (await data);
      // console.log('leagueData: ', leagueData);
      // setLeaguesInfo(leagueData);
      const processedOptions = leagueData.map(item => ({
        label: item.league_name,
        value: item.id
      }));
      // console.log('processed Options:', processedOptions)
      setOptionsLeague(processedOptions);
    } else {
      notifyError()
    }
  }

  const getInstitution = async () => {
    let response = fetch(`${GET_INSTITUITION_URL}${teamId}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response);
    // const data = (await response).json()
    // console.log('data:', data)
    const status = (await response).status;
    if (status === 200) {
      // const user = (await data)
      setIsEmailFieldVisible(false);
      // console.log('user found');
    } else if (status === 404) {
      setIsEmailFieldVisible(true);
    } else {
      notifyError();
    }
  }

  // const getCoachInfo = async (id) => {
  //   setCoachId(id)
  //   let response = fetch(`${COACH_URL}/${id}/`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       // 'Authorization': 'Bearer ' + String(authTokens.access)
  //     },
  //   })
  //   console.log('response:', response)
  //   const data = (await response).json()
  //   console.log('data:', data)
  //   const coach = (await data)
  //   console.log('coach info:', coach)
  //   const status = (await response).status
  //   if (status === 200) {
  //     // setLicenseId(coach.license_id)
  //     // setLicenseName(coach.license_name)
  //     // setSelectedFile(coach.certificate)
  //   } else {
  //     notifyError()
  //   }
  // }

  const getCoachLicenseInfo = async (id) => {
    setCoachLicenseId(id)
    let response = fetch(`${COACHLICENSE_URL}/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const coach = (await data)
    // console.log('coach LICENSE info:', coach)
    const status = (await response).status
    if (status === 200) {
      setLicenseId(coach.license_id)
      setLicenseName(coach.license_name)
      // setSelectedFile(coach.certificate)
    } else {
      notifyError()
    }
  }

  const getCoachCareerHistoryInfo = async (id) => {
    setCoachCareerHistoryId(id)
    let response = fetch(`${COACHCAREERHISTORY_URL}/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const history = (await data)
    console.log('Career History:', history)
    const status = (await response).status
    if (status === 200) {
      setLeagueType(history.league_type)
      setCountryName(history.country_name)
      setLeagueName(history.league_name)
      setLeagueId(history.league_id)
      setTeamName(history.club_name)
      setTeamId(history.club_id)
      setFromYear(history.from_year)
      setToYear(history.to_year)
      // setCurrentRequestStatus(history.status)
      // setRemarks(history.remarks)
      setKeyAchievements(history.achievements)
      setSummary(history.summary)
      if (history.endorsement_request.length > 0) {
        setInputFieldDisabled(true)
        setCheckedEndorsement(true)
        setCoachEndorsementRequest(history.endorsement_request)
      } else {
        setInputFieldDisabled(false)
        setCheckedEndorsement(false)
        setCoachEndorsementRequest([])
      }
      getCountries();
      getTeams();
      getLeagues();
    } else {
      notifyError();
    }
  }

  const getVideoClipInfo = async (id) => {
    setVideoId(id)
    let response = fetch(`${VIDEOCLIP_URL}/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const video = (await data)
    // console.log('video clip:', video)
    const status = (await response).status
    if (status === 200) {
      setVideoTitle(video.title)
      setVideoUrl(video.clip_url)
    } else {
      notifyError()
    }
  }

  // functions responsible for adding data in backend

  const handleAddCoachLicense = async (e) => {
    e.preventDefault();
    setValidatedLicense(true);
    // setIsLoading(true);
    const formData = new FormData();
    formData.append("coach", coachInfo.id);
    if (licenseName !== '') {
      formData.append("license_name", licenseName);
      setLicenseError('');
    } else {
      setLicenseError('Licence Name cannot be empty.');
      return;
    }
    if (licenseId !== null) {
      formData.append("license_id", licenseId);
    } else {
      formData.append("license_id", '');
    }
    if (selectedFile !== null) {
      if (selectedFile.type === 'application/pdf') {
        console.log('selectedFile', selectedFile.type);
        formData.append("document_file", selectedFile);
        formData.append("document_type", selectedFile.type);
        setFileTypeError('');
      } else {
        console.log('selectedFile', selectedFile.type);
        setFileTypeError('Please select a valid PDF file.');
        return;
      }
      // formData.append("certificate", selectedFile); 
    }
    let response = fetch(`${FOOTBALLCOACHLICENSECREATE_URL}`, {
      method: "POST",
      body: formData,
    });
    // console.log('response:', response)
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 201) {
      notifyRegistrationPersonalInfo()
      setLicenseId(null)
      setLicenseName('')
      setSelectedFile(null)
      getCoachLicense()
      setValidatedLicense(false)
      setShowAddLicenseModal(false)
    } else {
      notifyError()
    }
  };

  // const handleAddCoachCareerHistory = async (e) => {
  //   console.log('handleAddCoachCareerHistory function called');
  //   setValidatedCareerHistory(true);
  //   const formData = new FormData();
  //   formData.append("sport_type", userPersonalData.sport_type);
  //   if (coachInfo) {
  //     formData.append("coach_id", coachInfo.id);
  //   }
  //   if (leagueType !== '') {
  //     formData.append("league_type", leagueType);
  //     setTypeError('');
  //   } else {
  //     setTypeError('Type cannot be left empty.');
  //     return;
  //   }
  //   if (leagueType === 'Domestic') {
  //     if (countryName === '') {
  //       setCountryError('Country cannot be left empty.');
  //       return;
  //     }
  //     else {
  //       setCountryError('');
  //       formData.append("country_name", countryName);
  //     }
  //   } else {
  //     setCountryError('');
  //     formData.append("country_name", '');
  //   }
  //   if (leagueName !== '') {
  //     formData.append("league_name", leagueName);
  //     formData.append("league_id", leagueId);
  //   }
  //   if (teamName !== '') {
  //     formData.append("club_name", teamName);
  //     setTeamError('');
  //   } else {
  //     setTeamError('Team cannot be left empty.');
  //     return;
  //   }
  //   if (teamId !== null) {
  //     formData.append("club_id", teamId);
  //   } else {
  //     formData.append("club_id", 0);
  //   }
  //   if (fromYear !== '') {
  //     formData.append("from_year", fromYear);
  //     setFromYearError('');
  //   } else {
  //     setFromYearError('From year cannot be left empty.');
  //     return;
  //   }
  //   if (toYear !== '') {
  //     formData.append("to_year", fromYear);
  //     setToYearError('');
  //   } else {
  //     setToYearError('To year cannot be left empty.');
  //     return;
  //   }
  //   formData.append("achievements", keyAchievements);
  //   formData.append("summary", summary);
  //   formData.append("status", 'Draft');
  //   formData.append("remarks", remarks);
  //   if (teamId === null && teamName !== '' && leagueId === null && leagueName !== '') {
  //     formData.append("flag", 'teamleague');
  //     formData.append("reg_id", uuidv4());
  //     let response = fetch(`${FOOTBALLCOACHCAREERHISTORYCREATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 201) {
  //       notifyRegistrationPersonalInfo()
  //       setTeamName('')
  //       setTeamId(null)
  //       setFromYear('')
  //       setToYear('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       setCoachCareerHistoryId(null)
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowAddCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName !== '') {
  //     formData.append("flag", 'league');
  //     let response = fetch(`${FOOTBALLCOACHCAREERHISTORYCREATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 201) {
  //       notifyRegistrationPersonalInfo()
  //       setTeamName('')
  //       setTeamId(null)
  //       setFromYear('')
  //       setToYear('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       setCoachCareerHistoryId(null)
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowAddCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else if (teamId === null && teamName !== '' && leagueId !== null && leagueName !== '') {
  //     formData.append("flag", 'team');
  //     formData.append("reg_id", uuidv4());
  //     // formData.append("country_name", userPersonalData.citizenship);
  //     let response = fetch(`${FOOTBALLCOACHCAREERHISTORYCREATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 201) {
  //       notifyRegistrationPersonalInfo()
  //       setTeamName('')
  //       setTeamId(null)
  //       setFromYear('')
  //       setToYear('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       setCoachCareerHistoryId(null)
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowAddCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else {
  //     let response = fetch(`${FOOTBALLCOACHCAREERHISTORYLEAGUECREATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 201) {
  //       notifyRegistrationPersonalInfo()
  //       setTeamName('')
  //       setTeamId(null)
  //       setFromYear('')
  //       setToYear('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       setCoachCareerHistoryId(null)
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowAddCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   }
  // };

  const handleAddCoachCareerHistory = async (e) => {
    // console.log('handleAddCoachCareerHistory function called');
    setValidatedCareerHistory(true);
    // const formData = new FormData();
    // formData.append("sport_type", userPersonalData.sport_type);
    // if (coachInfo) {
    //   formData.append("coach_id", coachInfo.id);
    // }
    if (fromYear !== '') {
      // formData.append("from_year", fromYear);
      setFromYearError('');
    } else {
      setFromYearError('From year cannot be left empty.');
      return;
    }
    if (toYear !== '') {
      // formData.append("to_year", fromYear);
      setToYearError('');
    } else {
      setToYearError('To year cannot be left empty.');
      return;
    }
    if (leagueType !== '') {
      // formData.append("league_type", leagueType);
      setTypeError('');
    } else {
      setTypeError('Type cannot be left empty.');
      return;
    }
    if (leagueType === 'Domestic') {
      if (countryName === '') {
        setCountryError('Country cannot be left empty.');
        return;
      }
      else {
        setCountryError('');
        // formData.append("country_name", countryName);
      }
    } else {
      setCountryError('');
      // formData.append("country_name", '');
    }
    // if (leagueName !== '') {
    //   setLeagueError('');
    // } else {
    //   setLeagueError('League cannot be empty.');
    //   return;
    // }
    if (teamName !== '') {
      // formData.append("club_name", teamName);
      setTeamError('');
    } else {
      setTeamError('Team cannot be empty.');
      return;
    }
    // if (teamId !== null) {
    //   formData.append("club_id", teamId);
    // } else {
    //   formData.append("club_id", 0);
    // }
    // formData.append("achievements", keyAchievements);
    // formData.append("summary", summary);
    // formData.append("status", 'Draft');
    // formData.append("remarks", remarks);
    setIsSpinnerLoadingVisible(true);
    if (teamId === null && teamName !== '' && leagueId === null && leagueName !== '') {
      // formData.append("flag", 'teamleague');
      // formData.append("reg_id", uuidv4());

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'teamleague',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: leagueType === 'Domestic' ? countryName : '',
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            coach_id: coachInfo.id
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            coach_career_history: ''
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'teamleague',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: leagueType === 'Domestic' ? countryName : '',
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            coach_id: coachInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${FOOTBALLCOACHCAREERHISTORYCREATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setFromYear('')
          setToYear('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setCurrentRequestStatus('')
          setRemarks('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          setCoachCareerHistoryId(null)
          getLeagues()
          getTeams()
          setEmailId('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage('There was an error posting the data!');
        });
    } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName !== '') {
      // formData.append("flag", 'league');

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'league',
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: leagueType === 'Domestic' ? countryName : '',
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            coach_id: coachInfo.id
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            coach_career_history: '',
            reg_id: teamId
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'league',
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: leagueType === 'Domestic' ? countryName : '',
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            coach_id: coachInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${FOOTBALLCOACHCAREERHISTORYCREATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setFromYear('')
          setToYear('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setCurrentRequestStatus('')
          setRemarks('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          setCoachCareerHistoryId(null)
          getLeagues()
          getTeams()
          setEmailId('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage('There was an error posting the data!');
        });
    }else if (teamId !== null && teamName !== '' && leagueId === null && leagueName === '') {
      // formData.append("flag", 'league');

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'leaguenull',
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            country_name: leagueType === 'Domestic' ? countryName : '',
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            coach_id: coachInfo.id
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            coach_career_history: '',
            reg_id: teamId
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'leaguenull',
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            country_name: leagueType === 'Domestic' ? countryName : '',
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            coach_id: coachInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${FOOTBALLCOACHCAREERHISTORYCREATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setFromYear('')
          setToYear('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setCurrentRequestStatus('')
          setRemarks('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          setCoachCareerHistoryId(null)
          getLeagues()
          getTeams()
          setEmailId('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage('There was an error posting the data!');
        });
    } else if (teamId === null && teamName !== '' && leagueId !== null && leagueName !== '') {
      // formData.append("flag", 'team');
      // formData.append("reg_id", uuidv4());
      // formData.append("country_name", userPersonalData.citizenship);
      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'team',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: leagueType === 'Domestic' ? countryName : '',
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            coach_id: coachInfo.id
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            coach_career_history: ''
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'team',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: leagueType === 'Domestic' ? countryName : '',
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            coach_id: coachInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${FOOTBALLCOACHCAREERHISTORYCREATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setFromYear('')
          setToYear('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setCurrentRequestStatus('')
          setRemarks('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          setCoachCareerHistoryId(null)
          getLeagues()
          getTeams()
          setEmailId('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage('There was an error posting the data!');
        });
    }else if (teamId === null && teamName !== '' && leagueId === null && leagueName === '') {
      // formData.append("flag", 'team');
      // formData.append("reg_id", uuidv4());
      // formData.append("country_name", userPersonalData.citizenship);
      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'teamleaguenull',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            country_name: leagueType === 'Domestic' ? countryName : '',
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            coach_id: coachInfo.id
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            coach_career_history: ''
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'teamleaguenull',
            reg_id: uuidv4(),
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            country_name: leagueType === 'Domestic' ? countryName : '',
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            coach_id: coachInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${FOOTBALLCOACHCAREERHISTORYCREATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setFromYear('')
          setToYear('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setCurrentRequestStatus('')
          setRemarks('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          setCoachCareerHistoryId(null)
          getLeagues()
          getTeams()
          setEmailId('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage('There was an error posting the data!');
        });
    } else {

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: leagueType === 'Domestic' ? countryName : '',
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            coach_id: coachInfo.id
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            coach_career_history: '',
            reg_id: teamId
          }
        };
      } else {
        data = {
          career_history: {
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: leagueType === 'Domestic' ? countryName : '',
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type,
            coach_id: coachInfo.id
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${FOOTBALLCOACHCAREERHISTORYLEAGUECREATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setFromYear('')
          setToYear('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setCurrentRequestStatus('')
          setRemarks('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          setCoachCareerHistoryId(null)
          getLeagues()
          getTeams()
          setEmailId('')
          setIsEmailFieldVisible(false)
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setShowAddCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage('There was an error posting the data!');
        });
    }
  };

  const handleSubmitVideoClip = async (event) => {
    event.preventDefault();
    setValidatedVideos(true)
    const formData = new FormData();
    if (videoTitle === '') {
      setVideoTitleError('Video title cannot be empty')
      return;
    } else {
      setVideoTitleError('')
      formData.append("title", videoTitle);
    }
    if (videoUrl === '') {
      setVideoUrlError('Video url cannot be empty')
      return;
    } else {
      setVideoUrlError('')
      formData.append("clip_url", videoUrl);
    }
    formData.append("profile_type", 'coach');
    formData.append("user_id", user.user_id);
    let response = fetch(`${VIDEOCLIP_URL}/`, {
      method: "POST",
      body: formData,
    });
    // console.log('response:', response)
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 201) {
      notifyRegistrationPersonalInfo()
      setVideoTitle('')
      setVideoTitleError('')
      setVideoUrl('')
      setVideoUrlError('')
      // setIsLoading(true)
      setValidatedVideos(false)
      setShowAddVideosModal(false)
    } else {
      notifyError()
    }
  }

  // fuctions responsible for deleting data from backend

  const handleCoachLicenseDelete = async (id) => {
    // console.log('Handle Profile Delete function called');
    let response = fetch(`${COACHLICENSE_URL}/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    // console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyCustomSuccessMessage('Information deleted successfully.');
      const newClubList = coachLicense.filter((club) => club.id !== id);
      setCoachLicense(newClubList)
    } else {
      notifyError()
    }
  }

  const handleCoachCareerHistoryDelete = async (id) => {
    // console.log('Handle Profile Delete function called');
    let response = fetch(`${COACHCAREERHISTORY_URL}/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    // console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyRegistrationPersonalInfo();
      const newClubList = coachHistory.filter((club) => club.id !== id);
      setCoachHistory(newClubList)
    } else {
      notifyError()
    }
  }

  const handleCoachEndorsementRequestDelete = async (id) => {
    // console.log('Handle Profile Delete function called');
    let response = fetch(`${FOOTBALLCOACHENDORSEMENTREQUEST_URL}${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    // console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyCustomSuccessMessage('Information deleted successfully.');
      const newList = coachEndorsementRequest.filter((agent) => agent.id !== id);
      setCoachEndorsementRequest(newList)
    } else {
      notifyError()
    }
  }

  const handleVideoClipDelete = async (id) => {
    // console.log('Handle Video Delete function called');
    let response = fetch(`${VIDEOCLIP_URL}/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    // console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyCustomSuccessMessage('Successfully deleted the data!')
      const newVideoClip = videoClip.filter((clip) => clip.id !== id);
      setVideoClip(newVideoClip)
      // setIsLoading(true)
    } else {
      notifyError()
    }
  }

  // function responsible for updating data in backend

  const handleUpdateCoachLicense = async (e) => {
    e.preventDefault();
    setValidatedLicense(true);
    // setIsLoading(true);
    const formData = new FormData();
    formData.append("id", coachLicenseId);
    formData.append("coach", coachInfo.id);
    if (licenseName !== '') {
      formData.append("license_name", licenseName);
      setLicenseError('');
    } else {
      setLicenseError('Licence Name cannot be empty.');
      return;
    }
    if (licenseId !== null) {
      formData.append("license_id", licenseId);
    } else {
      formData.append("license_id", '');
    }
    if (selectedFile !== null) {
      if (selectedFile.type === 'application/pdf') {
        console.log('selectedFile', selectedFile.type);
        formData.append("document_file", selectedFile);
        formData.append("document_type", selectedFile.type);
        setFileTypeError('');
      } else {
        console.log('selectedFile', selectedFile.type);
        setFileTypeError('Please select a valid PDF file.');
        return;
      }
      // formData.append("certificate", selectedFile);
    }
    let response = fetch(`${FOOTBALLCOACHLICENSEUPDATE_URL}`, {
      method: "POST",
      body: formData,
    });
    // console.log('response:', response)
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 200) {
      notifyRegistrationPersonalInfo()
      setLicenseId(null)
      setLicenseName('')
      setSelectedFile(null)
      getCoachLicense()
      setValidatedLicense(false)
      setShowEditLicenselModal(false)
    } else {
      notifyError()
    }
  };

  // const handleUpdateCoachHistory = async (e) => {
  //   setValidatedCareerHistory(true);
  //   const formData = new FormData();
  //   formData.append("sport_type", userPersonalData.sport_type);
  //   formData.append("id", coachCareerHistoryId);
  //   formData.append("coach_id", coachInfo.id);
  //   if (leagueType !== '') {
  //     formData.append("league_type", leagueType);
  //     setTypeError('');
  //   } else {
  //     setTypeError('Type cannot be left empty.');
  //   }
  //   if (leagueType === 'Domestic') {
  //     if (countryName === '') {
  //       setCountryError('Country cannot be left empty.');
  //       return;
  //     }
  //     else {
  //       setCountryError('');
  //       formData.append("country_name", countryName);
  //     }
  //   } else {
  //     setCountryError('');
  //     formData.append("country_name", '');
  //   }
  //   formData.append("league_name", leagueName);
  //   if (leagueId !== null) {
  //     formData.append("league_id", leagueId);
  //   } else {
  //     formData.append("league_id", '');
  //   }
  //   if (teamName !== '') {
  //     // formData.append("club_id", teamId);
  //     formData.append("club_name", teamName);
  //     setTeamError('');
  //   } else {
  //     setTeamError('Team cannot be left empty.');
  //     return;
  //   }
  //   if (teamId !== null) {
  //     formData.append("club_id", teamId);
  //   } else {
  //     formData.append("club_id", 0);
  //   }
  //   if (fromYear !== '') {
  //     formData.append("from_year", fromYear);
  //     setFromYearError('');
  //   } else {
  //     setFromYearError('From Year cannot be left empty.');
  //     return;
  //   }
  //   if (toYear !== '') {
  //     formData.append("to_year", toYear);
  //     setToYearError('');
  //   } else {
  //     setToYearError('To Year cannot be left empty.');
  //     return;
  //   }
  //   formData.append("achievements", keyAchievements);
  //   formData.append("summary", summary);
  //   formData.append("status", currentRequestStatus);
  //   formData.append("remarks", remarks);
  //   if (teamId === null && teamName !== '' && leagueId === null && leagueName !== '') {
  //     formData.append("flag", 'teamleague');
  //     formData.append("reg_id", uuidv4());
  //     let response = fetch(`${FOOTBALLCOACHCAREERHISTORYTEAMLEAGUEUPDATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       notifyRegistrationPersonalInfo()
  //       setCoachCareerHistoryId(null)
  //       setTeamName('')
  //       setTeamId(null)
  //       setFromYear('')
  //       setToYear('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowEditCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName !== '') {
  //     formData.append("flag", 'league');
  //     let response = fetch(`${FOOTBALLCOACHCAREERHISTORYTEAMLEAGUEUPDATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       notifyRegistrationPersonalInfo()
  //       setCoachCareerHistoryId(null)
  //       setTeamName('')
  //       setTeamId(null)
  //       setFromYear('')
  //       setToYear('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowEditCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else if (teamId === null && teamName !== '' && leagueId !== null && leagueName !== '') {
  //     formData.append("flag", 'team');
  //     formData.append("reg_id", uuidv4());
  //     let response = fetch(`${FOOTBALLCOACHCAREERHISTORYTEAMLEAGUEUPDATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       notifyRegistrationPersonalInfo()
  //       setCoachCareerHistoryId(null)
  //       setTeamName('')
  //       setTeamId(null)
  //       setFromYear('')
  //       setToYear('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowEditCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   } else {
  //     let response = fetch(`${FOOTBALLCOACHCAREERHISTORYUPDATE_URL}`, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         // 'Content-Type': 'multipart/form-data',
  //         // 'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: formData,
  //     });
  //     console.log('response:', response)
  //     let data = (await response).json()
  //     console.log('data:', data)
  //     let status = (await response).status
  //     console.log('status:', status)
  //     if (status === 200) {
  //       notifyRegistrationPersonalInfo()
  //       setCoachCareerHistoryId(null)
  //       setTeamName('')
  //       setTeamId(null)
  //       setFromYear('')
  //       setToYear('')
  //       setLeagueName('')
  //       setLeagueId(null)
  //       setLeagueType('')
  //       setCurrentRequestStatus('')
  //       setRemarks('')
  //       setCountryName('')
  //       setKeyAchievements('')
  //       // setIsLoading(true)
  //       getLeagues()
  //       getTeams()
  //       setValidatedCareerHistory(false)
  //       setShowEditCareerHistoryModal(false)
  //     } else {
  //       notifyError()
  //     }
  //   }
  // };

  const handleUpdateCoachHistory = async (e) => {
    // e.preventDefault();
    // setIsLoading(true);
    setValidatedCareerHistory(true);
    // const formData = new FormData();
    // formData.append("sport_type", userPersonalData.sport_type);
    // formData.append("id", playerClubId);
    if (fromYear !== '') {
      // formData.append("from_year", fromYear);
      setFromYearError('');
    } else {
      setFromYearError('From year cannot be empty.');
      return;
    }
    if (toYear !== '') {
      // formData.append("to_year", toYear);
      setToYearError('');
    } else {
      setToYearError('To year cannot be empty.');
      return;
    }
    if (leagueType !== '') {
      // formData.append("league_type", leagueType);
      setTypeError('');
    } else {
      setTypeError('Type cannot be empty.');
    }
    if (leagueType === 'Domestic') {
      if (countryName === '') {
        setCountryError('Country cannot be empty.');
        return;
      }
      else {
        setCountryError('');
        // formData.append("country_name", countryName);
      }
    } else {
      setCountryError('');
      // formData.append("country_name", '');
    }
    if (teamName !== '') {
      // formData.append("games_played", gamesPlayed);
      setTeamError('');
    } else {
      setTeamError('Team name cannot be empty.');
      return;
    }
    setIsSpinnerLoadingVisible(true);
    if (teamId === null && teamName !== '' && leagueId === null && leagueName !== '' && leagueName !== null) {

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'teamleague',
            reg_id: uuidv4(),
            id: coachCareerHistoryId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            coach_career_history: coachCareerHistoryId
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'teamleague',
            reg_id: uuidv4(),
            id: coachCareerHistoryId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: ''
        };
      }

      console.log('data of teamleague: ', data);

      axios.post(`${FOOTBALLCOACHCAREERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setFromYear('')
          setToYear('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          setEmailId('')
          setPersonNameEndorsement('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage('There was an error posting the data!');
          setIsSpinnerLoadingVisible(false)
        });

    } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName !== '' && leagueName !== null) {

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'league',
            id: coachCareerHistoryId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            coach_career_history: coachCareerHistoryId,
            reg_id: teamId
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'league',
            id: coachCareerHistoryId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: ''
        };
      }

      console.log('data of league: ', data);

      axios.post(`${FOOTBALLCOACHCAREERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setFromYear('')
          setToYear('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          setEmailId('')
          setPersonNameEndorsement('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage('There was an error posting the data!');
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId !== null && teamName !== '' && leagueId === null && leagueName === '' && leagueName === null) {

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'leaguenull',
            id: coachCareerHistoryId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            coach_career_history: coachCareerHistoryId,
            reg_id: teamId
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'leaguenull',
            id: coachCareerHistoryId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: ''
        };
      }

      console.log('data of leaguenull: ', data);

      axios.post(`${FOOTBALLCOACHCAREERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setFromYear('')
          setToYear('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          setEmailId('')
          setPersonNameEndorsement('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage('There was an error posting the data!');
          setIsSpinnerLoadingVisible(false);
        });

    } else if (teamId === null && teamName !== '' && leagueId !== null && leagueName !== '' && leagueName !== null) {
      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'team',
            reg_id: uuidv4(),
            id: coachCareerHistoryId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            coach_career_history: coachCareerHistoryId
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'team',
            reg_id: uuidv4(),
            id: coachCareerHistoryId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: ''
        };
      }

      console.log('data of team: ', data);

      axios.post(`${FOOTBALLCOACHCAREERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setFromYear('')
          setToYear('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          setEmailId('')
          setPersonNameEndorsement('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage('There was an error posting the data!');
          setIsSpinnerLoadingVisible(false);
        });

    }else if (teamId === null && teamName !== '' && leagueId === null && leagueName === '' && leagueName === null) {
      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            flag: 'teamleaguenull',
            reg_id: uuidv4(),
            id: coachCareerHistoryId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            coach_career_history: coachCareerHistoryId
          }
        };
      } else {
        data = {
          career_history: {
            flag: 'teamleaguenull',
            reg_id: uuidv4(),
            id: coachCareerHistoryId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: ''
        };
      }

      console.log('data of teamleaguenull: ', data);

      axios.post(`${FOOTBALLCOACHCAREERHISTORYTEAMLEAGUEUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setFromYear('')
          setToYear('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setCountryName('')
          setKeyAchievements('')
          setComments('')
          getLeagues()
          getTeams()
          setEmailId('')
          setPersonNameEndorsement('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage('There was an error posting the data!');
          setIsSpinnerLoadingVisible(false);
        });

    }  else {

      let data;
      if (checkedEndorsement) {
        data = {
          career_history: {
            id: coachCareerHistoryId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: {
            to_endorser_email: emailId,
            to_endorser: '',
            from_endorsee: user.user_id,
            type: 'club',
            status: 'pending',
            comments: comments,
            remarks: '',
            coach_career_history: coachCareerHistoryId,
            reg_id: teamId
          }
        };
      } else {
        data = {
          career_history: {
            id: coachCareerHistoryId,
            club_id: teamId,
            club_name: teamName,
            from_year: fromYear,
            to_year: toYear,
            league_id: leagueId,
            league_name: leagueName,
            country_name: countryName,
            league_type: leagueType,
            achievements: keyAchievements,
            summary: summary,
            sport_type: userPersonalData.sport_type
          },
          endorsement_request: ''
        };
      }

      console.log('data: ', data);

      axios.post(`${FOOTBALLCOACHCAREERHISTORYUPDATE_URL}`, data)
        .then(response => {
          console.log('Data posted successfully:', response.data);
          notifyRegistrationPersonalInfo()
          setTeamName('')
          setTeamId(null)
          setFromYear('')
          setToYear('')
          setLeagueName('')
          setLeagueId(null)
          setLeagueType('')
          setCountryName('')
          setKeyAchievements('')
          getLeagues()
          getTeams()
          setEmailId('')
          setPersonNameEndorsement('')
          setValidatedCareerHistory(false)
          setIsSpinnerLoadingVisible(false)
          setIsEmailFieldVisible(false)
          setShowEditCareerHistoryModal(false)
        })
        .catch(error => {
          console.error('There was an error posting the data!', error);
          notifyCustomErrorMessage('There was an error posting the data!');
          setIsSpinnerLoadingVisible(false);
        });
    }
  };

  const handleUpdateVideoClip = async (event) => {
    event.preventDefault();
    setValidatedVideos(true)
    const formData = new FormData();
    if (videoTitle === '') {
      setVideoTitleError('Video title cannot be empty')
      return;
    } else {
      setVideoTitleError('')
      formData.append("title", videoTitle);
    }
    if (videoUrl === '') {
      setVideoUrlError('Video url cannot be empty')
      return;
    } else {
      setVideoUrlError('')
      formData.append("clip_url", videoUrl);
    }
    let response = fetch(`${VIDEOCLIP_URL}/${videoId}/`, {
      method: "PATCH",
      body: formData,
    });
    // console.log('response:', response)
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 200) {
      notifyCustomSuccessMessage('Successfully updated the data!')
      setVideoTitle('')
      setVideoUrl('')
      setVideoTitleError('')
      setVideoUrlError('')
      // setIsLoading(true)
      setValidatedVideos(false)
      setShowEditVideosModal(false)
    } else {
      notifyError()
    }
  }

  // function responsible for getting user personal information

  const userPersonalInfo = async () => {
    // e.preventDefault();
    // console.log('user Personal Information');
    let response = fetch(`${USER_URL}/${user.user_id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    const data = (await response).json()
    // console.log('data:', data)
    // console.log('response:', response)
    const status = (await response).status
    if (status === 200) {
      const personalInfo = (await data)
      // console.log('personalInfo: ', personalInfo)
      setUserPersonalData(personalInfo)
      const videoDetails = (await personalInfo.video_clip)
      setVideoClip(videoDetails)
      const sportProfileType = (await personalInfo.sport_profile_type)
      sportProfileType.map(item => {
        if (item.profile_type === 'Player') {
          if (item.is_active === true)
            setSelectedPlayerSwitchOption(true)
          else
            setSelectedPlayerSwitchOption(false)
        }
        if (item.profile_type === 'Coach') {
          if (item.is_active === true)
            setSelectedCoachSwitchOption(true)
          else
            setSelectedCoachSwitchOption(false)
        }
        if (item.profile_type === 'Agent') {
          if (item.is_active === true)
            setSelectedAgentSwitchOption(true)
          else
            setSelectedAgentSwitchOption(false)
        }
      })
      const coach = (await personalInfo.coach[0])
      if (coach) {
        setCoachInfo(coach)
        const license = (await coach.my_license)
        setCoachLicense(license)
        const history = (await coach.carreer_history)
        // console.log('Profile, coach history: ', history)
        setCoachHistory(history)
        getCoachLicense()
      }
    } else if (status === 401) {
      notifyAuthenticateError()
    } else {
      notifyError()
    }
  }

  // Handle click event

  const handleClick = () => {
    setIsTeamLOVLoading(false);
    setIsLeagueLOVLoading(false);
  };

  // handle on Change functions

  const handleOnChangeRegionType = (e) => {
    if (e) {
      const searchType = e.target.value;
      if (searchType === 'Domestic') {
        getCountries();
        getTeams();
        getLeagues();
        setCountryName('');
        setTeamName('');
        setLeagueName('');
      } else {
        getTeams();
        setTeamName('');
        getLeagues();
        setLeagueName('');
      }
      setLeagueType(searchType);
    } else {
      setLeagueType('');
      setTeamName('');
      setLeagueName('');
    }
  }

  const handleSearchLicenseFilter = (value) => {
    const res = optionsLicense.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    // console.log('res:', res);
    setSearchLicenseLOVData(res);
    setIsLicenseLOVLoading(true);
    setLicenseName(value);
    setLicenseId(null);
    if (value === "") {
      setSearchLicenseLOVData([]);
      setIsLicenseLOVLoading(false);
      setLicenseName('');
      setLicenseId(null);
    }
  }

  const handleSelectLicense = (item) => {
    setLicenseName(item.label);
    setLicenseId(item.value);
    setIsLicenseLOVLoading(false);
  }

  const handleSearchCountryFilter = (value) => {
    const res = optionsCountry.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    // console.log('res:', res);
    setSearchCountryLOVData(res);
    setIsCountryLOVLoading(true);
    setCountryName(value);
    if (value === "") {
      setSearchCountryLOVData([]);
      setIsCountryLOVLoading(false);
      setCountryName('');
    }
  }

  const handleSelectCountry = (item) => {
    setCountryName(item.value);
    setIsCountryLOVLoading(false);
  }

  const handleSearchFilter = (value) => {
    const res = optionsTeam.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    // console.log('res:', res);
    setSearchTeamLOVData(res);
    setIsTeamLOVLoading(true);
    setTeamName(value);
    if (value === "") {
      setSearchTeamLOVData([]);
      setIsTeamLOVLoading(false);
      setTeamName('');
      setTeamId(null);
    }
  }

  const handleSelectTeam = (item) => {
    setTeamName(item.label);
    setTeamId(item.value);
    setIsTeamLOVLoading(false);
  }

  const handleSearchLeagueFilter = (value) => {
    const res = optionsLeague.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    // console.log('res:', res);
    setSearchLeagueLOVData(res);
    setIsLeagueLOVLoading(true);
    setLeagueName(value);
    if (value === "") {
      setSearchLeagueLOVData([]);
      setIsLeagueLOVLoading(false);
      setLeagueName('');
      setLeagueId(null);
    }
  }

  const handleSelectLeague = (item) => {
    setLeagueName(item.label);
    setLeagueId(item.value);
    setIsLeagueLOVLoading(false);
  }

  const handleCheckedEndorsement = () => {
    setCheckedEndorsement(!checkedEndorsement);
    if (teamId !== null)
      getInstitution();
    else
      setIsEmailFieldVisible(true)
  }


  // handle on Close Modal

  const handleAddGenralModalClose = () => {
    setShowAddGeneralModal(false);
  }

  const handleEditGenralModalClose = () => {
    setShowEditGeneralModal(false);
  }

  const handleAddLicenseModalClose = () => {
    setShowAddLicenseModal(false);
    setValidatedLicense(false);
    setLicenseName('');
    setLicenseId(null);
  }

  const handleEditLicenseModalClose = () => {
    setShowEditLicenselModal(false);
    setValidatedLicense(false);
    setLicenseName('');
    setLicenseId(null);
  }

  const handleAddCareerHistoryModalClose = () => {
    setShowAddCareerHistoryModal(false);
    setEmailId('')
    setTeamName('')
    setTeamId(null)
    setFromYear('')
    setToYear('')
    setLeagueName('')
    setLeagueId(null)
    setLeagueType('')
    setCurrentRequestStatus('')
    setRemarks('')
    setCountryName('')
    setKeyAchievements('')
    setComments('')
    setCheckedEndorsement(false)
    setCoachCareerHistoryId(null)
    setIsEmailFieldVisible(false)
    setValidatedCareerHistory(false)
    setIsSpinnerLoadingVisible(false)
  }

  const handleEditCareerHistoryModalClose = () => {
    setShowEditCareerHistoryModal(false);
    setEmailId('')
    setTeamName('')
    setTeamId(null)
    setFromYear('')
    setToYear('')
    setLeagueName('')
    setLeagueId(null)
    setLeagueType('')
    setCurrentRequestStatus('')
    setRemarks('')
    setCountryName('')
    setKeyAchievements('')
    setComments('')
    setCheckedEndorsement(false)
    setCoachCareerHistoryId(null)
    setIsEmailFieldVisible(false)
    setValidatedCareerHistory(false)
    setIsSpinnerLoadingVisible(false)
  }


  const handleAddVideosModalClose = () => {
    setShowAddVideosModal(false);
    setVideoTitle('')
    setVideoTitleError('')
    setVideoUrl('')
    setVideoUrlError('')
    setValidatedVideos(false)
  }

  const handleEditVideosModalClose = () => {
    setShowEditVideosModal(false);
    setVideoTitle('')
    setVideoTitleError('')
    setVideoUrl('')
    setVideoUrlError('')
    setValidatedVideos(false)
  }

  useEffect(() => {
    userPersonalInfo();

    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        setIsTeamLOVLoading(false);
        setIsLeagueLOVLoading(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showAddGeneralModal, showEditGeneralModal, showAddLicenseModal, showEditLicenseModal, showAddCareerHistoryModal, showEditCareerHistoryModal, showAddVideosModal, showEditVideosModal]);

  const coachLicenseList = coachLicense.map((license) => {
    const words = license.document_file.split("/");
    return (
      <tr key={license.id}>
        <td>
          <FontAwesomeIcon
            icon={faEdit}
            color='#2222e785'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              getCoachLicenseInfo(license.id);
              setShowEditLicenselModal(true);
            }}
          />
        </td>
        <td>{userPersonalData.first_name} {userPersonalData.last_name}</td>
        <td>{license.license_name}</td>
        {/* <td>
          {license.certificate !== null ?
            <img src={license.certificate} alt={license.license_name} style={{ maxWidth: '50px' }} />
            : null
          }
        </td> */}
        <td>
          <a href={license.document_file} download="downloadedFile.pdf">
            {words[5]}
          </a>
        </td>
        <td>
          <FontAwesomeIcon
            icon={faTrashCan}
            color='red'
            style={{ cursor: 'pointer' }}
            onClick={() => handleCoachLicenseDelete(license.id)}
          />
        </td>
      </tr>
    );
  })

  // const coachCareerHistoryList = coachHistory.map((club) => {
  //   let currentStatus;
  //   if (club.status === 'Submitted') {
  //     currentStatus = 'Waiting for Approval';
  //     return (
  //       <tr key={club.id}>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={faCheck}
  //             color='#0f0'
  //           />
  //         </td>
  //         <td>{club.club_name}</td>
  //         <td>{club.from_year}</td>
  //         <td>{club.to_year}</td>
  //         <td>{club.league_name}</td>
  //         <td>{currentStatus}</td>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={faTrashCan}
  //             color='#ff001b5c'
  //           />
  //         </td>
  //       </tr>
  //     );
  //   } else if (club.status === 'Draft') {
  //     currentStatus = 'Draft';
  //   } else if (club.status === 'Approved') {
  //     currentStatus = 'Approved';
  //     return (
  //       <tr key={club.id}>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={faCheckDouble}
  //             color='#0f0'
  //           />
  //         </td>
  //         <td>{club.club_name}</td>
  //         <td>{club.from_year}</td>
  //         <td>{club.to_year}</td>
  //         <td>{club.league_name}</td>
  //         <td>{currentStatus}</td>
  //         <td>
  //           <FontAwesomeIcon
  //             icon={faTrashCan}
  //             color='#ff001b5c'
  //           />
  //         </td>
  //       </tr>
  //     );
  //   } else if (club.status === 'Rejected') {
  //     currentStatus = 'Rejected'
  //   }
  //   return (
  //     <tr key={club.id}>
  //       <td>
  //         <FontAwesomeIcon
  //           icon={faEdit}
  //           color='#2222e785'
  //           style={{ cursor: 'pointer' }}
  //           onClick={() => {
  //             getCoachCareerHistoryInfo(club.id);
  //             setShowEditCareerHistoryModal(true);
  //           }}
  //         />
  //       </td>
  //       <td>{club.club_name}</td>
  //       <td>{club.from_year}</td>
  //       <td>{club.to_year}</td>
  //       <td>{club.league_name}</td>
  //       <td>{currentStatus}</td>
  //       <td>
  //         <FontAwesomeIcon
  //           icon={faTrashCan}
  //           color='red'
  //           style={{ cursor: 'pointer' }}
  //           onClick={() => handleCoachCareerHistoryDelete(club.id)}
  //         />
  //       </td>
  //     </tr>
  //   );
  // })

  const coachCareerHistoryList = coachHistory.map((club) => {
    return (
      <tr key={club.id}>
        <td>
          <FontAwesomeIcon
            icon={faEdit}
            color='#2222e785'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              getCoachCareerHistoryInfo(club.id);
              setShowEditCareerHistoryModal(true);
            }}
          />
        </td>
        <td>{club.club_name}</td>
        <td>{club.from_year}</td>
        <td>{club.to_year}</td>
        <td>{club.league_name}</td>
        <td>
          <FontAwesomeIcon
            icon={faTrashCan}
            color='red'
            style={{ cursor: 'pointer' }}
            onClick={() => handleCoachCareerHistoryDelete(club.id)}
          />
        </td>
      </tr>
    );
  })

  const personalAchievementList = coachHistory.map((club) => {
    const parsedData = club.achievements !== null && club.achievements !== '' ? club.achievements.split('\n') : null;
    if (club.achievements !== null && club.achievements !== '')
      return (
        <tr key={club.id}>
          {/* <td>{club.achievements}</td> */}
          <td>{club.from_year}</td>
          <td>{club.to_year}</td>
          <td>
            {parsedData && parsedData.length > 0 &&
              <ul>
                {parsedData.map((item, index) => (
                  <li key={index} className='card-text'>{item}</li>
                ))}
              </ul>
            }
          </td>
        </tr>
      );
  })

  const coachEndorsementRequestList = coachEndorsementRequest.map((item) => {
    return (
      <tr key={item.id}>
        <td>
          {item.to_endorser.first_name === '' && item.to_endorser.last_name === '' ?
            item.to_endorser.email : item.to_endorser.first_name + ' ' + item.to_endorser.last_name
          }
        </td>
        <td>{item.type}</td>
        <td>{item.status}</td>
        <td>{item.remarks}</td>
        <td>
          <FontAwesomeIcon
            icon={faTrashCan}
            color='red'
            style={{ cursor: 'pointer' }}
            onClick={() => handleCoachEndorsementRequestDelete(item.id)}
          />
        </td>
      </tr>
    );
  })

  const videoClipList = videoClip.map((clip) => {
    if (clip.profile_type === 'coach') {
      return (
        <tr key={clip.id}>
          <td>
            <FontAwesomeIcon
              icon={faEdit}
              color='#2222e785'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                getVideoClipInfo(clip.id);
                setShowEditVideosModal(true);
              }}
            />
          </td>
          <td>{clip.title}</td>
          <td>
            <a href={'https://' + clip.clip_url} target="_blank" rel="noopener noreferrer">
              {clip.clip_url}
            </a>
          </td>
          <td>
            <FontAwesomeIcon
              icon={faTrashCan}
              color='red'
              style={{ cursor: 'pointer' }}
              onClick={() => handleVideoClipDelete(clip.id)}
            />
          </td>
        </tr>
      );
    }
  })

  return (
    <>

      <Modal show={showAddLicenseModal} onHide={handleAddLicenseModalClose} size="xl" scrollable={false}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Add Licence</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validatedLicense}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>License
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Select the name of a license"
                  value={licenseName}
                  onChange={e => handleSearchLicenseFilter(e.target.value)}
                  required
                />
                {isLicenseLOVloading &&
                  <div className='lov-content mt-1'>
                    <ul className='lov-list' style={{ padding: 5 }}>
                      {
                        searchLicenseLOVData.length > 0 ?
                          searchLicenseLOVData.map((item) => (
                            <li className='lov-list-element' key={item.value} onClick={() => handleSelectLicense(item)}>
                              {item.label}
                            </li>
                          ))

                          // : <li className='lov-list-element'>No data found</li>
                          : setIsLicenseLOVLoading(false)
                      }
                    </ul>
                  </div>
                }
                <Form.Control.Feedback type="invalid">
                  {licenseError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Certificate
                </Form.Label>
                <Form.Control
                  type="file"
                  size='lg'
                  // value={selectedFile}
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
                {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddLicenseModalClose}>Close</Button>
            <Button variant="primary" onClick={handleAddCoachLicense}>Save Changes</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEditLicenseModal} onHide={handleEditLicenseModalClose} size="xl" scrollable={false}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Edit Licence</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validatedLicense}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>License
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Select the name of a license"
                  value={licenseName}
                  onChange={e => handleSearchLicenseFilter(e.target.value)}
                  required
                />
                {isLicenseLOVloading &&
                  <div className='lov-content mt-1'>
                    <ul className='lov-list' style={{ padding: 5 }}>
                      {
                        searchLicenseLOVData.length > 0 ?
                          searchLicenseLOVData.map((item) => (
                            <li className='lov-list-element' key={item.value} onClick={() => handleSelectLicense(item)}>
                              {item.label}
                            </li>
                          ))

                          // : <li className='lov-list-element'>No data found</li>
                          : setIsLicenseLOVLoading(false)
                      }
                    </ul>
                  </div>
                }
                <Form.Control.Feedback type="invalid">
                  {licenseError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Certificate
                </Form.Label>
                <Form.Control
                  type="file"
                  size='lg'
                  // value={selectedFile}
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
                {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditLicenseModalClose}>Close</Button>
            <Button variant="primary" onClick={handleUpdateCoachLicense}>Save Changes</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showAddCareerHistoryModal} onHide={handleAddCareerHistoryModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Add Career History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isSpinnerLoadingVisible ?
            <div className='text-center'>
              <Button variant="primary" size='lg' disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading. Please wait...
              </Button>
            </div>
            :
            <Form noValidate validated={validatedCareerHistory}>
              <Row className='mb-3'>
                <Form.Group as={Col} sm='4'>
                  <Form.Label className='custom-label'>From Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2012"
                    value={fromYear}
                    onChange={(e) => setFromYear(e.target.value)}
                    onClick={handleClick}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {fromYearError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm='4'>
                  <Form.Label className='custom-label'>To Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2022"
                    value={toYear}
                    onChange={(e) => setToYear(e.target.value)}
                    onClick={handleClick}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {toYearError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm='4'>
                  <Form.Label className='custom-label'>Type
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Select
                    className='input'
                    size='lg'
                    value={leagueType}
                    // onChange={(e) => setLeagueType(e.target.value)}
                    onChange={handleOnChangeRegionType}
                    // style={{ fontSize: 11, padding: 10 }}
                    onClick={handleClick}
                    required
                  >
                    <option value=''>Open this select menu</option>
                    <option value="International">International</option>
                    <option value="Domestic">Domestic</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {typeError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Summary</Form.Label>
                  <Form.Control
                    className='input'
                    as="textarea"
                    rows={5}
                    placeholder="Enter the summary regarding your career history"
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                {leagueType === 'Domestic' &&
                  <Form.Group as={Col} sm="4">
                    <Form.Label className='custom-label'>Country Name
                      <span className='text-danger'> * </span>
                    </Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      placeholder="Select the name of a country"
                      value={countryName}
                      onChange={e => handleSearchCountryFilter(e.target.value)}
                      onClick={handleClick}
                      required
                    />
                    {isCountryLOVloading &&
                      <div className='lov-content mt-1'>
                        <ul className='lov-list' style={{ padding: 5 }}>
                          {
                            searchCountryLOVData.length > 0 ?
                              searchCountryLOVData.map((item) => (
                                <li className='lov-list-element' key={item.id} onClick={() => handleSelectCountry(item)}>
                                  {item.label}
                                </li>
                              ))

                              : <li className='lov-list-element' onClick={() => { setCountryName(''); setIsCountryLOVLoading(false); }}>
                                No data found. Please select a valid country.
                              </li>
                          }
                        </ul>
                      </div>
                    }
                    <Form.Control.Feedback type="invalid">
                      {countryError}
                    </Form.Control.Feedback>
                  </Form.Group>
                }

                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Club Name
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Select the name of a club"
                    value={teamName}
                    onChange={e => handleSearchFilter(e.target.value)}
                    onClick={handleClick}
                    required
                  />
                  {isTeamLOVloading &&
                    <div className='lov-content mt-1'>
                      <ul className='lov-list' style={{ padding: 5 }}>
                        {
                          searchTeamLOVData.length > 0 ?
                            searchTeamLOVData.map((item) => (
                              <li className='lov-list-element' key={item.id} onClick={() => handleSelectTeam(item)}>
                                {item.label}
                              </li>
                            ))

                            // : <li className='lov-list-element'>No data found</li>
                            : setIsTeamLOVLoading(false)
                        }
                      </ul>
                    </div>
                  }
                  <Form.Control.Feedback type="invalid">
                    {teamError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>League Name</Form.Label>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Select the name of a league"
                    value={leagueName}
                    onChange={e => handleSearchLeagueFilter(e.target.value)}
                    onClick={handleClick}
                  />
                  {isLeagueLOVloading &&
                    <div className='lov-content mt-1'>
                      <ul className='lov-list' style={{ padding: 5 }}>
                        {
                          searchLeagueLOVData.length > 0 ?
                            searchLeagueLOVData.map((item) => (
                              <li className='lov-list-element' key={item.id} onClick={() => handleSelectLeague(item)}>
                                {item.label}
                              </li>
                            ))

                            // : <li className='lov-list-element'>No data found</li>
                            : setIsLeagueLOVLoading(false)
                        }
                      </ul>
                    </div>
                  }
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Key Achievement</Form.Label>
                  <Form.Control
                    className='input'
                    as="textarea"
                    rows={5}
                    placeholder="Enter the key achievements"
                    value={keyAchievements}
                    onChange={(e) => setKeyAchievements(e.target.value)}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>

              {/* <Row className='mb-3'>
                <Form.Group as={Col} column sm="4">
                  <Form.Label className='custom-label'>Do you want endorsement?</Form.Label>
                  <Form.Check
                    type="checkbox"
                    size='lg'
                    label="Yes"
                    checked={checkedEndorsement}
                    onChange={() => setCheckedEndorsement(!checkedEndorsement)}
                  />
                </Form.Group>

                {checkedEndorsement && teamId === null &&
                  <Form.Group as={Col} column sm="4">
                    <Form.Label className='custom-label'>Email</Form.Label>
                    <Form.Control
                      className='input'
                      type="email"
                      size='lg'
                      placeholder="Enter the email address"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </Form.Group>
                }
              </Row> */}

              <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Do you want Endorsement?</Form.Label>
                  <Form.Check
                    type="checkbox"
                    size='lg'
                    label="Yes"
                    checked={checkedEndorsement}
                    // onChange={() => setCheckedEndorsement(!checkedEndorsement)}
                    onChange={handleCheckedEndorsement}
                  // disabled={inputFieldDisabled}
                  />
                </Form.Group>

                {checkedEndorsement && isEmailFieldVisible &&
                  <Form.Group as={Col} sm="4">
                    <Form.Label className='custom-label'>Email
                      <span className='text-danger'> * </span>
                    </Form.Label>
                    <Form.Control
                      className='input'
                      type="email"
                      size='lg'
                      placeholder="Enter the email address"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Email cannot be empty
                    </Form.Control.Feedback>
                  </Form.Group>
                }

              </Row>

              {checkedEndorsement &&
                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>Comments
                      <span className='text-danger'> * </span>
                    </Form.Label>
                    <Form.Control
                      // className='input'
                      // type="text"
                      // size='lg'
                      placeholder="Enter the comments"
                      // name="text"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                      // onChange={(e) => onChangeHandleComments(e)}
                      as="textarea"
                      rows={5}
                    />
                  </Form.Group>
                </Row>
              }

            </Form>
          }
        </Modal.Body>
        <Modal.Footer>
          {!isSpinnerLoadingVisible &&
            <>
              <Button variant="secondary" onClick={handleAddCareerHistoryModalClose}>Close</Button>
              <Button variant="primary" onClick={handleAddCoachCareerHistory}>Save Changes</Button>
            </>
          }
        </Modal.Footer>
      </Modal>

      <Modal show={showEditCareerHistoryModal} onHide={handleEditCareerHistoryModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Edit Career History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isSpinnerLoadingVisible ?
            <div className='text-center'>
              <Button variant="primary" size='lg' disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading. Please wait...
              </Button>
            </div>
            :
            <Form noValidate validated={validatedCareerHistory}>
              <Row className='mb-3'>
                <Form.Group as={Col} sm='4'>
                  <Form.Label className='custom-label'>From Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2012"
                    value={fromYear}
                    onChange={(e) => setFromYear(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {fromYearError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm='4'>
                  <Form.Label className='custom-label'>To Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2022"
                    value={toYear}
                    onChange={(e) => setToYear(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {toYearError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm='4'>
                  <Form.Label className='custom-label'>Type
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Select
                    className='input'
                    size='lg'
                    value={leagueType}
                    // onChange={(e) => setLeagueType(e.target.value)}
                    onChange={handleOnChangeRegionType}
                    // style={{ fontSize: 11, padding: 10 }}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                    required
                  >
                    <option value=''>Open this select menu</option>
                    <option value="International">International</option>
                    <option value="Domestic">Domestic</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {typeError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Summary</Form.Label>
                  <Form.Control
                    className='input'
                    as="textarea"
                    rows={5}
                    placeholder="Enter the summary regarding your career history"
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                    onClick={handleClick}
                    disabled={inputFieldDisabled}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                {leagueType === 'Domestic' &&
                  <Form.Group as={Col} sm="4">
                    <Form.Label className='custom-label'>Country Name
                      <span className='text-danger'> * </span>
                    </Form.Label>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      placeholder="Select the name of a country"
                      value={countryName}
                      onChange={e => handleSearchCountryFilter(e.target.value)}
                      disabled={inputFieldDisabled}
                      onClick={handleClick}
                      required
                    />
                    {isCountryLOVloading &&
                      <div className='lov-content mt-1'>
                        <ul className='lov-list' style={{ padding: 5 }}>
                          {
                            searchCountryLOVData.length > 0 ?
                              searchCountryLOVData.map((item) => (
                                <li className='lov-list-element' key={item.id} onClick={() => handleSelectCountry(item)}>
                                  {item.label}
                                </li>
                              ))

                              : <li className='lov-list-element' onClick={() => { setCountryName(''); setIsCountryLOVLoading(false); }}>
                                No data found. Please select a valid country.
                              </li>
                          }
                        </ul>
                      </div>
                    }
                    <Form.Control.Feedback type="invalid">
                      {countryError}
                    </Form.Control.Feedback>
                  </Form.Group>
                }

                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Club Name
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Select the name of a club"
                    value={teamName}
                    onChange={e => handleSearchFilter(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                    required
                  />
                  {isTeamLOVloading &&
                    <div className='lov-content mt-1'>
                      <ul className='lov-list' style={{ padding: 5 }}>
                        {
                          searchTeamLOVData.length > 0 ?
                            searchTeamLOVData.map((item) => (
                              <li className='lov-list-element' key={item.id} onClick={() => handleSelectTeam(item)}>
                                {item.label}
                              </li>
                            ))

                            // : <li className='lov-list-element'>No data found</li>
                            : setIsTeamLOVLoading(false)
                        }
                      </ul>
                    </div>
                  }
                  <Form.Control.Feedback type="invalid">
                    {teamError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>League Name</Form.Label>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Select the name of a league"
                    value={leagueName}
                    onChange={e => handleSearchLeagueFilter(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                  {isLeagueLOVloading &&
                    <div className='lov-content mt-1'>
                      <ul className='lov-list' style={{ padding: 5 }}>
                        {
                          searchLeagueLOVData.length > 0 ?
                            searchLeagueLOVData.map((item) => (
                              <li className='lov-list-element' key={item.id} onClick={() => handleSelectLeague(item)}>
                                {item.label}
                              </li>
                            ))

                            // : <li className='lov-list-element'>No data found</li>
                            : setIsLeagueLOVLoading(false)
                        }
                      </ul>
                    </div>
                  }
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className='custom-label'>Key Achievement</Form.Label>
                  <Form.Control
                    className='input'
                    as="textarea"
                    rows={5}
                    placeholder="Enter the key achievements"
                    value={keyAchievements}
                    onChange={(e) => setKeyAchievements(e.target.value)}
                    disabled={inputFieldDisabled}
                    onClick={handleClick}
                  />
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Do you want Endorsement?</Form.Label>
                  <Form.Check
                    type="checkbox"
                    size='lg'
                    label="Yes"
                    checked={checkedEndorsement}
                    // onChange={() => setCheckedEndorsement(!checkedEndorsement)}
                    onChange={handleCheckedEndorsement}
                    disabled={inputFieldDisabled}
                  />
                </Form.Group>

                {checkedEndorsement && isEmailFieldVisible &&
                  <Form.Group as={Col} sm="4">
                    <Form.Label className='custom-label'>Email
                      <span className='text-danger'> * </span>
                    </Form.Label>
                    <Form.Control
                      className='input'
                      type="email"
                      size='lg'
                      placeholder="Enter the email address"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Email cannot be empty
                    </Form.Control.Feedback>
                  </Form.Group>
                }
              </Row>

              {checkedEndorsement &&
                <Card>
                  <Card.Header as="h4" style={{ backgroundColor: '#e3f2fd' }}>Endorsement</Card.Header>
                  <Card.Body>

                    <Table striped responsive bordered hover>
                      <thead>
                        <tr>
                          <th>To Endorser</th>
                          <th>Type</th>
                          <th>Status</th>
                          <th>Remarks</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {coachEndorsementRequest && coachEndorsementRequest.length > 0 ?
                          coachEndorsementRequestList :
                          <tr>
                            <th colSpan={5} className='text-center'>
                              No rows found
                            </th>
                          </tr>
                        }
                      </tbody>
                    </Table>


                  </Card.Body>
                </Card>
              }

              {checkedEndorsement && !inputFieldDisabled &&
                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label>Comments
                      <span style={{ color: 'red', fontSize: 16 }}> * </span>
                    </Form.Label>
                    <Form.Control
                      // className='input'
                      // type="text"
                      // size='lg'
                      placeholder="Enter the comments"
                      // name="text"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                      // onChange={(e) => onChangeHandleComments(e)}
                      as="textarea"
                      rows={5}
                      disabled={inputFieldDisabled}
                    />
                  </Form.Group>
                </Row>
              }

            </Form>
          }
        </Modal.Body>
        <Modal.Footer>
          {!isSpinnerLoadingVisible &&
            <>
              <Button variant="secondary" onClick={handleEditCareerHistoryModalClose}>Close</Button>
              {!inputFieldDisabled &&
                <Button variant="primary" onClick={handleUpdateCoachHistory}>Save Changes</Button>
              }
            </>
          }
        </Modal.Footer>
      </Modal>

      <Modal show={showAddVideosModal} onHide={handleAddVideosModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Add Video Clip</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validatedVideos}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Title
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the title of the video clip"
                  value={videoTitle}
                  onChange={(e) => setVideoTitle(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoTitleError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Url
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the url of the video clip posted on youtube"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoUrlError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddVideosModalClose}>Close</Button>
            <Button variant="primary" onClick={handleSubmitVideoClip}>Save Changes</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEditVideosModal} onHide={handleEditVideosModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Edit Video Clip</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validatedVideos}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Title
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the title of the video clip"
                  value={videoTitle}
                  onChange={(e) => setVideoTitle(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoTitleError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Url
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the url of the video clip posted on youtube"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoUrlError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditVideosModalClose}>Close</Button>
            <Button variant="primary" onClick={handleUpdateVideoClip}>Save Changes</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={true} />
      {
        loading &&
        <div className='overlay'>
          <div className="container">
            <div className='search-content' style={{ marginTop: 60, marginLeft: 40, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
              <ul className='content-list' style={{ padding: 10 }}>
                {
                  searchData.length > 0 ?
                    searchData.slice(0, 3).map((item) => (
                      <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                        {item.first_name} {item.last_name}
                      </li>
                    ))

                    : <li className='list-element'>No data found</li>
                }
                {searchData.length > 0 ?
                  <>
                    <hr />
                    <li className='see-all-btn'>
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ width: 280 }}
                        onClick={() => navigateToSearchAllWithParams(searchData)}
                      >
                        See all
                      </button>
                    </li>
                  </> :
                  null}
              </ul>
            </div>
          </div>
        </div>
      }

      <Container>
        <Row>

          <ProfileBuilderNavBar
            playerIsActive={selectedPlayerSwitchOption}
            coachIsActive={selectedCoachSwitchOption}
            agentIsActive={selectedAgentSwitchOption}
          />

          <Col xs={9}>
            <Card className='mt-3'>
              <Card.Body>
                <h2 className='text-center'>Coach</h2>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                  justify
                  variant='underline'
                >
                  <Tab eventKey="general" title="GENERAL">
                    <Form noValidate validated={validatedGeneral}>

                    </Form>
                  </Tab>

                  <Tab eventKey="license" title="LICENSE">
                    <Stack direction="horizontal" gap={2}>
                      <div className="p-2 ms-auto">
                        <Button
                          variant="primary"
                          onClick={() => setShowAddLicenseModal(true)}
                        >
                          Create
                        </Button>
                      </div>
                    </Stack>
                    <div style={{ height: '350px', overflowY: 'scroll' }}>
                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of License</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>Name</th>
                                <th>License</th>
                                <th>Certificate</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {coachLicense && coachLicense.length > 0 ? coachLicenseList :
                                <tr>
                                  <th colSpan={5} className='text-center'>
                                    No rows found
                                  </th>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>

                  <Tab eventKey="careerhistory" title="CAREER HISTORY">
                    <Stack direction="horizontal" gap={2}>
                      <div className="p-2 ms-auto">
                        <Button
                          variant="primary"
                          onClick={() => setShowAddCareerHistoryModal(true)}
                        >
                          Create
                        </Button>
                      </div>
                    </Stack>
                    <div style={{ height: '350px', overflowY: 'scroll' }}>
                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of Career Histories</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>Club Name</th>
                                <th>From Year</th>
                                <th>To Year</th>
                                <th>League Name</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {coachHistory && coachHistory.length > 0 ? coachCareerHistoryList :
                                <tr>
                                  <th className='text-center' colSpan={6}>No data found</th>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>

                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of personal achievements</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>From Year</th>
                                <th>To Year</th>
                                <th>Achievement Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {coachHistory && coachHistory.length > 0 ? personalAchievementList :
                                <tr>
                                  <th className='text-center' colSpan={3}>No data found</th>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>

                  <Tab eventKey="videos" title="VIDEOS">
                    <Stack direction="horizontal" gap={2}>
                      <div className="p-2 ms-auto">
                        <Button
                          variant="primary"
                          onClick={() => setShowAddVideosModal(true)}
                        >
                          Create
                        </Button>
                      </div>
                    </Stack>
                    <div style={{ height: '350px', overflowY: 'scroll' }}>
                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of videos</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>Video Title</th>
                                <th>URL</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                videoClip && videoClip.length > 0 ?
                                  videoClipList
                                  :
                                  <tr>
                                    <th colSpan={4} className='text-center'>
                                      No rows found
                                    </th>
                                  </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>

                </Tabs>
              </Card.Body>
            </Card>

          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  )
}
