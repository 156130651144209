import React, { useContext, useEffect, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import AuthContext from '../context/AuthContext';
// import blankPhoto from '../assets/blank-profile-picture.png'
import { NetworkConnectCard } from './CustomComponents';
import { handleNetworkConnectingSubmit, handleNetworkConnectingRequest, handleNetworkConnectRequestDelete } from '../services/ApiServices';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

export const NetworkConnect = () => {

  const { 
    getUserList, 
    userList, 
    currentUser, 
    userPersonalData, 
    userPersonalInfo, 
    notifyCustomSuccessMessage,
    notifyError 
  } = useContext(AuthContext);

  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [limitPlayer, setLimitPlayer] = useState(6);
  const [limitCoach, setLimitCoach] = useState(6);
  const [limitAgent, setLimitAgent] = useState(6);
  const [limitClub, setLimitClub] = useState(6);
  const [player, setPlayer] = useState([]);
  const [coach, setCoach] = useState([]);
  const [agent, setAgent] = useState([]);
  const [club, setClub] = useState([]);
  const [reloadNetworkUser, setReloadNetworkUser] = useState(false);

  const navigate = useNavigate();

  const navigateToProfileWithParams = (item) => {
    console.log('Search Data:', item);
    if (item.account_type === 'user')
      navigate(`/user/viewprofile?user_id=${item.id}`);
    else
      navigate(`/institute/viewprofile?institute_id=${item.id}`);
  };

  const handleOnCloseUserCard = (userId) => {
    const newList = users.filter((list) => list.id !== userId);
    setUsers(newList);
    const newPlayerList = player.filter((list) => list.id !== userId);
    setPlayer(newPlayerList);
    const newCoachList = coach.filter((list) => list.id !== userId);
    setCoach(newCoachList);
    const newAgentList = agent.filter((list) => list.id !== userId);
    setAgent(newAgentList);
    const newClubList = club.filter((list) => list.id !== userId);
    setClub(newClubList);
  }

  const handleSubmit = async (item, currentUser) => {
    // event.preventDefault();
    const response = handleNetworkConnectingSubmit(item, currentUser);
    // console.log('response from handlePost:', response);
    let responseData = (await response).json()
    console.log('responseData:', responseData)
    let responseStatus = (await response).status
    // console.log('status:', responseStatus)
    if (responseStatus === 201) {
      // const id = responseData.id;
      // const response = handleNetworkConnectingRequest(item, currentUser, id);
      // console.log('response from handlePost:', response);
      // let data = (await response).json()
      // console.log('data:', data)
      // let status = (await response).status
      // console.log('status:', status)
      // if (status === 201) {
        notifyCustomSuccessMessage('Request send successfully');
      // setReloadNetworkUser(true);
      // getUserList();
      // userPersonalInfo();
      // handleUserList();
      handleOnCloseUserCard(item.id);
      // } else {
      //   handleNetworkConnectRequestDelete(id);
      //   notifyError();
      // }
    } else {
      notifyError();
    }
  }

  const handleUserList = () => {
    let list = [];
    if (userList) {
      userList.forEach((userData) => {
        if (userPersonalData) {
          userPersonalData.connected_users.forEach((connectedUser) => {
            // const newData = userList.filter((data) => data.id !== connectedUser.connect_to_user.id);
            // setUsers(...users,newData);
            // setUsers([...users, newData]);
            if (userData.id === connectedUser.connect_to_user.id && connectedUser.status === 'accepted') {
              // const newData = userList.filter((data) => data.id === connectedUser.connect_to_user.id);
              // console.log('newData: ', newData);
              list.push(userData);
            }
          })
        }
      })
      // console.log('user updated list: ', list);
      // const newData = list.filter((data) => data.id === connectedUser.connect_to_user.id);
      // console.log('newData: ', newData);

      // const filteredArray1 = list.filter((item) => !userList.find((element) => element.id === item.id));

      const filteredArray2 = userList.filter((item) => !list.find((element) => element.id === item.id));

      // console.log('filteredArray1', filteredArray1);
      // console.log('filteredArray2', filteredArray2);

      setUsers(filteredArray2);

      let playerList = [];
      let coachList = [];
      let agentList = [];
      let clubList = [];
      // filteredArray2.forEach((userData) => {
      //   const result = filteredArray2.find((player) =>
      //     player.sport_profile_type.some((item) =>
      //       item.profile_type.includes('Player') && item.is_active === 'true'
      //     )
      //   );
      //   console.log('Player', result);
      //   playerList.push(result);
      // })


      filteredArray2.forEach((userData) => {
        // const player = userData.sport_profile_type.filter((item) => item.profile_type === 'Player' && item.is_active === 'true');
        // console.log('player', player);
        // playerList.push(player);
        // console.log('userData', userData);
        // const filteredPlayerData = userData.sport_profile_type.filter(data => {
        //   return data.profile_type === 'Player' && data.is_active === 'true';
        // });
        // console.log('filteredPlayerData', filteredPlayerData);
        userData.sport_profile_type.forEach((profile) => {
          if ((profile.profile_type === 'Player') && (profile.is_active)) {
            playerList.push(userData);
          }
          else if ((profile.profile_type === 'Coach') && (profile.is_active)) {
            coachList.push(userData);
          }
          else if ((profile.profile_type === 'Agent') && (profile.is_active)) {
            agentList.push(userData);
          }
          else if ((profile.profile_type === 'Institution') && (profile.is_active)) {
            clubList.push(userData);
          }
        })
      })

      // console.log('filteredPlayerData', playerList);
      // console.log('filteredCoachData', coachList);
      // console.log('filteredAgentData', agentList);
      // console.log('filteredClubData', clubList);

      setPlayer(playerList);
      setCoach(coachList);
      setAgent(agentList);
      setClub(clubList);
      setSpinnerLoading(false);
    }
  }

  useEffect(() => {
    getUserList();
    userPersonalInfo();
    // console.log('userList: ', userList);
    handleUserList();
    // let list = [];
    // if (userList) {
    //   userList.forEach((userData) => {
    //     if (userPersonalData) {
    //       userPersonalData.connected_users.forEach((connectedUser) => {
    //         // const newData = userList.filter((data) => data.id !== connectedUser.connect_to_user.id);
    //         // setUsers(...users,newData);
    //         // setUsers([...users, newData]);
    //         if (userData.id === connectedUser.connect_to_user.id && connectedUser.status === 'accepted') {
    //           // const newData = userList.filter((data) => data.id === connectedUser.connect_to_user.id);
    //           // console.log('newData: ', newData);
    //           list.push(userData);
    //         }
    //       })
    //     }
    //   })
    //   console.log('user updated list: ', list);
    //   // const newData = list.filter((data) => data.id === connectedUser.connect_to_user.id);
    //   // console.log('newData: ', newData);

    //   const filteredArray1 = list.filter((item) => !userList.find((element) => element.id === item.id));

    //   const filteredArray2 = userList.filter((item) => !list.find((element) => element.id === item.id));

    //   console.log('filteredArray1', filteredArray1);
    //   console.log('filteredArray2', filteredArray2);

    //   setUsers(filteredArray2);

    //   let playerList = [];
    //   let coachList = [];
    //   let agentList = [];
    //   let clubList = [];
    //   // filteredArray2.forEach((userData) => {
    //   //   const result = filteredArray2.find((player) =>
    //   //     player.sport_profile_type.some((item) =>
    //   //       item.profile_type.includes('Player') && item.is_active === 'true'
    //   //     )
    //   //   );
    //   //   console.log('Player', result);
    //   //   playerList.push(result);
    //   // })


    //   filteredArray2.forEach((userData) => {
    //     // const player = userData.sport_profile_type.filter((item) => item.profile_type === 'Player' && item.is_active === 'true');
    //     // console.log('player', player);
    //     // playerList.push(player);
    //     console.log('userData', userData);
    //     // const filteredPlayerData = userData.sport_profile_type.filter(data => {
    //     //   return data.profile_type === 'Player' && data.is_active === 'true';
    //     // });
    //     // console.log('filteredPlayerData', filteredPlayerData);
    //     userData.sport_profile_type.forEach((profile) => {
    //       if ((profile.profile_type === 'Player') && (profile.is_active)) {
    //         playerList.push(userData);
    //       }
    //       else if ((profile.profile_type === 'Coach') && (profile.is_active)) {
    //         coachList.push(userData);
    //       }
    //       else if ((profile.profile_type === 'Agent') && (profile.is_active)) {
    //         agentList.push(userData);
    //       }
    //       else if ((profile.profile_type === 'Institution') && (profile.is_active)) {
    //         clubList.push(userData);
    //       }
    //     })
    //   })

    //   console.log('filteredPlayerData', playerList);
    //   console.log('filteredCoachData', coachList);
    //   console.log('filteredAgentData', agentList);
    //   console.log('filteredClubData', clubList);

    //   setPlayer(playerList);
    //   setCoach(coachList);
    //   setAgent(agentList);
    //   setClub(clubList);
    //   setSpinnerLoading(false);
    // }
    // setUsers(list);
  }, [])


  const playerDetailInfo = (type) => {
    return player.slice(0, limitPlayer).map((item) => {
      // console.log('player:', item);
      if (item.id !== currentUser.user_id) {
        return (
          <NetworkConnectCard
            item={item}
            onClose={() => handleOnCloseUserCard(item.id)}
            profileType={type}
            onConnect={() => handleSubmit(item, currentUser)}
            onClickUser={() => navigateToProfileWithParams(item)}
            currentUser={currentUser}
          />
        );
      }
    })
  }

  const coachDetailInfo = (type) => {
    return coach.slice(0, limitCoach).map((item) => {
      if (item.id !== currentUser.user_id) {
        return (
          <NetworkConnectCard
            item={item}
            onClose={() => handleOnCloseUserCard(item.id)}
            profileType={type}
            onConnect={() => handleSubmit(item, currentUser)}
            onClickUser={() => navigateToProfileWithParams(item)}
            currentUser={currentUser}
          />
        );
      }
    })
  }

  const agentDetailInfo = (type) => {
    return agent.slice(0, limitAgent).map((item) => {
      if (item.id !== currentUser.user_id) {
        return (
          <NetworkConnectCard
            item={item}
            onClose={() => handleOnCloseUserCard(item.id)}
            profileType={type}
            onConnect={() => handleSubmit(item, currentUser)}
            onClickUser={() => navigateToProfileWithParams(item)}
            currentUser={currentUser}
          />
        );
      }
    })
  }

  const clubDetailInfo = (type) => {
    return club.slice(0, limitClub).map((item) => {
      if (item.id !== currentUser.user_id) {
        return (
          <NetworkConnectCard
            item={item}
            onClose={() => handleOnCloseUserCard(item.id)}
            profileType={type}
            onConnect={() => handleSubmit(item, currentUser)}
            onClickUser={() => navigateToProfileWithParams(item)}
            currentUser={currentUser}
          />
        );
      }
    })
  }

  // const userDetailInfo = (type) => {
  //   return users.map((item) => {
  //     if (item.id !== currentUser.user_id) {
  //       return (
  //         <NetworkConnectCard
  //           item={item}
  //           onClose={() => handleOnCloseUserCard(item.id)}
  //           profileType={type}
  //           onConnect={() => handleSubmit(item, currentUser)}
  //           onClickUser={() => navigateToProfileWithParams(item)}
  //         />
  //       );
  //     } else {
  //       return (
  //         null
  //       );
  //     }
  //   })
  // }

  return (
    <>
      <div className='col'>
        <div className="card my-3">
          <div className="card-header d-flex justify-content-between" style={{ backgroundColor: '#2222e785', fontWeight: 600, color: '#fff', fontSize: 15, fontFamily: 'monospace' }}>Players you may know
            <button type="button" class="btn btn-light" onClick={() => setLimitPlayer(9)}>Load more</button>
          </div>
          <div className="card-body text-center">
            <div className="row row-cols-1 row-cols-md-4 g-4">
              {spinnerLoading ?
                <div className='text-center'>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
                :
                playerDetailInfo('Player')
              }
            </div>
          </div>
        </div>
        <div className="card my-3">
          <div className="card-header d-flex justify-content-between" style={{ backgroundColor: '#2222e785', fontWeight: 600, color: '#fff', fontSize: 15, fontFamily: 'monospace' }}>Coach you may know
            <button type="button" class="btn btn-light" onClick={() => setLimitCoach(9)}>Load more</button>
          </div>
          <div className="card-body text-center">
            <div className="row row-cols-1 row-cols-md-3 g-4">
              {spinnerLoading ?
                <div className='text-center'>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
                :
                coachDetailInfo('Coach')
              }
            </div>
          </div>
        </div>
        <div className="card my-3">
          <div className="card-header d-flex justify-content-between" style={{ backgroundColor: '#2222e785', fontWeight: 600, color: '#fff', fontSize: 15, fontFamily: 'monospace' }}>Agent you may know
            <button type="button" class="btn btn-light" onClick={() => setLimitAgent(9)}>Load more</button>
          </div>
          <div className="card-body text-center">
            <div className="row row-cols-1 row-cols-md-3 g-4">
              {spinnerLoading ?
                <div className='text-center'>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
                :
                agentDetailInfo('Agent')
              }
            </div>
          </div>
        </div>
        <div className="card my-3">
          <div className="card-header d-flex justify-content-between" style={{ backgroundColor: '#2222e785', fontWeight: 600, color: '#fff', fontSize: 15, fontFamily: 'monospace' }}>Institution you may know
            <button type="button" class="btn btn-light" onClick={() => setLimitClub(9)}>Load more</button>
          </div>
          <div className="card-body text-center">
            <div className="row row-cols-1 row-cols-md-3 g-4">
              {spinnerLoading ?
                <div className='text-center'>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
                :
                clubDetailInfo('Institution')
              }
            </div>
          </div>
        </div>
      </div >
      <ToastContainer />
    </>
  )
}
