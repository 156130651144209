import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
// import logo from '../logo.svg';
// import BlankProfilePicture from '../assets/blank-profile-picture.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import AuthContext from '../context/AuthContext';
import './css/NavBar.css';
import { Modal, Button, Form, Col, Nav, Card } from "react-bootstrap";
// import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import Nav from 'react-bootstrap/Nav';
// import Card from 'react-bootstrap/Card';
// import { data } from 'jquery';
// import Select from 'react-select';
import { SEARCHPLAYER_URL, SEARCHCOACH_URL, SEARCHAGENT_URL, SEARCHINSTITUITION_URL } from '../services/Constants';

export default function Navbar({ loading, setLoading, searchData, setSearchData, flag, profileType }) {
    let { user, logoutUser, getUserList, userList, sportProfileList, userSportProfileInfo } = useContext(AuthContext)

    const navigate = useNavigate();

    // const [showSearchModal, setShowSearchModal] = useState(false);

    // const [loadingPlayer, setLoadingPlayer] = useState(true);
    // const [loadingCoach, setLoadingCoach] = useState(false);
    // const [loadingAgent, setLoadingAgent] = useState(false);
    // const [loadingInstituition, setLoadingInstituition] = useState(false);
    // const [data, setData] = useState([{ type: "", text: "" }])
    // const [coachData, setCoachData] = useState([{ type: "", text: "" }])
    // const [agentData, setAgentData] = useState([{ type: "", text: "" }])
    // const [instituitionData, setInstituitionData] = useState([{ type: "", text: "" }])

    // const optionsPlayer = [
    //     { value: 'user__first_name', label: 'First name' },
    //     { value: 'user__last_name', label: 'Last name' },
    //     { value: 'primary_position', label: 'Primary position' },
    //     { value: 'secondary_position', label: 'Secondary position' },
    //     { value: 'top_speed', label: 'Top speed' },
    //     { value: 'preferred_foot', label: 'Preferred foot' },
    //     { value: 'current_club_inside_name', label: 'Current club' },
    // ];

    // const optionsCoach = [
    //     { value: 'user__first_name', label: 'First name' },
    //     { value: 'user__last_name', label: 'Last name' },
    //     { value: 'current_team', label: 'Current club' },
    // ];

    // const optionsAgent = [
    //     { value: 'first_name', label: 'First name' },
    //     { value: 'last_name', label: 'Last name' },
    //     { value: 'sport_type', label: 'Sport type' },
    // ];

    // const optionsInstituition = [
    //     { value: 'club_name', label: 'Institution name' },
    //     { value: 'sport_type', label: 'Sport type' },
    // ];

    const handleSearchFilter = (value) => {
        if (flag) {
            const res = userList.filter(x => (x.first_name.toLowerCase().includes(value) && x.id !== user.user_id) || (x.last_name.toLowerCase().includes(value) && x.id !== user.user_id) || (x.is_flag === true ? x.club_name !== null && x.club_name !== '' ? x.club_name.toLowerCase().includes(value) && x.id !== user.user_id : null : null));
            console.log('filtererd userList', res);
            setSearchData(res);
            setLoading(true)
            if (value === "") {
                setSearchData([]);
                setLoading(false)
            }
        }
    }

    // const handleAdvanceSearchPlayer = async () => {
    //     let baseUrl = `${SEARCHPLAYER_URL}`
    //     console.log('Data: ', data);
    //     data.forEach((item) => {
    //         console.log(item);
    //         baseUrl = baseUrl + item.type.value + '=' + item.text + '&';
    //         console.log('baseUrl:', baseUrl);
    //     })
    //     const newBaseUrl = baseUrl.substring(0, baseUrl.length - 1);
    //     console.log('complete baseUrl:', newBaseUrl);
    //     let response = fetch(`${newBaseUrl}`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     })
    //     console.log('response:', response)
    //     const apiData = (await response).json()
    //     console.log('data:', apiData)
    //     const status = (await response).status
    //     if (status === 200) {
    //         const searchInfo = (await apiData)
    //         console.log('searchInfo: ', searchInfo)
    //         // navigate("/advancesearch", { state: { searchInfo } });
    //         navigate("/advancesearch", { state: { searchInfo, type: 'player' } });
    //         setShowSearchModal(false);
    //     } else {
    //         alert('something went wrong!')
    //     }
    // }

    // const handleAdvanceSearchCoach = async () => {
    //     let baseUrl = `${SEARCHCOACH_URL}`
    //     console.log('Data: ', coachData);
    //     coachData.forEach((item) => {
    //         console.log(item);
    //         baseUrl = baseUrl + item.type.value + '=' + item.text + '&';
    //         console.log('baseUrl:', baseUrl);
    //     })
    //     const newBaseUrl = baseUrl.substring(0, baseUrl.length - 1);
    //     console.log('complete baseUrl:', newBaseUrl);
    //     let response = fetch(`${newBaseUrl}`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     })
    //     console.log('response:', response)
    //     const apiData = (await response).json()
    //     console.log('data:', apiData)
    //     const status = (await response).status
    //     if (status === 200) {
    //         const searchInfo = (await apiData)
    //         console.log('searchInfo: ', searchInfo)
    //         navigate("/advancesearch", { state: { searchInfo, type: 'coach' } });
    //         setShowSearchModal(false);
    //     } else {
    //         alert('something went wrong!')
    //     }
    // }

    // const handleAdvanceSearchAgent = async () => {
    //     let baseUrl = `${SEARCHAGENT_URL}`
    //     console.log('Data: ', agentData);
    //     agentData.forEach((item) => {
    //         console.log(item);
    //         baseUrl = baseUrl + item.type.value + '=' + item.text + '&';
    //         console.log('baseUrl:', baseUrl);
    //     })
    //     const newBaseUrl = baseUrl.substring(0, baseUrl.length - 1);
    //     console.log('complete baseUrl:', newBaseUrl);
    //     let response = fetch(`${newBaseUrl}`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     })
    //     console.log('response:', response)
    //     const apiData = (await response).json()
    //     console.log('data:', apiData)
    //     const status = (await response).status
    //     if (status === 200) {
    //         const searchInfo = (await apiData)
    //         console.log('searchInfo: ', searchInfo)
    //         navigate("/advancesearchagent", { state: { searchInfo, type: 'agent' } });
    //         setShowSearchModal(false);
    //     } else {
    //         alert('something went wrong!')
    //     }
    // }

    // const handleAdvanceSearchInstituition = async () => {
    //     let baseUrl = `${SEARCHINSTITUITION_URL}`
    //     console.log('Data: ', instituitionData);
    //     instituitionData.forEach((item) => {
    //         console.log(item);
    //         baseUrl = baseUrl + item.type.value + '=' + item.text + '&';
    //         console.log('baseUrl:', baseUrl);
    //     })
    //     const newBaseUrl = baseUrl.substring(0, baseUrl.length - 1);
    //     console.log('complete baseUrl:', newBaseUrl);
    //     let response = fetch(`${newBaseUrl}`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     })
    //     console.log('response:', response)
    //     const apiData = (await response).json()
    //     console.log('data:', apiData)
    //     const status = (await response).status
    //     if (status === 200) {
    //         const searchInfo = (await apiData)
    //         console.log('searchInfo: ', searchInfo)
    //         navigate("/advancesearch", { state: { searchInfo, type: 'instituition' } });
    //         setShowSearchModal(false);
    //     } else {
    //         alert('something went wrong!')
    //     }
    // }

    // const handleSelect = (eventKey) => alert(`selected ${eventKey}`);

    // const handleSearchModalClose = () => {
    //     setShowSearchModal(false);
    //     setLoadingPlayer(true);
    //     setLoadingCoach(false);
    //     setLoadingAgent(false);
    //     setLoadingInstituition(false);
    // }

    // const handleAddField = () => {
    //     setData([...data, { type: "", text: "" }]);
    // }

    // const handleDelete = (i) => {
    //     const deleteVal = [...data]
    //     deleteVal.splice(i, 1)
    //     setData(deleteVal)
    // }

    // const handleChange = (e, i) => {
    //     const { name, value } = e.target
    //     const onchangeVal = [...data]
    //     onchangeVal[i][name] = value
    //     setData(onchangeVal)
    // }

    // const handleChangeType = (e, i) => {
    //     // const { name, value } = e.target
    //     const onchangeVal = [...data]
    //     onchangeVal[i]['type'] = e
    //     setData(onchangeVal)
    //     console.log('onchangeVal', onchangeVal)
    // }

    // const handleAddFieldCoach = () => {
    //     setCoachData([...coachData, { type: "", text: "" }])
    // }

    // const handleDeleteCoach = (i) => {
    //     const deleteVal = [...coachData]
    //     deleteVal.splice(i, 1)
    //     setCoachData(deleteVal)
    // }

    // const handleChangeCoach = (e, i) => {
    //     const { name, value } = e.target
    //     const onchangeVal = [...coachData]
    //     onchangeVal[i][name] = value
    //     setCoachData(onchangeVal)
    // }

    // const handleChangeCoachType = (e, i) => {
    //     // const { name, value } = e.target
    //     const onchangeVal = [...coachData]
    //     onchangeVal[i]['type'] = e
    //     setCoachData(onchangeVal)
    //     console.log('onchangeVal', onchangeVal)
    // }

    // const handleAddFieldAgent = () => {
    //     setAgentData([...agentData, { type: "", text: "" }])
    // }

    // const handleChangeAgentType = (e, i) => {
    //     // const { name, value } = e.target
    //     const onchangeVal = [...agentData]
    //     onchangeVal[i]['type'] = e
    //     setAgentData(onchangeVal)
    //     console.log('onchangeVal', onchangeVal)
    // }

    // const handleChangeAgent = (e, i) => {
    //     const { name, value } = e.target
    //     const onchangeVal = [...agentData]
    //     onchangeVal[i][name] = value
    //     setAgentData(onchangeVal)
    // }

    // const handleDeleteAgent = (i) => {
    //     const deleteVal = [...agentData]
    //     deleteVal.splice(i, 1)
    //     setAgentData(deleteVal)
    // }

    // const handleAddFieldInstituition = () => {
    //     setInstituitionData([...instituitionData, { type: "", text: "" }])
    // }

    // const handleChangeInstituitionType = (e, i) => {
    //     // const { name, value } = e.target
    //     const onchangeVal = [...instituitionData]
    //     onchangeVal[i]['type'] = e
    //     setInstituitionData(onchangeVal)
    //     console.log('onchangeVal', onchangeVal)
    // }

    // const handleChangeInstituition = (e, i) => {
    //     const { name, value } = e.target
    //     const onchangeVal = [...instituitionData]
    //     onchangeVal[i][name] = value
    //     setInstituitionData(onchangeVal)
    // }

    // const handleDeleteInstutuition = (i) => {
    //     const deleteVal = [...instituitionData]
    //     deleteVal.splice(i, 1)
    //     setInstituitionData(deleteVal)
    // }

    const sportProfile = sportProfileList.map((item) => {
        if ((item.profile_type === 'Institution') && (item.is_active))
            return (
                <li className="nav-item">
                    <NavLink className="nav-link d-flex flex-column text-center" aria-current="page" to="/notifications/pending">
                        <i className="fas fa-bell fa-lg"></i>
                        <span className="small">Notifications</span>
                    </NavLink>
                </li>
            )
    })

    useEffect(() => {
        getUserList();
        // console.log('user list: ', userList);
        userSportProfileInfo();
    }, [])

    return (
        <>

            {/* <Modal show={showSearchModal} onHide={handleSearchModalClose} size="xl" scrollable={true}>
                <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
                    <Modal.Title>Advance Search</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Nav fill variant="pills" defaultActiveKey="link-1">
                            <Nav.Item onClick={() => { setLoadingPlayer(true); setLoadingCoach(false); setLoadingAgent(false); setLoadingInstituition(false) }}>
                                <Nav.Link eventKey="link-1">Player</Nav.Link>
                            </Nav.Item>
                            <Nav.Item onClick={() => { setLoadingPlayer(false); setLoadingCoach(true); setLoadingAgent(false); setLoadingInstituition(false) }}>
                                <Nav.Link eventKey="link-2">Coach</Nav.Link>
                            </Nav.Item>
                            <Nav.Item onClick={() => { setLoadingPlayer(false); setLoadingCoach(false); setLoadingAgent(true); setLoadingInstituition(false) }}>
                                <Nav.Link eventKey="link-3">Agent</Nav.Link>
                            </Nav.Item>
                            <Nav.Item onClick={() => { setLoadingPlayer(false); setLoadingCoach(false); setLoadingAgent(false); setLoadingInstituition(true) }}>
                                <Nav.Link eventKey="link-4">Instituition</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Card className='mt-3'>
                            <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Search Filter
                                {loadingPlayer &&
                                    <Button
                                        variant="light"
                                        style={{ float: 'right' }}
                                        onClick={handleAddField}
                                    >Add Fields
                                    </Button>
                                }
                                {loadingCoach &&
                                    <Button
                                        variant="light"
                                        style={{ float: 'right' }}
                                        onClick={handleAddFieldCoach}
                                    >Add Fields
                                    </Button>
                                }
                                {loadingAgent &&
                                    <Button
                                        variant="light"
                                        style={{ float: 'right' }}
                                        onClick={handleAddFieldAgent}
                                    >Add Fields
                                    </Button>
                                }
                                {loadingInstituition &&
                                    <Button
                                        variant="light"
                                        style={{ float: 'right' }}
                                        onClick={handleAddFieldInstituition}
                                    >Add Fields
                                    </Button>
                                }
                            </Card.Header>
                            <Card.Body>
                                {
                                    loadingPlayer && data.map((val, i) =>
                                        <>
                                            <div className="d-flex flex-row justify-content-between mb-1">
                                                <Form.Group as={Col} sm="3">
                                                    <Select
                                                        name='type'
                                                        value={val.type}
                                                        onChange={(e) => handleChangeType(e, i)}
                                                        options={optionsPlayer}
                                                        // styles={customStyles}
                                                        isSearchable
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} className='ms-2'>
                                                    <Form.Control
                                                        size="lg"
                                                        type="text"
                                                        placeholder="Enter your text"
                                                        name="text"
                                                        value={val.text}
                                                        onChange={(e) => handleChange(e, i)}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm="1">
                                                    <div className='d-flex justify-content-center'>
                                                        <div>
                                                            <FontAwesomeIcon
                                                                icon={faTrashCan}
                                                                color='red'
                                                                style={{ padding: 11, border: '1px solid red', borderRadius: 5, cursor: 'pointer' }}
                                                                onClick={() => handleDelete(i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    loadingCoach && coachData.map((val, i) =>
                                        <>
                                            <div className="d-flex flex-row justify-content-between mb-1">
                                                <Form.Group as={Col} sm="3">
                                                    <Select
                                                        name='type'
                                                        value={val.type}
                                                        onChange={(e) => handleChangeCoachType(e, i)}
                                                        options={optionsCoach}
                                                        // styles={customStyles}
                                                        isSearchable
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} className='ms-2'>
                                                    <Form.Control
                                                        size="lg"
                                                        type="text"
                                                        placeholder="Enter your text"
                                                        name="text"
                                                        value={val.text}
                                                        onChange={(e) => handleChangeCoach(e, i)}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm="1">
                                                    <div className='d-flex justify-content-center'>
                                                        <div>
                                                            <FontAwesomeIcon
                                                                icon={faTrashCan}
                                                                color='red'
                                                                style={{ padding: 11, border: '1px solid red', borderRadius: 5, cursor: 'pointer' }}
                                                                onClick={() => handleDeleteCoach(i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    loadingAgent && agentData.map((val, i) =>
                                        <>
                                            <div className="d-flex flex-row justify-content-between mb-1">
                                                <Form.Group as={Col} sm="3">
                                                    <Select
                                                        name='type'
                                                        value={val.type}
                                                        onChange={(e) => handleChangeAgentType(e, i)}
                                                        options={optionsAgent}
                                                        // styles={customStyles}
                                                        isSearchable
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} className='ms-2'>
                                                    <Form.Control
                                                        size="lg"
                                                        type="text"
                                                        placeholder="Enter your text"
                                                        name="text"
                                                        value={val.text}
                                                        onChange={(e) => handleChangeAgent(e, i)}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm="1">
                                                    <div className='d-flex justify-content-center'>
                                                        <div>
                                                            <FontAwesomeIcon
                                                                icon={faTrashCan}
                                                                color='red'
                                                                style={{ padding: 11, border: '1px solid red', borderRadius: 5, cursor: 'pointer' }}
                                                                onClick={() => handleDeleteAgent(i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    loadingInstituition && instituitionData.map((val, i) =>
                                        <>
                                            <div className="d-flex flex-row justify-content-between mb-1">
                                                <Form.Group as={Col} sm="3">
                                                    <Select
                                                        name='type'
                                                        value={val.type}
                                                        onChange={(e) => handleChangeInstituitionType(e, i)}
                                                        options={optionsInstituition}
                                                        // styles={customStyles}
                                                        isSearchable
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} className='ms-2'>
                                                    <Form.Control
                                                        size="lg"
                                                        type="text"
                                                        placeholder="Enter your text"
                                                        name="text"
                                                        value={val.text}
                                                        onChange={(e) => handleChangeInstituition(e, i)}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} sm="1">
                                                    <div className='d-flex justify-content-center'>
                                                        <div className='ms-1'>
                                                            <FontAwesomeIcon
                                                                icon={faTrashCan}
                                                                color='red'
                                                                style={{ padding: 11, border: '1px solid red', borderRadius: 5, cursor: 'pointer' }}
                                                                onClick={() => handleDeleteInstutuition(i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </>
                                    )
                                }
                            </Card.Body>
                        </Card>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSearchModalClose}>Close</Button>
                    {
                        loadingPlayer &&
                        <Button variant="success" onClick={handleAdvanceSearchPlayer}>Search</Button>
                    }
                    {
                        loadingCoach &&
                        <Button variant="success" onClick={handleAdvanceSearchCoach}>Search</Button>
                    }
                    {
                        loadingAgent &&
                        <Button variant="success" onClick={handleAdvanceSearchAgent}>Search</Button>
                    }
                    {
                        loadingInstituition &&
                        <Button variant="success" onClick={handleAdvanceSearchInstituition}>Search</Button>
                    }
                </Modal.Footer>
            </Modal> */}

            {/* <nav className="navbar sticky-top navbar-expand-lg border-bottom border-body" data-bs-theme="dark" style={{ backgroundColor: '#192531' }}>

                <div className="container">
                    <div className="d-flex flex-row">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <NavLink className="navbar-brand" to="/">
                            <img src={logo} alt="logo" width="30" height="24" />
                        </NavLink>
                        <div className="d-flex flex-column" role="search">
                            <input
                                className="form-control me-2"
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                style={{ width: 200 }}
                                onChange={e => handleSearchFilter(e.target.value)}
                            />
                        </div>
                        <button className="btn btn-outline-light" type="submit" onClick={() => setShowSearchModal(true)}>Advance Search</button>

                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto d-flex flex-row mt-3 mt-lg-0">
                                    <li className="nav-item text-center mx-2 mx-lg-1">
                                        <NavLink className="nav-link" aria-current="page" to="/">
                                            <div className="d-flex flex-column">
                                                <FontAwesomeIcon icon={faHome} />
                                                Home
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item text-center mx-2 mx-lg-1">
                                        <NavLink className="nav-link" to="/network/connect">
                                            <div className="d-flex flex-column">
                                                <FontAwesomeIcon icon={faNetworkWired} />
                                                My Network
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item text-center mx-2 mx-lg-1">
                                        <NavLink className="nav-link" to="/messaging">
                                            <div className="d-flex flex-column">
                                                <FontAwesomeIcon icon={faEnvelope} />
                                                Messaging
                                            </div>
                                        </NavLink>
                                    </li>

                                    {sportProfile}

                                    <li className="nav-item dropdown text-center mx-2 mx-lg-1">
                                        <div className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div className="d-flex flex-column">
                                                <FontAwesomeIcon icon={faUser} />
                                            </div>
                                            Profile
                                        </div>
                                        <ul className="dropdown-menu">
                                            <li><NavLink className="dropdown-item" to="/sportprofile">Add Sport Profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/editprofile">Change Profile</NavLink></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            {user ?
                                                (<li className="dropdown-item" onClick={logoutUser} style={{ cursor: 'pointer' }}>
                                                    Logout
                                                </li>) : (
                                                    null
                                                )}
                                        </ul>
                                    </li>
                                    <li className="nav-item text-center mx-2 mx-lg-1">
                                        <NavLink className="nav-link" to="/">
                                            <div className="d-flex flex-column">
                                                <span style={{ fontSize: 9 }}>Hello,</span>
                                                {user && <span>{user.username}</span>}
                                            </div>
                                        </NavLink>
                                    </li>
                                </ul>
                        </div>
                    </div>
                </div>
            </nav> */}

            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                <div className="container">
                    <div className="d-flex flex-row">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        {/* <a className="navbar-brand" href="#"><i className="fab fa-linkedin fa-2x"></i></a> */}
                        <form className="input-group mx-3" style={{ width: 300 }}>
                            <input type="search" className="form-control" placeholder="Search..." aria-label="Search" onChange={e => handleSearchFilter(e.target.value)} disabled={!flag} />
                            <button
                                className="btn btn-outline-primary"
                                type="button"
                                data-bs-ripple-color="dark"
                                style={{ padding: '.45rem 1.5rem .35rem' }}
                                // onClick={() => setShowSearchModal(true)}
                                onClick={() => navigate('/advancefilter')}
                                disabled={!flag}
                            >
                                Advance Search
                            </button>
                        </form>
                    </div>
                    {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}

                    {flag ?
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink className="nav-link d-flex flex-column text-center" aria-current="page" to="/">
                                        <i className="fas fa-home fa-lg"></i>
                                        <span className="small">Home</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <div
                                        className="nav-link dropdown-toggle d-flex align-items-center flex-column text-center"
                                        id="navbarDropdownMenuYourDayLink"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fas fa-calendar-day fa-lg"></i>
                                        <span className="small">Your day</span>
                                    </div>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuYourDayLink">
                                        <li><NavLink className="dropdown-item" to="/wellness">Your wellness</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/conditioning">Your conditioning</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <div
                                        className="nav-link dropdown-toggle d-flex align-items-center flex-column text-center"
                                        id="navbarDropdownMenuRequestLink"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fas fa-envelope fa-lg"></i>
                                        <span className="small">Requests</span>
                                    </div>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuRequestLink">
                                        <li><NavLink className="dropdown-item" to="/opportunaties">Opportunity</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/help">Help</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/endorsements/pending">Endorsement</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link d-flex flex-column text-center" aria-current="page" to="/network/connect">
                                        <i className="fas fa-user-friends fa-lg"></i>
                                        <span className="small">My network</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link d-flex flex-column text-center" aria-current="page" to="/messaging">
                                        <i className="fas fa-comment-dots fa-lg"></i>
                                        <span className="small">Messaging</span>
                                    </NavLink>
                                </li>

                                {sportProfile}

                                <li className="nav-item dropdown">
                                    <div
                                        className="nav-link dropdown-toggle d-flex align-items-center flex-column text-center"
                                        id="navbarDropdownMenuLink"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fas fa-user fa-lg"></i>
                                        <span className="small">Profile</span>
                                    </div>
                                    {profileType !== 'institute' ?
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <li><NavLink className="dropdown-item" to="/editprofile">Edit profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/viewprofile">View Profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/changepassword">Change password</NavLink></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            {user ?
                                                (<li className="dropdown-item" onClick={logoutUser} style={{ cursor: 'pointer' }}>
                                                    Logout
                                                </li>) : (
                                                    null
                                                )}
                                        </ul> :
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <li><NavLink className="dropdown-item" to="/editprofileinstituition">Edit profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/viewprofileinstituition">View Profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/changepassword">Change password</NavLink></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            {user ?
                                                (<li className="dropdown-item" onClick={logoutUser} style={{ cursor: 'pointer' }}>
                                                    Logout
                                                </li>) : (
                                                    null
                                                )}
                                        </ul>
                                    }
                                </li>
                            </ul>
                        </div> :
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item dropdown">
                                    <div
                                        className="nav-link dropdown-toggle d-flex align-items-center flex-column text-center"
                                        id="navbarDropdownMenuLink"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fas fa-user fa-lg"></i>
                                        <span className="small">Profile</span>
                                    </div>
                                    {profileType !== 'institute' ?
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <li><NavLink className="dropdown-item" to="/editprofile">Edit profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/viewprofile">View Profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/changepassword">Change password</NavLink></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            {user ?
                                                (<li className="dropdown-item" onClick={logoutUser} style={{ cursor: 'pointer' }}>
                                                    Logout
                                                </li>) : (
                                                    null
                                                )}
                                        </ul> :
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <li><NavLink className="dropdown-item" to="/editprofileinstituition">Edit profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/viewprofileinstituition">View Profile</NavLink></li>
                                            <li><NavLink className="dropdown-item" to="/changepassword">Change password</NavLink></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            {user ?
                                                (<li className="dropdown-item" onClick={logoutUser} style={{ cursor: 'pointer' }}>
                                                    Logout
                                                </li>) : (
                                                    null
                                                )}
                                        </ul>
                                    }
                                </li>
                            </ul>
                        </div>
                    }
                </div>
            </nav>

        </>
    )
}