import React, { useState, useContext, useEffect } from 'react'
import Navbar from '../../components/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row, Tab, Tabs, Form, Button, Table, Stack, Modal, Spinner } from 'react-bootstrap';
import { AGENTCAREERHISTORYUPDATE_URL, AGENTCAREERHISTORY_URL, AGENTLICENSE_URL, AGENTPLAYERSCOACHESENDORSEMENTUPDATE_URL, AGENT_URL, COUNTRY_URL, CREATEPLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL, FOOTBALLAGENTCAREERHISTORY_URL, FOOTBALLAGENTLICENSECREATE_URL, FOOTBALLAGENTLICENSEUPDATE_URL, FOOTBALLAGENTUPDATE_URL, FOOTBALLPLAYERSANDCOACHESUNDERAGENT_URL, GETPLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL, PERSONALACHIEVEMENTS_URL, PLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL, PROFILEDESC_URL, SEARCHCOACH_URL, SEARCHPLAYER_URL, SPORTLICENSE_URL, USER_URL, VIDEOCLIP_URL } from '../../services/Constants';
import AuthContext from '../../context/AuthContext'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select, { StylesConfig } from 'react-select';
import { ProfileBuilderNavBar } from '../../components/ProfileBuilderNavBar';
import axios from 'axios';

export default function EditAgentProfile() {

  let {
    user,
    authTokens,
    notifyRegistrationPersonalInfo,
    notifyCustomSuccessMessage,
    notifyError,
    notifyAuthenticateError,
    notifyCustomErrorMessage,
    notifyCustomWarningMessage
  } = useContext(AuthContext)

  const navigate = useNavigate();

  const [key, setKey] = useState('general');

  // State variable of search user profile in Navbar

  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);

  // State variable to manage form validation

  const [validatedGeneral, setValidatedGeneral] = useState(false);
  const [validatedLicense, setValidatedLicense] = useState(false);
  const [validatedCareerHistory, setValidatedCareerHistory] = useState(false);
  const [validatedPlayersAndCoaches, setValidatedPlayersAndCoaches] = useState(false);
  const [validatedVideos, setValidatedVideos] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  // State variable for managing Select options 

  const [optionsCountry, setOptionsCountry] = useState([]);
  const [optionsLicense, setOptionsLicense] = useState([]);

  // State variable for managing search lov filter

  const [searchLicenseLOVData, setSearchLicenseLOVData] = useState([]);
  const [isLicenseLOVloading, setIsLicenseLOVLoading] = useState(false);
  const [isCountryLOVloading, setIsCountryLOVLoading] = useState(false);
  const [searchCountryLOVData, setSearchCountryLOVData] = useState([]);

  // State variable to manage user data

  const [userPersonalData, setUserPersonalData] = useState('')
  const [sportPersonData, setSportPersonData] = useState([])

  // State variable to manage sport profile type

  const [selectedPlayerSwitchOption, setSelectedPlayerSwitchOption] = useState(false);
  const [selectedCoachSwitchOption, setSelectedCoachSwitchOption] = useState(false);
  const [selectedAgentSwitchOption, setSelectedAgentSwitchOption] = useState(false);

  // State variable to manage agent

  const [agentInfo, setAgentInfo] = useState('')
  const [agentInfoId, setAgentInfoId] = useState('')
  const [countryName, setCountryName] = useState([]);
  const [licenseName, setLicenseName] = useState('')
  const [licenseId, setLicenseId] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null);
  const [agentLicense, setAgentLicense] = useState([])
  const [agentLicenseId, setAgentLicenseId] = useState(null)

  const [licenseError, setLicenseError] = useState('');
  const [fileTypeError, setFileTypeError] = useState('');

  // State variable of agent career history

  const [agentCareerHistory, setAgentCareerHistory] = useState([])
  const [agentCareerHistoryId, setAgentCareerHistoryId] = useState(null)
  const [fromYear, setFromYear] = useState('')
  const [toYear, setToYear] = useState('')
  const [company, setCompany] = useState('')
  const [email, setEmail] = useState('')
  const [contactNo, setContactNo] = useState('')
  const [addressLane, setAddressLane] = useState('');
  const [pin, setPin] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [keyAchievements, setKeyAchievements] = useState('')
  const [summary, setSummary] = useState('')
  const [selectedValue, setSelectedValue] = useState('');

  // const [periodError, setPeriodError] = useState('');
  const [fromYearError, setFromYearError] = useState('');
  const [toYearError, setToYearError] = useState('');
  const [companyError, setCompanyError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [contactNoError, setContactNoError] = useState('');
  const [addressLaneError, setAddressLaneError] = useState('');
  const [pinError, setPinError] = useState('');
  const [stateError, setStateError] = useState('');
  const [countryError, setCountryError] = useState('');

  // State variable to manage players and coaches under me 

  const [agentPlayersAndCoaches, setAgentPlayersAndCoaches] = useState([])
  const [agentPlayersAndCoachesId, setAgentPlayersAndCoachesId] = useState(null)
  const [agentPlayersAndCoachesEndorsement, setAgentPlayersAndCoachesEndorsement] = useState([])
  // const [createNewUsers, setCreateNewUsers] = useState([])
  const [profileType, setProfileType] = useState('')
  // const [profileTypeEndorsement, setProfileTypeEndorsement] = useState('')
  const [personId, setPersonId] = useState('')
  const [agentCareerHistoryInstanceId, setAgentCareerHistoryInstanceId] = useState('')
  // const [personEndorsementId, setPersonEndorsementId] = useState('')
  const [personName, setPersonName] = useState('')
  const [comments, setComments] = useState('')
  const [athleteName, setAtheleteName] = useState([{ type: "", name: "", is_notable: false, user_id: "" }])
  const [updateAthleteName, setUpdateAtheleteName] = useState([{ type: "", name: "", is_notable: false, user_id: "", agent_career_history: "" }])
  // const [personNameEndorsement, setPersonNameEndorsement] = useState('')
  const [checkedNotable, setCheckedNotable] = useState(false);
  const [checkedEndorsement, setCheckedEndorsement] = useState(false);
  const [personNameEndorsement, setPersonNameEndorsement] = useState([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", agent_players_coaches_under_me: "" }])

  const [profileTypeError, setProfileTypeError] = useState('')
  const [personNameError, setPersonNameError] = useState('')
  const [isSpinnerLoadingVisible, setIsSpinnerLoadingVisible] = useState(false)

  // State variable to manage video clip

  const [videoId, setVideoId] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [videoTitleError, setVideoTitleError] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [videoUrlError, setVideoUrlError] = useState('');
  const [videoClip, setVideoClip] = useState([]);

  // State variable to manage model

  const [showModal, setShowModal] = useState(false);
  const [showModalEndorsement, setShowModalEndorsement] = useState(false);
  const [showModalAthelete, setShowModalAthelete] = useState(false);
  const [showModalEditAthelete, setShowModalEditAthelete] = useState(false);
  const [showAddGeneralModal, setShowAddGeneralModal] = useState(false)
  const [showEditGeneralModal, setShowEditGeneralModal] = useState(false)
  const [showAddLicenseModal, setShowAddLicenseModal] = useState(false)
  const [showEditLicenseModal, setShowEditLicenselModal] = useState(false)
  const [showAddCareerHistoryModal, setShowAddCareerHistoryModal] = useState(false)
  const [showEditCareerHistoryModal, setShowEditCareerHistoryModal] = useState(false)
  const [showAddPlayersAndCoachesEndorsementModal, setShowAddPlayersAndCoachesEndorsementModal] = useState(false)
  const [showEditPlayersAndCoachesModal, setShowEditPlayersAndCoachesModal] = useState(false)
  const [showEditPlayersAndCoachesEndorsementModal, setShowEditPlayersAndCoachesEndorsementModal] = useState(false)
  const [showAddVideosModal, setShowAddVideosModal] = useState(false)
  const [showEditVideosModal, setShowEditVideosModal] = useState(false)

  // function responsible of search data with navigation

  const navigateToSearchDataWithParams = (item) => {
    console.log('navigateToSearchDataWithParams');
    navigate(`/user/viewprofile?user_id=${item.id}`);
  };

  const navigateToSearchAllWithParams = (item) => {
    console.log('navigateToSearchAllWithParams');
    console.log('Search Data:', item);
    navigate("/searchall", { state: { item } });
  };

  // function responsible for getting data from backend

  const getAgentLicense = async () => {
    // e.preventDefault();
    // console.log('get license');
    let response = fetch(`${SPORTLICENSE_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const status = (await response).status
    if (status === 200) {
      const licenseData = (await data);
      // console.log('licenseData: ', licenseData);
      // setCountryInfo(licenseData);
      const processedOptions = licenseData.map(item => ({
        label: item.license_name,
        value: item.id
      }));
      // console.log('processed Options:', processedOptions)
      setOptionsLicense(processedOptions);
    } else {
      notifyError()
    }
  }

  const getCountries = async () => {
    // e.preventDefault();
    // console.log('get countries');
    let response = fetch(`${COUNTRY_URL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const status = (await response).status
    if (status === 200) {
      const countryData = (await data);
      // console.log('countryData: ', countryData);
      // setCountryInfo(countryData);
      const processedOptions = countryData.map(item => ({
        label: item.country_name,
        value: item.country_name
      }));
      // console.log('processed Options:', processedOptions)
      setOptionsCountry(processedOptions);
    } else {
      notifyError()
    }
  }

  const getAgentInfo = async (id) => {
    setAgentInfoId(id)
    let response = fetch(`${AGENT_URL}/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const agent = (await data)
    // console.log('agent info:', agent)
    const status = (await response).status
    if (status === 200) {
      if (agent.country_name !== null) {
        const newArray = agent.country_name.map(item => ({
          label: item.name,
          value: item.name
        }));
        // console.log('newArray: ', newArray);
        setCountryName(newArray)
      } else {
        setCountryName('')
      }
    } else {
      notifyError()
    }
  }

  const getAgentLicenseInfo = async (id) => {
    setAgentLicenseId(id)
    let response = fetch(`${AGENTLICENSE_URL}/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const agent = (await data)
    // console.log('agent LICENSE info:', agent)
    const status = (await response).status
    if (status === 200) {
      setLicenseId(agent.license_id)
      setLicenseName(agent.license_name)
      // setSelectedFile(coach.certificate)
    } else {
      notifyError()
    }
  }

  const getAgentCareerHistoryInfo = async (id) => {
    setAgentCareerHistoryId(id)
    let response = fetch(`${FOOTBALLAGENTCAREERHISTORY_URL}${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const agent = (await data)
    // console.log('agent career history info:', agent)
    const status = (await response).status
    if (status === 200) {
      setFromYear(agent.from_year)
      setToYear(agent.to_year)
      setCompany(agent.company)
      setContactNo(agent.contact_no)
      setEmail(agent.email)
      setAddressLane(agent.address_lane)
      setPin(agent.zip)
      setState(agent.state)
      setCountry(agent.country)
      setKeyAchievements(agent.achievements)
      setAgentPlayersAndCoaches(agent.players_and_coaches_under_me)
    } else {
      notifyError()
    }
  }

  const getPlayersAndCoachesUnderAgent = async (id) => {
    setAgentPlayersAndCoachesId(id)
    let response = fetch(`${GETPLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL}${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const agent = (await data)
    // console.log('agent career history info:', agent)
    const status = (await response).status
    if (status === 200) {
      setProfileType(agent.type)
      setPersonId(agent.user_id)
      setPersonName(agent.name)
      if (agent.is_notable) {
        setCheckedNotable(true)
      } else {
        setCheckedNotable(false)
      }
      setAgentPlayersAndCoachesEndorsement(agent.endorsement_request)
    } else {
      notifyError()
    }
  }

  const getVideoClipInfo = async (id) => {
    setVideoId(id)
    let response = fetch(`${VIDEOCLIP_URL}/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    // console.log('response:', response)
    const data = (await response).json()
    // console.log('data:', data)
    const video = (await data)
    // console.log('video clip:', video)
    const status = (await response).status
    if (status === 200) {
      setVideoTitle(video.title)
      setVideoUrl(video.clip_url)
    } else {
      notifyError()
    }
  }

  // functions responsible for adding data in backend

  // const handleSubmitAgent = async (event) => {
  //   event.preventDefault();
  //   const formData = new FormData();
  //   formData.append("user", user.user_id);
  //   let response = fetch(`${AGENT_URL}/`, {
  //     method: "POST",
  //     body: formData,
  //   });
  //   // console.log('response:', response)
  //   let data = (await response).json()
  //   // console.log('data:', data)
  //   let status = (await response).status
  //   // console.log('status:', status)
  //   if (status === 201) {
  //     notifyRegistrationPersonalInfo()
  //     // setIsLoading(true)
  //   } else {
  //     notifyError()
  //   }
  // }

  const handleAddAgentLicense = async (e) => {
    e.preventDefault();
    setValidatedLicense(true);
    // setIsLoading(true);
    const formData = new FormData();
    formData.append("agent", agentInfo.id);
    if (licenseName !== '') {
      formData.append("license_name", licenseName);
      setLicenseError('');
    } else {
      setLicenseError('Licence Name cannot be empty.');
      return;
    }
    if (licenseId !== null) {
      formData.append("license_id", licenseId);
    } else {
      formData.append("license_id", '');
    }
    if (selectedFile !== null) {
      // formData.append("certificate", selectedFile);
      if (selectedFile.type === 'application/pdf') {
        console.log('selectedFile', selectedFile.type);
        formData.append("document_file", selectedFile);
        formData.append("document_type", selectedFile.type);
        setFileTypeError('');
      } else {
        console.log('selectedFile', selectedFile.type);
        setFileTypeError('Please select a valid PDF file.');
        return;
      }
    }
    let response = fetch(`${FOOTBALLAGENTLICENSECREATE_URL}`, {
      method: "POST",
      body: formData,
    });
    // console.log('response:', response)
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 201) {
      notifyRegistrationPersonalInfo()
      setLicenseId(null)
      setLicenseName('')
      setSelectedFile(null)
      getAgentLicense()
      setValidatedLicense(false)
      setShowAddLicenseModal(false)
    } else {
      notifyError()
    }
  };

  // const handleAddAgentCareerHistory = async (event) => {
  //   event.preventDefault();
  //   setValidatedCareerHistory(true)
  //   const formData = new FormData();
  //   if (fromYear === '') {
  //     setFromYearError('From year cannot be left empty')
  //     return;
  //   } else {
  //     setFromYearError('')
  //     formData.append("from_year", fromYear);
  //   }
  //   if (toYear === '') {
  //     setToYearError('To year cannot be left empty')
  //     return;
  //   } else {
  //     setToYearError('')
  //     formData.append("to_year", toYear);
  //   }
  //   if (company === '') {
  //     setCompanyError('Company name cannot be left empty')
  //     return;
  //   } else {
  //     setCompanyError('')
  //     formData.append("company", company);
  //   }
  //   formData.append("email", email);
  //   formData.append("contact_no", contactNo);
  //   formData.append("address_lane", addressLane);
  //   formData.append("zip", pin);
  //   formData.append("state", state);
  //   if (country === '') {
  //     setCountryError('Country cannot be left empty')
  //     return;
  //   } else {
  //     setCountryError('')
  //     formData.append("country", country);
  //   }
  //   formData.append("achievements", keyAchievements);
  //   formData.append("summary", summary);
  //   formData.append("agent", agentInfo.id);
  //   formData.append("players_and_coaches_under_me", athleteName);
  //   let response = fetch(`${AGENTCAREERHISTORY_URL}`, {
  //     method: "POST",
  //     body: formData,
  //   });
  //   // console.log('response:', response)
  //   let data = (await response).json()
  //   // console.log('data:', data)
  //   let status = (await response).status
  //   // console.log('status:', status)
  //   if (status === 201) {
  //     notifyRegistrationPersonalInfo()
  //     setFromYear('')
  //     setToYear('')
  //     setCompany('')
  //     setContactNo('')
  //     setEmail('')
  //     setAddressLane('')
  //     setPin('')
  //     setState('')
  //     setCountry('')
  //     setKeyAchievements('')
  //     // setIsLoading(true)
  //     setValidatedCareerHistory(false)
  //     setShowAddCareerHistoryModal(false)
  //   } else if (status === 400) {
  //     let emailErr = ''
  //     if ((await data).email && (await data).email.length > 0) {
  //       emailErr = (await data).email[0]
  //       // console.log('emailErr:', emailErr)
  //       notifyCustomErrorMessage(emailErr)
  //     } else {
  //       notifyError()
  //     }
  //   } else {
  //     notifyError()
  //   }
  // }

  const handleAddAgentCareerHistory = (e) => {
    e.preventDefault();
    let data;
    setValidatedCareerHistory(true)
    if (fromYear === '') {
      setFromYearError('From year cannot be left empty')
      return;
    } else {
      setFromYearError('')
    }
    if (toYear === '') {
      setToYearError('To year cannot be left empty')
      return;
    } else {
      setToYearError('')
    }
    if (company === '') {
      setCompanyError('Company name cannot be left empty')
      return;
    } else {
      setCompanyError('')
    }
    if (country === '') {
      setCountryError('Country cannot be left empty')
      return;
    } else {
      setCountryError('')
    }
    if (athleteName.length >= 0 && athleteName[0].type !== '' && athleteName[0].name !== '' && athleteName[0].id !== '') {
      data = {
        from_year: fromYear,
        to_year: toYear,
        company: company,
        contact_no: contactNo,
        email: email,
        address_lane: addressLane,
        zip: pin,
        state: state,
        country: country,
        achievements: keyAchievements,
        summary: summary,
        agent: agentInfo.id,
        players_and_coaches_under_me: athleteName
      };
    } else {
      data = {
        from_year: fromYear,
        to_year: toYear,
        company: company,
        contact_no: contactNo,
        email: email,
        address_lane: addressLane,
        zip: pin,
        state: state,
        country: country,
        achievements: keyAchievements,
        summary: summary,
        agent: agentInfo.id
      }
    }
    axios.post(`${AGENTCAREERHISTORY_URL}`, data)
      .then(response => {
        console.log('Data posted successfully:', response.data);
        notifyRegistrationPersonalInfo()
        setFromYear('')
        setToYear('')
        setCompany('')
        setContactNo('')
        setEmail('')
        setAddressLane('')
        setPin('')
        setState('')
        setCountry('')
        setKeyAchievements('')
        setAtheleteName([{ type: "", name: "", is_notable: false, user_id: "" }])
        setValidatedCareerHistory(false)
        setShowAddCareerHistoryModal(false)
      })
      .catch(error => {
        console.error('There was an error posting the data!', error);
        notifyCustomErrorMessage('There was an error posting the data!');
      });
  };

  // const handleAddPlayersCoachesUnderMe = async (event) => {
  //   event.preventDefault();
  //   setValidatedPlayersAndCoaches(true)
  //   const formData = new FormData();
  //   if (profileType === '') {
  //     setProfileType('Profile type cannot be left empty')
  //     return;
  //   } else {
  //     setProfileType('')
  //     formData.append("sport_profile", profileType);
  //   }
  //   if (personName === '') {
  //     setPersonNameError('Name cannot be left empty')
  //     return;
  //   } else {
  //     setPersonNameError('')
  //     formData.append("user_name", personName);
  //   }
  //   if (personId !== '') {
  //     formData.append("user_id", personId);
  //   }
  //   formData.append("is_notable", checkedNotable);
  //   formData.append("agent", agentInfo.id);
  //   let response = fetch(`${FOOTBALLPLAYERSANDCOACHESUNDERAGENT_URL}`, {
  //     method: "POST",
  //     body: formData,
  //   });
  //   // console.log('response:', response)
  //   let data = (await response).json()
  //   // console.log('data:', data)
  //   let status = (await response).status
  //   // console.log('status:', status)
  //   if (status === 201) {
  //     notifyRegistrationPersonalInfo()
  //     setCheckedNotable(false)
  //     setPersonId('')
  //     setPersonName('')
  //     setProfileType('')
  //     // setIsLoading(true)
  //     setValidatedPlayersAndCoaches(false)
  //     setShowAddPlayersAndCoachesEndorsementModal(false)
  //   } else {
  //     notifyError()
  //   }
  // }

  const handleAddPlayersCoachesUnderMe = (e) => {
    e.preventDefault();
    setValidatedPlayersAndCoaches(true)
    setIsSpinnerLoadingVisible(true)
    if (profileType === '') {
      setProfileTypeError('Profile type cannot be left empty')
      return;
    } else {
      setProfileTypeError('')
    }
    if (personName === '') {
      setPersonNameError('Name cannot be left empty')
      return;
    } else {
      setPersonNameError('')
    }

    const data = {
      players_coaches_under_me: {
        type: profileType,
        user_id: personId,
        name: personName,
        is_notable: checkedNotable,
        agent_career_history: agentCareerHistoryInstanceId
      },
      endorsement_request: personNameEndorsement.length >= 0 && personNameEndorsement[0].type !== '' && personNameEndorsement[0].to_endorser_email !== '' && personNameEndorsement[0].agent_players_coaches_under_me !== '' ? personNameEndorsement : '',
    };

    console.log('data: ', data);

    axios.post(`${CREATEPLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL}`, data)
      .then(response => {
        console.log('Data posted successfully:', response.data);
        notifyRegistrationPersonalInfo()
        setProfileType('')
        setPersonId('')
        setPersonName('')
        setCheckedNotable(false)
        setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", agent_players_coaches_under_me: "" }])
        setValidatedCareerHistory(false)
        setIsSpinnerLoadingVisible(false)
        setShowAddPlayersAndCoachesEndorsementModal(false)
      })
      .catch(error => {
        console.error('There was an error posting the data!', error);
        notifyCustomErrorMessage('There was an error posting the data!');
        setIsSpinnerLoadingVisible(false)
      });
  };

  const handleSubmitVideoClip = async (event) => {
    event.preventDefault();
    setValidatedVideos(true)
    const formData = new FormData();
    if (videoTitle === '') {
      setVideoTitleError('Video title cannot be empty')
      return;
    } else {
      setVideoTitleError('')
      formData.append("title", videoTitle);
    }
    if (videoUrl === '') {
      setVideoUrlError('Video url cannot be empty')
      return;
    } else {
      setVideoUrlError('')
      formData.append("clip_url", videoUrl);
    }
    formData.append("profile_type", 'agent');
    formData.append("user_id", user.user_id);
    let response = fetch(`${VIDEOCLIP_URL}/`, {
      method: "POST",
      body: formData,
    });
    // console.log('response:', response)
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 201) {
      notifyRegistrationPersonalInfo()
      setVideoTitle('')
      setVideoTitleError('')
      setVideoUrl('')
      setVideoUrlError('')
      // setIsLoading(true)
      setValidatedVideos(false)
      setShowAddVideosModal(false)
    } else {
      notifyError()
    }
  }

  // fuctions responsible for deleting data from backend

  const handleAgentLicenseDelete = async (id) => {
    // console.log('Handle Profile Delete function called');
    let response = fetch(`${AGENTLICENSE_URL}/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyCustomSuccessMessage('Information deleted successfully.');
      const newClubList = agentLicense.filter((club) => club.id !== id);
      setAgentLicense(newClubList)
    } else {
      notifyError()
    }
  }

  const handleAgentCareerHistoryDelete = async (id) => {
    // console.log('Handle Profile Delete function called');
    let response = fetch(`${FOOTBALLAGENTCAREERHISTORY_URL}${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyCustomSuccessMessage('Information deleted successfully.');
      const newList = agentCareerHistory.filter((history) => history.id !== id);
      setAgentCareerHistory(newList)
    } else {
      notifyError()
    }
  }

  const handlePlayersAndCoachesUnderAgentDelete = async (id) => {
    // console.log('Handle Profile Delete function called');
    let response = fetch(`${FOOTBALLPLAYERSANDCOACHESUNDERAGENT_URL}${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyCustomSuccessMessage('Information deleted successfully.');
      const newList = agentPlayersAndCoaches.filter((agent) => agent.id !== id);
      setAgentPlayersAndCoaches(newList)
    } else {
      notifyError()
    }
  }

  const handlePlayersAndCoachesEndorsementUnderAgentDelete = async (id) => {
    // console.log('Handle Profile Delete function called');
    let response = fetch(`${PLAYERSANDCOACHESENDORSEMENTUNDERAGENT_URL}${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyCustomSuccessMessage('Information deleted successfully.');
      const newList = agentPlayersAndCoachesEndorsement.filter((agent) => agent.id !== id);
      setAgentPlayersAndCoachesEndorsement(newList)
    } else {
      notifyError()
    }
  }

  const handleVideoClipDelete = async (id) => {
    // console.log('Handle Video Delete function called');
    let response = fetch(`${VIDEOCLIP_URL}/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    console.log('response:', response)
    let status = (await response).status
    if (status === 204) {
      notifyCustomSuccessMessage('Successfully deleted the data!')
      const newVideoClip = videoClip.filter((clip) => clip.id !== id);
      setVideoClip(newVideoClip)
      // setIsLoading(true)
    } else {
      notifyError()
    }
  }

  // function responsible for updating data in backend

  const handleUpdateAgent = async (e) => {
    e.preventDefault();
    setValidatedGeneral(true);
    // setIsLoading(true);
    const formData = new FormData();
    // formData.append("id", agentInfo.id);
    formData.append("user", user.user_id);
    // console.log('countryName: ', countryName);
    const newArray = countryName.map(item => ({
      name: item.value
    }));
    // console.log('newArray: ', newArray);
    formData.append("country_name", JSON.stringify(newArray));
    // console.log("Form Data:", formData.values());
    let response = fetch(`${AGENT_URL}/${agentInfo.id}/`, {
      method: "PATCH",
      body: formData,
    });
    // console.log('response:', response)
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 200) {
      notifyCustomSuccessMessage('Information updated successfully.')
      setLicenseId(null)
      setLicenseName('')
      setSelectedFile(null)
      setCountryName([])
      setValidatedGeneral(false)
      setShowEditGeneralModal(false)
      getAgentLicense()
    } else {
      notifyError()
    }
  };

  const handleUpdateAgentLicense = async (e) => {
    e.preventDefault();
    setValidatedLicense(true);
    // setIsLoading(true);
    const formData = new FormData();
    formData.append("id", agentLicenseId);
    formData.append("agent", agentInfo.id);
    if (licenseName !== '') {
      formData.append("license_name", licenseName);
      setLicenseError('');
    } else {
      setLicenseError('Licence Name cannot be empty.');
      return;
    }
    if (licenseId !== null) {
      formData.append("license_id", licenseId);
    } else {
      formData.append("license_id", '');
    }
    if (selectedFile !== null) {
      // formData.append("certificate", selectedFile);
      if (selectedFile.type === 'application/pdf') {
        console.log('selectedFile', selectedFile.type);
        formData.append("document_file", selectedFile);
        formData.append("document_type", selectedFile.type);
        setFileTypeError('');
      } else {
        console.log('selectedFile', selectedFile.type);
        setFileTypeError('Please select a valid PDF file.');
        return;
      }
    }
    let response = fetch(`${FOOTBALLAGENTLICENSEUPDATE_URL}`, {
      method: "POST",
      body: formData,
    });
    // console.log('response:', response)
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 200) {
      notifyRegistrationPersonalInfo()
      setLicenseId(null)
      setLicenseName('')
      setSelectedFile(null)
      getAgentLicense()
      setValidatedLicense(false)
      setShowEditLicenselModal(false)
    } else {
      notifyError()
    }
  };

  // const handleUpdateAgentCareerHistory = async (event) => {
  //   event.preventDefault();
  //   setValidatedCareerHistory(true)
  //   const formData = new FormData();
  //   if (fromYear === '') {
  //     setFromYearError('From year cannot be left empty')
  //     return;
  //   } else {
  //     setFromYearError('')
  //     formData.append("from_year", fromYear);
  //   }
  //   if (toYear === '') {
  //     setToYearError('To year cannot be left empty')
  //     return;
  //   } else {
  //     setToYearError('')
  //     formData.append("to_year", toYear);
  //   }
  //   if (company === '') {
  //     setCompanyError('Company name cannot be left empty')
  //     return;
  //   } else {
  //     setCompanyError('')
  //     formData.append("company", company);
  //   }
  //   formData.append("email", email);
  //   formData.append("contact_no", contactNo);
  //   formData.append("address_lane", addressLane);
  //   formData.append("zip", pin);
  //   formData.append("state", state);
  //   if (country === '') {
  //     setCountryError('Country cannot be left empty')
  //     return;
  //   } else {
  //     setCountryError('')
  //     formData.append("country", country);
  //   }
  //   formData.append("achievements", keyAchievements);
  //   formData.append("summary", summary);
  //   formData.append("id", agentCareerHistoryId);
  //   if (updateAthleteName.length >= 0) {
  //     if (updateAthleteName[0].type !== '' && updateAthleteName[0].name !== '' && updateAthleteName[0].agent_career_history !== '')
  //       formData.append("players_and_coaches_under_me", JSON.stringify(updateAthleteName));
  //     else {
  //       formData.append("players_and_coaches_under_me", '');
  //     }
  //   }
  //   else {
  //     formData.append("players_and_coaches_under_me", '');
  //   }
  //   // formData.append("agent", agentInfo.id);
  //   let response = fetch(`${AGENTCAREERHISTORYUPDATE_URL}`, {
  //     method: "POST",
  //     body: formData,
  //   });
  //   // console.log('response:', response)
  //   let data = (await response).json()
  //   // console.log('data:', data)
  //   let status = (await response).status
  //   // console.log('status:', status)
  //   if (status === 200) {
  //     notifyRegistrationPersonalInfo()
  //     setAgentCareerHistoryId(null)
  //     setFromYear('')
  //     setToYear('')
  //     setCompany('')
  //     setContactNo('')
  //     setEmail('')
  //     setAddressLane('')
  //     setPin('')
  //     setState('')
  //     setCountry('')
  //     setKeyAchievements('')
  //     // setIsLoading(true)
  //     setValidatedCareerHistory(false)
  //     setShowEditCareerHistoryModal(false)
  //   // } else if (status === 400) {
  //   //   let emailErr = ''
  //   //   if ((await data).email && (await data).email.length > 0) {
  //   //     emailErr = (await data).email[0]
  //   //     // console.log('emailErr:', emailErr)
  //   //     notifyCustomErrorMessage(emailErr)
  //   //   } else {
  //   //     notifyError()
  //   //   }
  //   } else {
  //     notifyError()
  //   }
  // }

  const handleUpdateAgentCareerHistory = (e) => {
    e.preventDefault();
    setValidatedCareerHistory(true)
    if (fromYear === '') {
      setFromYearError('From year cannot be left empty')
      return;
    } else {
      setFromYearError('')
    }
    if (toYear === '') {
      setToYearError('To year cannot be left empty')
      return;
    } else {
      setToYearError('')
    }
    if (company === '') {
      setCompanyError('Company name cannot be left empty')
      return;
    } else {
      setCompanyError('')
    }
    if (country === '') {
      setCountryError('Country cannot be left empty')
      return;
    } else {
      setCountryError('')
    }
    const data = {
      id: agentCareerHistoryId,
      from_year: fromYear,
      to_year: toYear,
      company: company,
      contact_no: contactNo,
      email: email,
      address_lane: addressLane,
      zip: pin,
      state: state,
      country: country,
      achievements: keyAchievements,
      summary: summary,
      players_and_coaches_under_me: updateAthleteName.length >= 0 && updateAthleteName[0].type !== '' && updateAthleteName[0].name !== '' && updateAthleteName[0].agent_career_history !== '' ? updateAthleteName : ''
    };

    axios.post(`${AGENTCAREERHISTORYUPDATE_URL}`, data)
      .then(response => {
        console.log('Data posted successfully:', response.data);
        notifyRegistrationPersonalInfo()
        setFromYear('')
        setToYear('')
        setCompany('')
        setContactNo('')
        setEmail('')
        setAddressLane('')
        setPin('')
        setState('')
        setCountry('')
        setKeyAchievements('')
        setUpdateAtheleteName([{ type: "", name: "", is_notable: false, user_id: "", agent_career_history: "" }])
        setValidatedCareerHistory(false)
        setShowEditCareerHistoryModal(false)
      })
      .catch(error => {
        console.error('There was an error posting the data!', error);
        notifyCustomErrorMessage('There was an error posting the data!');
      });
  };

  const handleUpdatePlayerAndCoachesUnderAgent = async (event) => {
    event.preventDefault();
    setValidatedPlayersAndCoaches(true)
    // setIsLoading(true)
    const formData = new FormData();
    if (profileType === '') {
      setProfileTypeError('Profile type cannot be left empty.')
      return;
    } else {
      setProfileTypeError('')
      formData.append("type", profileType);
    }
    if (personName === '') {
      setPersonNameError('Person name cannot be left empty')
      return;
    } else {
      setPersonNameError('')
      formData.append("name", personName);
      formData.append("user_id", personId);
    }
    formData.append("is_notable", checkedNotable);
    let response = fetch(`${FOOTBALLPLAYERSANDCOACHESUNDERAGENT_URL}${agentPlayersAndCoachesId}/`, {
      method: "PATCH",
      body: formData,
    });
    // console.log('response:', response)
    // let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 200) {
      notifyCustomSuccessMessage('Successfully updated the data!')
      setPersonId(null)
      setPersonName('')
      setProfileType('')
      setCheckedNotable(false)
      // setIsLoading(false)
      setValidatedPlayersAndCoaches(false)
      setShowEditPlayersAndCoachesModal(false)
      setShowEditCareerHistoryModal(true)
    } else {
      notifyError()
    }
  }

  const handleUpdatePlayerAndCoachesEndorsementUnderAgent = (e) => {
    e.preventDefault();
    setValidatedPlayersAndCoaches(true);
    setIsSpinnerLoadingVisible(true);
    const data = {
      id: agentPlayersAndCoachesId,
      type: profileType,
      user_id: personId,
      name: personName,
      is_notable: checkedNotable,
      endorsement_request: personNameEndorsement.length >= 0 && personNameEndorsement[0].type !== '' && personNameEndorsement[0].to_endorser_email !== '' && personNameEndorsement[0].agent_players_coaches_under_me !== '' ? personNameEndorsement : '',
      // create_new_users: createNewUsers.length >= 0 ? createNewUsers : ''
    };

    console.log('data: ', data);

    axios.post(`${AGENTPLAYERSCOACHESENDORSEMENTUPDATE_URL}`, data)
      .then(response => {
        console.log('Data posted successfully:', response.data);
        notifyRegistrationPersonalInfo()
        setFromYear('')
        setToYear('')
        setCompany('')
        setContactNo('')
        setEmail('')
        setAddressLane('')
        setPin('')
        setState('')
        setCountry('')
        setKeyAchievements('')
        setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", agent_players_coaches_under_me: "" }])
        setValidatedCareerHistory(false)
        setIsSpinnerLoadingVisible(false)
        setShowEditPlayersAndCoachesEndorsementModal(false)
      })
      .catch(error => {
        console.error('There was an error posting the data!', error);
        notifyCustomErrorMessage('There was an error posting the data!');
        setIsSpinnerLoadingVisible(false);
      });
  };

  const handleUpdateVideoClip = async (event) => {
    event.preventDefault();
    setValidatedVideos(true)
    // setIsLoading(true)
    const formData = new FormData();
    if (videoTitle === '') {
      setVideoTitleError('Video title cannot be empty')
      return;
    } else {
      setVideoTitleError('')
      formData.append("title", videoTitle);
    }
    if (videoUrl === '') {
      setVideoUrlError('Video url cannot be empty')
      return;
    } else {
      setVideoUrlError('')
      formData.append("clip_url", videoUrl);
    }
    let response = fetch(`${VIDEOCLIP_URL}/${videoId}/`, {
      method: "PATCH",
      body: formData,
    });
    // console.log('response:', response)
    let data = (await response).json()
    // console.log('data:', data)
    let status = (await response).status
    // console.log('status:', status)
    if (status === 200) {
      notifyCustomSuccessMessage('Successfully updated the data!')
      setVideoTitle('')
      setVideoUrl('')
      setVideoTitleError('')
      setVideoUrlError('')
      // setIsLoading(false)
      setValidatedVideos(false)
      setShowEditVideosModal(false)
    } else {
      notifyError()
    }
  }

  // function responsible for getting user personal information

  const userPersonalInfo = async () => {
    // e.preventDefault();
    console.log('user Personal Information');
    let response = fetch(`${USER_URL}/${user.user_id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + String(authTokens.access)
      },
    })
    const data = (await response).json()
    // console.log('data:', data)
    // console.log('response:', response)
    const status = (await response).status
    if (status === 200) {
      const personalInfo = (await data)
      // console.log('personalInfo: ', personalInfo)
      setUserPersonalData(personalInfo)
      const agent = (await personalInfo.agent[0])
      if (agent) {
        setAgentInfo(agent)
        const license = (await agent.my_license)
        setAgentLicense(license)
        const history = (await agent.career_history)
        setAgentCareerHistory(history)
        // if (history.length > 0) {
        //   const playersAndCoaches = (await history.players_and_coaches_under_me)
        //   setAgentPlayersAndCoaches(playersAndCoaches)
        // }
      }
      const videoDetails = (await personalInfo.video_clip)
      setVideoClip(videoDetails)
      const sportProfileType = (await personalInfo.sport_profile_type)
      sportProfileType.map(item => {
        if (item.profile_type === 'Player') {
          if (item.is_active === true)
            setSelectedPlayerSwitchOption(true)
          else
            setSelectedPlayerSwitchOption(false)
        }
        if (item.profile_type === 'Coach') {
          if (item.is_active === true)
            setSelectedCoachSwitchOption(true)
          else
            setSelectedCoachSwitchOption(false)
        }
        if (item.profile_type === 'Agent') {
          if (item.is_active === true)
            setSelectedAgentSwitchOption(true)
          else
            setSelectedAgentSwitchOption(false)
        }
      })
      getAgentLicense()
      getCountries()
    } else if (status === 401) {
      notifyAuthenticateError()
    } else {
      notifyError()
    }
  }

  // handle on change function

  const handleSearchLicenseFilter = (value) => {
    const res = optionsLicense.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    console.log('res:', res);
    setSearchLicenseLOVData(res);
    setIsLicenseLOVLoading(true);
    setLicenseName(value);
    setLicenseId(null);
    if (value === "") {
      setSearchLicenseLOVData([]);
      setIsLicenseLOVLoading(false);
      setLicenseName('');
      setLicenseId(null);
    }
  }

  const handleSelectLicense = (item) => {
    setLicenseName(item.label);
    setLicenseId(item.value);
    setIsLicenseLOVLoading(false);
  }

  const handleSearchCountryFilter = (value) => {
    const res = optionsCountry.filter(x => x.label.toLowerCase().includes(value.toLowerCase()));
    // console.log('res:', res);
    setSearchCountryLOVData(res);
    setIsCountryLOVLoading(true);
    setCountry(value);
    if (value === "") {
      setSearchCountryLOVData([]);
      setIsCountryLOVLoading(false);
      setCountryName('');
    }
  }

  const handleSelectCountry = (item) => {
    setCountry(item.value);
    setIsCountryLOVLoading(false);
  }

  const handleOnChangeCountry = (e) => {
    setCountryName(e);
    // console.log('Countries: ', e);
  }

  const handleOnClickSearchFilter = async () => {
    setValidatedPlayersAndCoaches(true);
    if (profileType === '') {
      setProfileTypeError('Profile Type cannot be left empty.');
      return;
    } else if (profileType === 'player') {
      setProfileTypeError('');
      let COMPLETE_URL;
      let apiEndPoint;
      if (personName !== '') {
        const splitPersonName = personName.split(" ");
        console.log('splitPersonName:', splitPersonName);
        if (splitPersonName.length > 1) {
          const firstName = splitPersonName[0];
          console.log('firstName:', firstName);
          const lastName = splitPersonName[1];
          console.log('lastName:', lastName);
          apiEndPoint = 'user__first_name=' + firstName + '&user__last_name=' + lastName;
        } else {
          const firstName = splitPersonName[0];
          apiEndPoint = 'user__first_name=' + firstName;
        }
        COMPLETE_URL = SEARCHPLAYER_URL + apiEndPoint;
      } else {
        COMPLETE_URL = SEARCHPLAYER_URL
      }
      let response = fetch(`${COMPLETE_URL}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      // console.log('response:', response)
      const data = (await response).json()
      // console.log('data:', data)
      const status = (await response).status
      // console.log('status:', status)
      const list = await data;
      // console.log('list:', list);
      if (status === 200) {
        const newList = list.filter((item) => item.user.id !== user.user_id);
        // console.log('newList:', newList);
        setSportPersonData(newList);
      }
      setShowModal(true);
    } else if (profileType === 'coach') {
      setProfileTypeError('');
      let COMPLETE_URL;
      let apiEndPoint;
      if (personName !== '') {
        const splitPersonName = personName.split(" ");
        if (splitPersonName.length > 1) {
          const firstName = splitPersonName[0];
          const lastName = splitPersonName[1];
          apiEndPoint = 'user__first_name=' + firstName + '&user__last_name=' + lastName;
        } else {
          const firstName = splitPersonName[0];
          apiEndPoint = 'user__first_name=' + firstName;
        }
        COMPLETE_URL = SEARCHCOACH_URL + apiEndPoint;
      }
      else {
        COMPLETE_URL = SEARCHCOACH_URL
      }
      let response = fetch(`${COMPLETE_URL}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      // console.log('response:', response)
      const data = (await response).json()
      // console.log('data:', data)
      const status = (await response).status
      // console.log('status:', status)
      const list = await data;
      // console.log('list:', list);
      if (status === 200) {
        const newList = list.filter((item) => item.user.id !== user.user_id);
        // console.log('newList:', newList);
        setSportPersonData(newList);
      }
      setShowModal(true);
    }
  }

  const handleOnClickSearchEndorsementFilter = async (e, type, i) => {
    setValidatedPlayersAndCoaches(true);
    console.log('type :', type);
    const profileTypeEndorsement = type;
    if (profileTypeEndorsement === '') {
      setProfileTypeError('Profile Type cannot be left empty.');
      return;
    } else if (profileTypeEndorsement === 'player') {
      setProfileTypeError('');
      let COMPLETE_URL;
      let apiEndPoint;
      if (personNameEndorsement[i].to_endorser_email !== '') {
        // apiEndPoint = 'user__email=' + personNameEndorsement[i].text;
        // COMPLETE_URL = SEARCHPLAYER_URL + apiEndPoint;
        const splitPersonName = personNameEndorsement[i].to_endorser_email(" ");
        // console.log('splitPersonName:', splitPersonName);
        if (splitPersonName.length > 1) {
          const firstName = splitPersonName[0];
          // console.log('firstName:', firstName);
          const lastName = splitPersonName[1];
          // console.log('lastName:', lastName);
          apiEndPoint = 'user__first_name=' + firstName + '&user__last_name=' + lastName;
        } else {
          const firstName = splitPersonName[0];
          apiEndPoint = 'user__first_name=' + firstName;
        }
        COMPLETE_URL = SEARCHPLAYER_URL + apiEndPoint;
      } else {
        COMPLETE_URL = SEARCHPLAYER_URL
      }
      let response = fetch(`${COMPLETE_URL}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      // console.log('response:', response)
      const data = (await response).json()
      // console.log('data:', data)
      const status = (await response).status
      // console.log('status:', status)
      const list = await data;
      // console.log('list:', list);
      if (status === 200) {
        const newList = list.filter((item) => item.user.id !== user.user_id);
        // console.log('newList:', newList);
        setSportPersonData(newList);
      }
      // setShowModalEndorsement(true);
    } else if (profileTypeEndorsement === 'coach') {
      setProfileTypeError('');
      let COMPLETE_URL;
      let apiEndPoint;
      if (personNameEndorsement[i].to_endorser_email !== '') {
        // apiEndPoint = 'user__email=' + personNameEndorsement[i].text;
        // COMPLETE_URL = SEARCHCOACH_URL + apiEndPoint;
        const splitPersonName = personNameEndorsement[i].to_endorser_email(" ");
        // console.log('splitPersonName:', splitPersonName);
        if (splitPersonName.length > 1) {
          const firstName = splitPersonName[0];
          // console.log('firstName:', firstName);
          const lastName = splitPersonName[1];
          // console.log('lastName:', lastName);
          apiEndPoint = 'user__first_name=' + firstName + '&user__last_name=' + lastName;
        } else {
          const firstName = splitPersonName[0];
          apiEndPoint = 'user__first_name=' + firstName;
        }
        COMPLETE_URL = SEARCHCOACH_URL + apiEndPoint;
      } else {
        COMPLETE_URL = SEARCHCOACH_URL
      }
      let response = fetch(`${COMPLETE_URL}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      // console.log('response:', response)
      const data = (await response).json()
      // console.log('data:', data)
      const status = (await response).status
      // console.log('status:', status)
      const list = await data;
      // console.log('list:', list);
      if (status === 200) {
        const newList = list.filter((item) => item.user.id !== user.user_id);
        // console.log('newList:', newList);
        setSportPersonData(newList);
      }
      // setShowModalEndorsement(true);
    }
    setShowModalEndorsement(true);
  }

  const handleOnClickSportPerson = (data) => {
    const name = data.user.first_name + ' ' + data.user.last_name;
    setPersonName(name);
    setPersonId(data.user.id);
    setShowModal(false);
  }

  const onChangeHandleComments = (e) => {
    // const { name, value } = e.target
    // const name = 'text';
    setComments(e.target.value)
    const onchangeVal = [...personNameEndorsement]
    onchangeVal.map(item => {
      item.comments = e.target.value
    })
    // console.log('onchangeVal', onchangeVal)
    setPersonNameEndorsement(onchangeVal);
  }

  const handleOnClickSportPersonEndorsement = (data, i) => {
    // const { name, value } = e.target
    // const name = 'text';
    const onchangeVal = [...personNameEndorsement]
    onchangeVal[i]['to_endorser_email'] = data.user.email;
    onchangeVal[i]['to_endorser'] = data.user.id;
    // onchangeVal[i]['from_endorsee'] = user.user_id;
    // onchangeVal[i]['endorsee'] = personId;
    // onchangeVal[i]['status'] = 'pending';
    // onchangeVal[i]['agent_players_coaches_under_me'] = agentPlayersAndCoachesId;
    setPersonNameEndorsement(onchangeVal);
    // setPersonEndorsementId(value.user.id);
    setShowModalEndorsement(false);
  }

  const handleAddFieldEndorsement = () => {
    setPersonNameEndorsement([...personNameEndorsement, { to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", agent_players_coaches_under_me: "" }])
  }

  const handleDeleteEndorsement = (i) => {
    const deleteVal = [...personNameEndorsement]
    deleteVal.splice(i, 1)
    setPersonNameEndorsement(deleteVal)
  }

  const handleChangeEndorsement = (e, i) => {
    const { name, value } = e.target
    const onchangeVal = [...personNameEndorsement]
    onchangeVal[i][name] = value
    setPersonNameEndorsement(onchangeVal)
    console.log('onchangeVal', onchangeVal)
  }

  const handleChangeEndorsementType = (e, i) => {
    // const { name, value } = e.target
    const onchangeVal = [...personNameEndorsement]
    onchangeVal[i]['type'] = e.target.value
    onchangeVal[i]['from_endorsee'] = user.user_id;
    onchangeVal[i]['status'] = 'pending';
    onchangeVal[i]['agent_players_coaches_under_me'] = agentPlayersAndCoachesId
    setPersonNameEndorsement(onchangeVal)
    console.log('onchangeVal', onchangeVal)
  }

  const handleChangeSelectedValue = (event) => {
    // if (event.target.value != '') {
    // console.log('value: ', event.target.value);
    setSelectedValue(event.target.value);
    const originalData = event.target.value;
    const splitData = originalData.split(' - ');
    // console.log('splitData: ', splitData);
    setAgentCareerHistoryInstanceId(splitData[0])
    console.log('splitData[0]: ', splitData[0]);
    setFromYear(splitData[1]);
    console.log('splitData[1]: ', splitData[1]);
    setToYear(splitData[2]);
    console.log('splitData[2]: ', splitData[2]);
    setCompany(splitData[3]);
    console.log('splitData[3]: ', splitData[3]);
    // } else {
    //   setFromYear('');
    //   setToYear('');
    //   setCompany('');
    // }

  };

  const handleChangeProfileTypeAthlete = (e, i) => {
    // const { name, value } = e.target
    const onchangeVal = [...athleteName]
    onchangeVal[i]['type'] = e.target.value
    setAtheleteName(onchangeVal)
    console.log('onchangeVal', onchangeVal)
  }

  const handleChangeAthlete = (e, i) => {
    const { name, value } = e.target
    const onchangeVal = [...athleteName]
    onchangeVal[i][name] = value
    setAtheleteName(onchangeVal)
    console.log('onchangeVal', onchangeVal)
  }

  const handleChangeAthleteIsNotable = (e, i) => {
    // const { name, value } = e.target
    const onchangeVal = [...athleteName]
    onchangeVal[i]['is_notable'] = e
    setAtheleteName(onchangeVal)
    console.log('onchangeVal', onchangeVal)
  }

  const handleOnClickSearchAthleteFilter = async (e, type, i) => {
    setValidatedCareerHistory(true);
    // console.log('type :', type);
    // const profileTypeEndorsement = type;
    if (type === '') {
      setProfileTypeError('Profile Type cannot be left empty.');
      return;
    } else if (type === 'player') {
      setProfileTypeError('');
      let COMPLETE_URL;
      let apiEndPoint;
      if (athleteName[i].name !== '') {
        const splitPersonName = athleteName[i].name(" ");
        // console.log('splitPersonName:', splitPersonName);
        if (splitPersonName.length > 1) {
          const firstName = splitPersonName[0];
          // console.log('firstName:', firstName);
          const lastName = splitPersonName[1];
          // console.log('lastName:', lastName);
          apiEndPoint = 'user__first_name=' + firstName + '&user__last_name=' + lastName;
        } else {
          const firstName = splitPersonName[0];
          apiEndPoint = 'user__first_name=' + firstName;
        }
        COMPLETE_URL = SEARCHPLAYER_URL + apiEndPoint;
      } else {
        COMPLETE_URL = SEARCHPLAYER_URL
      }
      let response = fetch(`${COMPLETE_URL}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      // console.log('response:', response)
      const data = (await response).json()
      // console.log('data:', data)
      const status = (await response).status
      // console.log('status:', status)
      const list = await data;
      console.log('list:', list);
      if (status === 200) {
        const newList = list.filter((item) => item.user.id !== user.user_id);
        console.log('newList:', newList);
        setSportPersonData(newList);
      }
      // setShowModalEndorsement(true);
    } else if (type === 'coach') {
      setProfileTypeError('');
      let COMPLETE_URL;
      let apiEndPoint;
      if (athleteName[i].name !== '') {
        const splitPersonName = athleteName[i].name(" ");
        // console.log('splitPersonName:', splitPersonName);
        if (splitPersonName.length > 1) {
          const firstName = splitPersonName[0];
          // console.log('firstName:', firstName);
          const lastName = splitPersonName[1];
          // console.log('lastName:', lastName);
          apiEndPoint = 'user__first_name=' + firstName + '&user__last_name=' + lastName;
        } else {
          const firstName = splitPersonName[0];
          apiEndPoint = 'user__first_name=' + firstName;
        }
        COMPLETE_URL = SEARCHPLAYER_URL + apiEndPoint;
      } else {
        COMPLETE_URL = SEARCHCOACH_URL
      }
      let response = fetch(`${COMPLETE_URL}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      // console.log('response:', response)
      const data = (await response).json()
      // console.log('data:', data)
      const status = (await response).status
      // console.log('status:', status)
      const list = await data;
      console.log('list:', list);
      if (status === 200) {
        const newList = list.filter((item) => item.user.id !== user.user_id);
        console.log('newList:', newList);
        setSportPersonData(newList);
      }
      // setShowModalEndorsement(true);
    }
    setShowModalAthelete(true);
  }

  const handleAddFieldAthlete = (i) => {
    const filtered = athleteName.filter((item, index) => index === i);
    // console.log("filtered data:", filtered)
    // let flag = 0;
    if (filtered.length > 0) {
      filtered.forEach(item => {
        if (item.type !== '' && item.text !== '') {
          // flag = 1;
          setAtheleteName([...athleteName, { type: "", name: "", is_notable: false, user_id: "" }])
        } else {
          notifyCustomWarningMessage('Profile Type and Name cannot be left empty')
        }
      })
    }
    // if (flag === 1)
    //   setAtheleteName([...athleteName, { type: "", text: "", is_notable: "" }])
    // else {
    //   notifyCustomWarningMessage('Profile Type and Name cannot be left empty')
    // }
  }

  const handleDeleteAthlete = (i) => {
    const deleteVal = [...athleteName]
    deleteVal.splice(i, 1)
    setAtheleteName(deleteVal)
  }

  const handleOnClickSportPersonAthlete = (data, i) => {
    // const { name, value } = e.target
    // const name = 'name';
    const onchangeVal = [...athleteName]
    onchangeVal[i]['name'] = data.user.first_name + ' ' + data.user.last_name;
    onchangeVal[i]['user_id'] = data.user.id;
    setAtheleteName(onchangeVal);
    // setPersonEndorsementId(value.user.id);
    setShowModalAthelete(false);
  }

  const handleChangeProfileTypeAthleteUpdate = (e, i) => {
    // const { name, value } = e.target
    const onchangeVal = [...updateAthleteName]
    onchangeVal[i]['type'] = e.target.value
    onchangeVal[i]['agent_career_history'] = agentCareerHistoryId
    setUpdateAtheleteName(onchangeVal)
    console.log('onchangeVal', onchangeVal)
  }

  const handleChangeAthleteUpdate = (e, i) => {
    const { name, value } = e.target
    const onchangeVal = [...updateAthleteName]
    onchangeVal[i][name] = value
    setUpdateAtheleteName(onchangeVal)
    console.log('onchangeVal', onchangeVal)
  }

  const handleOnClickSearchAthleteUpdateFilter = async (e, type, i) => {
    setValidatedCareerHistory(true);
    // console.log('type :', type);
    // const profileTypeEndorsement = type;
    if (type === '') {
      setProfileTypeError('Profile Type cannot be left empty.');
      return;
    } else if (type === 'player') {
      setProfileTypeError('');
      let COMPLETE_URL;
      let apiEndPoint;
      if (athleteName[i].name !== '') {
        const splitPersonName = athleteName[i].name(" ");
        // console.log('splitPersonName:', splitPersonName);
        if (splitPersonName.length > 1) {
          const firstName = splitPersonName[0];
          // console.log('firstName:', firstName);
          const lastName = splitPersonName[1];
          // console.log('lastName:', lastName);
          apiEndPoint = 'user__first_name=' + firstName + '&user__last_name=' + lastName;
        } else {
          const firstName = splitPersonName[0];
          apiEndPoint = 'user__first_name=' + firstName;
        }
        COMPLETE_URL = SEARCHPLAYER_URL + apiEndPoint;
      } else {
        COMPLETE_URL = SEARCHPLAYER_URL
      }
      let response = fetch(`${COMPLETE_URL}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      // console.log('response:', response)
      const data = (await response).json()
      // console.log('data:', data)
      const status = (await response).status
      // console.log('status:', status)
      const list = await data;
      console.log('list:', list);
      if (status === 200) {
        const newList = list.filter((item) => item.user.id !== user.user_id);
        console.log('newList:', newList);
        setSportPersonData(newList);
      }
      // setShowModalEndorsement(true);
    } else if (type === 'coach') {
      setProfileTypeError('');
      let COMPLETE_URL;
      let apiEndPoint;
      if (athleteName[i].name !== '') {
        const splitPersonName = athleteName[i].name(" ");
        // console.log('splitPersonName:', splitPersonName);
        if (splitPersonName.length > 1) {
          const firstName = splitPersonName[0];
          // console.log('firstName:', firstName);
          const lastName = splitPersonName[1];
          // console.log('lastName:', lastName);
          apiEndPoint = 'user__first_name=' + firstName + '&user__last_name=' + lastName;
        } else {
          const firstName = splitPersonName[0];
          apiEndPoint = 'user__first_name=' + firstName;
        }
        COMPLETE_URL = SEARCHPLAYER_URL + apiEndPoint;
      } else {
        COMPLETE_URL = SEARCHCOACH_URL
      }
      let response = fetch(`${COMPLETE_URL}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      // console.log('response:', response)
      const data = (await response).json()
      // console.log('data:', data)
      const status = (await response).status
      // console.log('status:', status)
      const list = await data;
      // console.log('list:', list);
      if (status === 200) {
        const newList = list.filter((item) => item.user.id !== user.user_id);
        // console.log('newList:', newList);
        setSportPersonData(newList);
      }
      // setShowModalEndorsement(true);
    }
    setShowModalEditAthelete(true);
  }

  const handleOnClickSportPersonAthleteUpdate = (data, i) => {
    // const { name, value } = e.target
    // const name = 'name';
    const onchangeVal = [...updateAthleteName]
    onchangeVal[i]['name'] = data.user.first_name + ' ' + data.user.last_name;
    onchangeVal[i]['user_id'] = data.user.id;
    setUpdateAtheleteName(onchangeVal);
    // setPersonEndorsementId(value.user.id);
    setShowModalEditAthelete(false);
  }

  const handleChangeUpdateAthleteIsNotable = (e, i) => {
    // const { name, value } = e.target
    const onchangeVal = [...updateAthleteName]
    onchangeVal[i]['is_notable'] = e
    setUpdateAtheleteName(onchangeVal)
    // console.log('onchangeVal', onchangeVal)
  }

  const handleAddFieldUpdateAthlete = (i) => {
    const filtered = updateAthleteName.filter((item, index) => index === i);
    // console.log("filtered data:", filtered)
    // let flag = 0;
    if (filtered.length > 0) {
      filtered.forEach(item => {
        if (item.type !== '' && item.text !== '') {
          // flag = 1;
          setUpdateAtheleteName([...updateAthleteName, { type: "", name: "", is_notable: false, is_user: "", agent_career_history: "" }])
        } else {
          notifyCustomWarningMessage('Profile Type and Name cannot be left empty')
        }
      })
    }
    // if (flag === 1)
    //   setAtheleteName([...athleteName, { type: "", text: "", is_notable: "" }])
    // else {
    //   notifyCustomWarningMessage('Profile Type and Name cannot be left empty')
    // }
  }

  const handleDeleteUpdateAthlete = (i) => {
    const deleteVal = [...updateAthleteName]
    deleteVal.splice(i, 1)
    setUpdateAtheleteName(deleteVal)
  }

  // handle Model Close

  const handleClose = () => setShowModal(false);

  const handleCloseEndorsement = () => setShowModalEndorsement(false);

  const handleCloseAthlete = () => setShowModalAthelete(false);

  const handleCloseEditAthlete = () => setShowModalEditAthelete(false);

  const handleAddGenralModalClose = () => {
    setShowAddGeneralModal(false);
  }

  const handleEditGenralModalClose = () => {
    setShowEditGeneralModal(false);
    setLicenseId(null);
    setLicenseName('');
    setSelectedFile(null);
    setCountryName([]);
    setValidatedGeneral(false);
    setShowEditGeneralModal(false);
    getAgentLicense();
  }

  const handleAddLicenseModalClose = () => {
    setShowAddLicenseModal(false);
    setLicenseId(null);
    setLicenseName('');
    setSelectedFile(null);
    getAgentLicense();
    setValidatedLicense(false);
    setShowAddLicenseModal(false);
  }

  const handleEditLicenseModalClose = () => {
    setShowEditLicenselModal(false);
    setLicenseId(null);
    setLicenseName('');
    setSelectedFile(null);
    getAgentLicense();
    setValidatedLicense(false);
  }

  const handleAddCareerHistoryModalClose = () => {
    setShowAddCareerHistoryModal(false);
    setFromYear('');
    setToYear('');
    setCompany('');
    setContactNo('');
    setEmail('');
    setAddressLane('');
    setPin('');
    setState('');
    setCountry('');
    setKeyAchievements('');
    setAtheleteName([{ type: "", name: "", is_notable: false, user_id: "" }]);
    setValidatedCareerHistory(false);
  }

  const handleEditCareerHistoryModalClose = () => {
    setShowEditCareerHistoryModal(false);
    setFromYear('');
    setToYear('');
    setCompany('');
    setContactNo('');
    setEmail('');
    setAddressLane('');
    setPin('');
    setState('');
    setCountry('');
    setKeyAchievements('');
    setAtheleteName([{ type: "", name: "", is_notable: false, user_id: "" }]);
    setValidatedCareerHistory(false);
  }

  const handleAddPlayersAndCoachesModalClose = () => {
    setShowAddPlayersAndCoachesEndorsementModal(false);
    setProfileType('')
    setPersonId('')
    setPersonName('')
    setCheckedNotable(false)
    setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", agent_players_coaches_under_me: "" }])
    setValidatedCareerHistory(false)
  }

  const handleEditPlayersAndCoachesModalClose = () => {
    setShowEditPlayersAndCoachesModal(false);
    setShowEditCareerHistoryModal(true);
    setPersonId(null)
    setPersonName('')
    setProfileType('')
    setCheckedNotable(false)
    // setIsLoading(false)
    setValidatedPlayersAndCoaches(false)
  }

  const handleEditPlayersAndCoachesEndorsementModalClose = () => {
    setShowEditPlayersAndCoachesEndorsementModal(false);
    setFromYear('')
    setToYear('')
    setCompany('')
    setContactNo('')
    setEmail('')
    setAddressLane('')
    setPin('')
    setState('')
    setCountry('')
    setKeyAchievements('')
    setPersonNameEndorsement([{ to_endorser_email: "", to_endorser: "", from_endorsee: "", type: "", status: "", comments: "", remarks: "", agent_players_coaches_under_me: "" }])
    setValidatedCareerHistory(false)
  }

  const handleAddVideosModalClose = () => {
    setShowAddVideosModal(false);
    setVideoTitle('')
    setVideoTitleError('')
    setVideoUrl('')
    setVideoUrlError('')
  }

  const handleEditVideosModalClose = () => {
    setShowEditVideosModal(false);
    setVideoTitle('')
    setVideoTitleError('')
    setVideoUrl('')
    setVideoUrlError('')
  }

  useEffect(() => {
    userPersonalInfo();

    // function simulateNetworkRequest() {
    //   return new Promise((resolve) => setTimeout(resolve, 2000));
    // }

    // if (isLoading) {
    //   simulateNetworkRequest().then(() => {
    //     setIsLoading(false);
    //   });
    // }
  }, [showAddGeneralModal, showEditGeneralModal, showAddLicenseModal, showEditLicenseModal, showAddCareerHistoryModal, showEditCareerHistoryModal, showAddPlayersAndCoachesEndorsementModal, showEditPlayersAndCoachesEndorsementModal, showAddVideosModal, showEditVideosModal]);

  const agentLicenseList = agentLicense.map((license) => {
    const words = license.document_file.split("/");
    return (
      <tr key={license.id}>
        <td>
          <FontAwesomeIcon
            icon={faEdit}
            color='#2222e785'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              getAgentLicenseInfo(license.id);
              setShowEditLicenselModal(true);
            }}
          />
        </td>
        <td>{userPersonalData.first_name} {userPersonalData.last_name}</td>
        <td>{license.license_name}</td>
        {/* <td>
          {license.certificate !== null ?
            <img src={license.certificate} alt={license.license_name} style={{ maxWidth: '50px' }} />
            : null
          }
        </td> */}
        <td>
          <a href={license.document_file} download="downloadedFile.pdf">
            {words[5]}
          </a>
        </td>
        <td>
          <FontAwesomeIcon
            icon={faTrashCan}
            color='red'
            style={{ cursor: 'pointer' }}
            onClick={() => handleAgentLicenseDelete(license.id)}
          />
        </td>
      </tr>
    );
  })

  const agentCareerHistoryList = agentCareerHistory.map((history) => {
    return (
      <tr key={history.id}>
        <td>
          <FontAwesomeIcon
            icon={faEdit}
            color='#2222e785'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              getAgentCareerHistoryInfo(history.id);
              setShowEditCareerHistoryModal(true);
            }}
          />
        </td>
        <td>{history.from_year}</td>
        <td>{history.to_year}</td>
        <td>{history.company}</td>
        <td>{history.contact_no}</td>
        <td>{history.email}</td>
        <td>{history.state}</td>
        <td>{history.country}</td>
        <td>
          <FontAwesomeIcon
            icon={faTrashCan}
            color='red'
            style={{ cursor: 'pointer' }}
            onClick={() => handleAgentCareerHistoryDelete(history.id)}
          />
        </td>
      </tr>
    );
  })

  const personalAchievementList = agentCareerHistory.map((history) => {
    const parsedData = history.achievements !== null && history.achievements !== '' ? history.achievements.split('\n') : null;
    if (history.achievements !== null && history.achievements !== '')
      return (
        <tr key={history.id}>
          {/* <td>{history.achievements}</td> */}
          <td>{history.from_year}</td>
          <td>{history.to_year}</td>
          <td>
            {parsedData && parsedData.length > 0 &&
              <ul>
                {parsedData.map((item, index) => (
                  <li key={index} className='card-text'>{item}</li>
                ))}
              </ul>
            }
          </td>
        </tr>
      );
  })

  const sportPersonList = sportPersonData.map((data) => {
    return (
      <li className='list-element' key={data.id} onClick={() => handleOnClickSportPerson(data)}>
        <div className='d-flex justify-content-between'>
          <span>
            {data.user.first_name} {data.user.last_name}
          </span>
          <span>
            {data.user.citizenship}
          </span>
        </div>
        <div>
          {data.user.sport_type}
        </div>
      </li>
    );
  })

  const sportPersonEndorsementList = sportPersonData.map((data) => {
    return (
      <li className='list-element' key={data.id} onClick={() => handleOnClickSportPersonEndorsement(data, personNameEndorsement.length - 1)}>
        <div className='d-flex justify-content-between'>
          <span>
            {data.user.first_name} {data.user.last_name}
          </span>
          <span>
            {data.user.email}
          </span>
        </div>
        <div>
          {data.user.sport_type}
        </div>
      </li>
    );
  })

  const sportPersonAthleteList = sportPersonData.map((data) => {
    return (
      <li className='list-element' key={data.id} onClick={() => handleOnClickSportPersonAthlete(data, athleteName.length - 1)}>
        <div className='d-flex justify-content-between'>
          <span>
            {data.user.first_name} {data.user.last_name}
          </span>
          <span>
            {data.user.citizenship}
          </span>
        </div>
        <div>
          {data.user.sport_type}
        </div>
      </li>
    );
  })

  const sportPersonAthleteUpdateList = sportPersonData.map((data) => {
    return (
      <li className='list-element' key={data.id} onClick={() => handleOnClickSportPersonAthleteUpdate(data, athleteName.length - 1)}>
        <div className='d-flex justify-content-between'>
          <span>
            {data.user.first_name} {data.user.last_name}
          </span>
          <span>
            {data.user.citizenship}
          </span>
        </div>
        <div>
          {data.user.sport_type}
        </div>
      </li>
    );
  })

  const agentPlayersAndCoachesUnderMeList = agentPlayersAndCoaches.map((item) => {
    return (
      <tr key={item.id}>
        <td>
          <FontAwesomeIcon
            icon={faEdit}
            color='#2222e785'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              getPlayersAndCoachesUnderAgent(item.id);
              setShowEditPlayersAndCoachesModal(true);
              setShowEditCareerHistoryModal(false);
            }}
          />
        </td>
        <td>{item.name}</td>
        <td>{item.type}</td>
        <td>{item.is_notable ? 'Yes' : 'No'}</td>
        <td>
          <FontAwesomeIcon
            icon={faTrashCan}
            color='red'
            style={{ cursor: 'pointer' }}
            onClick={() => handlePlayersAndCoachesUnderAgentDelete(item.id)}
          />
        </td>
      </tr>
    );
  })

  const agentPlayersAndCoachesEndorsementList = agentPlayersAndCoachesEndorsement.map((item) => {
    return (
      <tr key={item.id}>
        <td>{item.to_endorser.first_name} {item.to_endorser.last_name}</td>
        <td>{item.type}</td>
        <td>{item.status}</td>
        <td>{item.remarks}</td>
        <td>
          <FontAwesomeIcon
            icon={faTrashCan}
            color='red'
            style={{ cursor: 'pointer' }}
            onClick={() => handlePlayersAndCoachesEndorsementUnderAgentDelete(item.id)}
          />
        </td>
      </tr>
    );
  })

  // const agentPlayersAndCoachesUnderMeNestedList = agentCareerHistory.map((history) => {
  //   return (
  //     <>
  //       {/* <tr key={history.id}>
  //         <td colSpan="3"><strong>{history.from_year} - {history.to_year}</strong></td>
  //       </tr> */}
  //       {history.players_and_coaches_under_me.map(item => {
  //         <tr key={item.id}>
  //           <td>
  //             <FontAwesomeIcon
  //               icon={faEdit}
  //               color='#2222e785'
  //               style={{ cursor: 'pointer' }}
  //               onClick={() => {
  //                 // getPlayersAndCoachesUnderAgent(item.id);
  //                 setShowEditPlayersAndCoachesEndorsementModal(true);
  //               }}
  //             />
  //           </td>
  //           <td>{item.name}</td>
  //           <td>{item.type}</td>
  //           <td>{item.is_notable ? 'Yes' : 'No'}</td>
  //           <td>
  //             <FontAwesomeIcon
  //               icon={faTrashCan}
  //               color='red'
  //               style={{ cursor: 'pointer' }}
  //               onClick={() => handlePlayersAndCoachesUnderAgentDelete(item.id)}
  //             />
  //           </td>
  //         </tr>
  //       })}
  //     </>
  //   );
  // })

  const videoClipList = videoClip.map((clip) => {
    if (clip.profile_type === 'agent') {
      return (
        <tr key={clip.id}>
          <td>
            <FontAwesomeIcon
              icon={faEdit}
              color='#2222e785'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                getVideoClipInfo(clip.id);
                setShowEditVideosModal(true);
              }}
            />
          </td>
          <td>{clip.title}</td>
          <td>
            <a href={'https://' + clip.clip_url} target="_blank" rel="noopener noreferrer">
              {clip.clip_url}
            </a>
          </td>
          <td>
            <FontAwesomeIcon
              icon={faTrashCan}
              color='red'
              style={{ cursor: 'pointer' }}
              onClick={() => handleVideoClipDelete(clip.id)}
            />
          </td>
        </tr>
      );
    }
  })

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles) => {
      return {
        ...styles,

        ':hover': {
          backgroundColor: "#6d6c6c",
          color: 'white',
        },
      };
    },
  };

  return (
    <>

      <Modal show={showModal} onHide={handleClose} scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>List of {profileType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className='content-list' style={{ padding: 10 }}>
            {sportPersonData && sportPersonData.length > 0 ? sportPersonList : 'No data found'}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalEndorsement} onHide={handleCloseEndorsement} scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>List of Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className='content-list' style={{ padding: 10 }}>
            {sportPersonData && sportPersonData.length > 0 ? sportPersonEndorsementList : 'No data found'}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEndorsement}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalEditAthelete} onHide={handleCloseEditAthlete} scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>List of Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className='content-list' style={{ padding: 10 }}>
            {sportPersonData && sportPersonData.length > 0 ? sportPersonAthleteUpdateList : 'No data found'}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditAthlete}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalAthelete} onHide={handleCloseAthlete} scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>List of Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className='content-list' style={{ padding: 10 }}>
            {sportPersonData && sportPersonData.length > 0 ? sportPersonAthleteList : 'No data found'}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAthlete}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditGeneralModal} onHide={handleEditGenralModalClose} size="xl" scrollable={false}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Edit Agent Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedGeneral}>
            <Row className="mb-3">
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>Country</Form.Label>
                <Select
                  value={countryName}
                  onChange={handleOnChangeCountry}
                  options={optionsCountry}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                      ...theme.colors,
                      primary25: '#6d6c6c',
                    },
                  })}
                  styles={colourStyles}
                  isMulti
                  isSearchable
                  isClearable
                  required
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditGenralModalClose}>Close</Button>
          <Button variant="primary" onClick={handleUpdateAgent}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddLicenseModal} onHide={handleAddLicenseModalClose} size="xl" scrollable={false}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Add License</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedLicense}>
            <Row className="mb-3">
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>License
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Select the name of a license"
                  value={licenseName}
                  onChange={e => handleSearchLicenseFilter(e.target.value)}
                  required
                />
                {isLicenseLOVloading &&
                  <div className='lov-content mt-1'>
                    <ul className='lov-list' style={{ padding: 5 }}>
                      {
                        searchLicenseLOVData.length > 0 ?
                          searchLicenseLOVData.map((item) => (
                            <li className='lov-list-element' key={item.value} onClick={() => handleSelectLicense(item)}>
                              {item.label}
                            </li>
                          ))

                          // : <li className='lov-list-element'>No data found</li>
                          : setIsLicenseLOVLoading(false)
                      }
                    </ul>
                  </div>
                }
                <Form.Control.Feedback type="invalid">
                  {licenseError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Certificate
                  <span className='text-danger'> </span>
                </Form.Label>
                <Form.Control
                  type="file"
                  size='lg'
                  // value={selectedFile}
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
                {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddLicenseModalClose}>Close</Button>
          <Button variant="primary" onClick={handleAddAgentLicense}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditLicenseModal} onHide={handleEditLicenseModalClose} size="xl" scrollable={false}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Edit License</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedLicense}>
            <Row className="mb-3">
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>License
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Select the name of a license"
                  value={licenseName}
                  onChange={e => handleSearchLicenseFilter(e.target.value)}
                  required
                />
                {isLicenseLOVloading &&
                  <div className='lov-content mt-1'>
                    <ul className='lov-list' style={{ padding: 5 }}>
                      {
                        searchLicenseLOVData.length > 0 ?
                          searchLicenseLOVData.map((item) => (
                            <li className='lov-list-element' key={item.value} onClick={() => handleSelectLicense(item)}>
                              {item.label}
                            </li>
                          ))

                          // : <li className='lov-list-element'>No data found</li>
                          : setIsLicenseLOVLoading(false)
                      }
                    </ul>
                  </div>
                }
                <Form.Control.Feedback type="invalid">
                  {licenseError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Certificate
                  <span className='text-danger'> </span>
                </Form.Label>
                <Form.Control
                  type="file"
                  size='lg'
                  // value={selectedFile}
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
                {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditLicenseModalClose}>Close</Button>
          <Button variant="primary" onClick={handleUpdateAgentLicense}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddPlayersAndCoachesEndorsementModal} onHide={handleAddPlayersAndCoachesModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Add Players and Coaches</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isSpinnerLoadingVisible ?
            <div className='text-center'>
              <Button variant="primary" size='lg' disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading. Please wait...
              </Button>
            </div>
            :
            <Form noValidate validated={validatedPlayersAndCoaches}>
              <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Select
                    className='input'
                    size='lg'
                    value={selectedValue}
                    onChange={handleChangeSelectedValue}
                    // onChange={handleOnChangeProfileType}
                    // style={{ fontSize: 11, padding: 10 }}
                    required
                  >
                    <option value=''>Open this select menu</option>
                    {agentCareerHistory.map((history) => (
                      <option key={history.id} value={history.id + ' - ' + history.from_year + ' - ' + history.to_year + ' - ' + history.company}>
                        {history.from_year} - {history.to_year} - {history.company}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select an option to continue
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>From Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2012"
                    value={fromYear}
                    onChange={(e) => setFromYear(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {fromYearError}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>To Year
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="number"
                    size='lg'
                    placeholder="Enter the year e.g. 2012"
                    value={toYear}
                    onChange={(e) => setToYear(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {toYearError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Company Name
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Enter the name of the company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {companyError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Profile Type
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Select
                    className='input'
                    size='lg'
                    value={profileType}
                    onChange={(e) => setProfileType(e.target.value)}
                    // onChange={handleOnChangeProfileType}
                    // style={{ fontSize: 11, padding: 10 }}
                    required
                  >
                    <option value=''>Open this select menu</option>
                    <option value="player">Player</option>
                    <option value="coach">Coach</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {profileTypeError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Name
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Stack direction="horizontal" gap={2}>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      placeholder="Enter the name of a person"
                      value={personName}
                      onChange={(e) => setPersonName(e.target.value)}
                    />
                    <Button
                      variant="primary"
                      size='lg'
                      onClick={handleOnClickSearchFilter}
                    >
                      Search
                    </Button>
                  </Stack>
                  <Form.Control.Feedback type="invalid">
                    {personNameError}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} sm="2">
                  <Form.Label className='custom-label'>Is it notable</Form.Label>
                  <Form.Check
                    type="checkbox"
                    size='lg'
                    label="Yes"
                    checked={checkedNotable}
                    onChange={() => setCheckedNotable(!checkedNotable)}
                  />
                </Form.Group>

                <Form.Group as={Col} sm="2">
                  <Form.Label className='custom-label'>Endorsement</Form.Label>
                  <Form.Check
                    type="checkbox"
                    size='lg'
                    label="Yes"
                    checked={checkedEndorsement}
                    onChange={() => setCheckedEndorsement(!checkedEndorsement)}
                  />
                </Form.Group>
              </Row>

              {checkedEndorsement &&
                <Card>
                  <Card.Header as="h5" style={{ backgroundColor: '#e3f2fd' }}>Endorsement</Card.Header>
                  <Card.Body>
                    {personNameEndorsement.map((val, i) =>
                      <Row>
                        <Form.Group as={Col} sm="4">
                          <Form.Label className='custom-label'>Profile Type
                            <span className='text-danger'> * </span>
                          </Form.Label>
                          <Form.Select
                            className='input'
                            size='lg'
                            value={val.type}
                            onChange={(e) => handleChangeEndorsementType(e, i)}
                            // onChange={handleOnChangeProfileType}
                            // style={{ fontSize: 11, padding: 10 }}
                            required
                          >
                            <option value=''>Open this select menu</option>
                            <option value="player">Player</option>
                            <option value="coach">Coach</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {profileTypeError}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} sm="4">
                          <Form.Label className='custom-label'>Name
                            <span className='text-danger'> * </span>
                          </Form.Label>
                          <Stack direction="horizontal" gap={2}>
                            <Form.Control
                              className='input'
                              type="text"
                              size='lg'
                              placeholder="Enter the name of a person"
                              name="to_endorser_email"
                              value={val.to_endorser_email}
                              onChange={(e) => handleChangeEndorsement(e, i)}
                            />
                            <Button
                              variant="primary"
                              size='lg'
                              onClick={(e) => handleOnClickSearchEndorsementFilter(e, val.type, i)}
                            >
                              Search
                            </Button>
                          </Stack>
                          <Form.Control.Feedback type="invalid">
                            {personNameError}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm="4">
                          <div className='d-flex justify-content-end mt-5'>
                            <div className='ms-2'>
                              <FontAwesomeIcon
                                icon={faPlus}
                                color='green'
                                style={{ padding: 11, border: '1px solid green', borderRadius: 5, cursor: 'pointer' }}
                                onClick={handleAddFieldEndorsement}
                              />
                            </div>
                            <div className='ms-2'>
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                color='red'
                                style={{ padding: 11, border: '1px solid red', borderRadius: 5, cursor: 'pointer' }}
                                onClick={() => handleDeleteEndorsement(i)}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Row>
                    )}
                  </Card.Body>
                </Card>
              }
            </Form>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddPlayersAndCoachesModalClose}>Close</Button>
          <Button variant="primary" onClick={handleAddPlayersCoachesUnderMe}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditPlayersAndCoachesEndorsementModal} onHide={handleEditPlayersAndCoachesEndorsementModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Edit Players and Coaches</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isSpinnerLoadingVisible ?
            <div className='text-center'>
              <Button variant="primary" size='lg' disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading. Please wait...
              </Button>
            </div>
            :
            <Form noValidate validated={validatedPlayersAndCoaches}>
              <Row className='mb-3'>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Profile Type
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Form.Select
                    className='input'
                    size='lg'
                    value={profileType}
                    onChange={(e) => setProfileType(e.target.value)}
                    // onChange={handleOnChangeProfileType}
                    // style={{ fontSize: 11, padding: 10 }}
                    disabled
                  >
                    <option value=''>Open this select menu</option>
                    <option value="player">Player</option>
                    <option value="coach">Coach</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {profileTypeError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm="4">
                  <Form.Label className='custom-label'>Name
                    <span className='text-danger'> * </span>
                  </Form.Label>
                  <Stack direction="horizontal" gap={2}>
                    <Form.Control
                      className='input'
                      type="text"
                      size='lg'
                      placeholder="Enter the name of a person"
                      value={personName}
                      onChange={(e) => setPersonName(e.target.value)}
                      disabled
                    />
                    <Button
                      variant="primary"
                      size='lg'
                      onClick={handleOnClickSearchFilter}
                      disabled
                    >
                      Search
                    </Button>
                  </Stack>
                  <Form.Control.Feedback type="invalid">
                    {personNameError}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} sm="2">
                  <Form.Label className='custom-label'>Is it notable</Form.Label>
                  <Form.Check
                    type="checkbox"
                    size='lg'
                    label="Yes"
                    checked={checkedNotable}
                    onChange={() => setCheckedNotable(!checkedNotable)}
                    disabled
                  />
                </Form.Group>

                <Form.Group as={Col} sm="2">
                  <Form.Label className='custom-label'>Endorsement</Form.Label>
                  <Form.Check
                    type="checkbox"
                    size='lg'
                    label="Yes"
                    checked={checkedEndorsement}
                    onChange={() => setCheckedEndorsement(!checkedEndorsement)}
                  />
                </Form.Group>
              </Row>

              {checkedEndorsement &&
                <Card>
                  <Card.Header as="h5" style={{ backgroundColor: '#e3f2fd' }}>Endorsement</Card.Header>
                  <Card.Body>

                    <Table striped responsive bordered hover>
                      <thead>
                        <tr>
                          <th>To Endorser</th>
                          <th>Type</th>
                          <th>Status</th>
                          <th>Remarks</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {agentPlayersAndCoachesEndorsement && agentPlayersAndCoachesEndorsement.length > 0 ?
                          agentPlayersAndCoachesEndorsementList :
                          <tr>
                            <td className='text-center' colSpan={5}>
                              No data found
                            </td>
                          </tr>
                        }
                      </tbody>
                    </Table>

                    {personNameEndorsement.map((val, i) =>
                      <Row>
                        <Form.Group as={Col} sm="4">
                          <Form.Label className='custom-label'>Profile Type
                            <span className='text-danger'> * </span>
                          </Form.Label>
                          <Form.Select
                            className='input'
                            size='lg'
                            value={val.type}
                            onChange={(e) => handleChangeEndorsementType(e, i)}
                            // onChange={handleOnChangeProfileType}
                            // style={{ fontSize: 11, padding: 10 }}
                            required
                          >
                            <option value=''>Open this select menu</option>
                            <option value="player">Player</option>
                            <option value="coach">Coach</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {profileTypeError}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} sm="4">
                          <Form.Label className='custom-label'>Name
                            <span className='text-danger'> * </span>
                          </Form.Label>
                          <Stack direction="horizontal" gap={2}>
                            <Form.Control
                              className='input'
                              type="text"
                              size='lg'
                              placeholder="Enter the name of a person"
                              name="to_endorser_email"
                              value={val.to_endorser_email}
                              onChange={(e) => handleChangeEndorsement(e, i)}
                            />
                            <Button
                              variant="primary"
                              size='lg'
                              onClick={(e) => handleOnClickSearchEndorsementFilter(e, val.type, i)}
                            >
                              Search
                            </Button>
                          </Stack>
                          <Form.Control.Feedback type="invalid">
                            {personNameError}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} sm="4">
                          <div className='d-flex justify-content-end mt-5'>
                            <div className='ms-2'>
                              <FontAwesomeIcon
                                icon={faPlus}
                                color='green'
                                style={{ padding: 11, border: '1px solid green', borderRadius: 5, cursor: 'pointer' }}
                                onClick={handleAddFieldEndorsement}
                              />
                            </div>
                            <div className='ms-2'>
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                color='red'
                                style={{ padding: 11, border: '1px solid red', borderRadius: 5, cursor: 'pointer' }}
                                onClick={() => handleDeleteEndorsement(i)}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Row>
                    )}
                  </Card.Body>
                </Card>
              }

              {checkedEndorsement &&
                <Row className='mb-3'>
                  <Form.Group as={Col}>
                    <Form.Label className='custom-label'>Comments
                      <span className='text-danger'> * </span>
                    </Form.Label>
                    <Form.Control
                      // className='input'
                      // type="text"
                      // size='lg'
                      placeholder="Enter the comments"
                      // name="text"
                      value={comments}
                      // onChange={(e) => setComments(e.target.value)}
                      onChange={(e) => onChangeHandleComments(e)}
                      as="textarea"
                      rows={5}
                    />
                  </Form.Group>
                </Row>
              }

            </Form>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditPlayersAndCoachesEndorsementModalClose}>Close</Button>
          <Button variant="primary" onClick={handleUpdatePlayerAndCoachesEndorsementUnderAgent}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditPlayersAndCoachesModal} onHide={handleEditPlayersAndCoachesModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Edit Players and Coaches</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedPlayersAndCoaches}>
            <Row className='mb-3'>
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>Profile Type
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Select
                  className='input'
                  size='lg'
                  value={profileType}
                  onChange={(e) => setProfileType(e.target.value)}
                  // onChange={handleOnChangeProfileType}
                  // style={{ fontSize: 11, padding: 10 }}
                  required
                >
                  <option value=''>Open this select menu</option>
                  <option value="player">Player</option>
                  <option value="coach">Coach</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {profileTypeError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>Name
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Stack direction="horizontal" gap={2}>
                  <Form.Control
                    className='input'
                    type="text"
                    size='lg'
                    placeholder="Enter the name of a person"
                    value={personName}
                    onChange={(e) => setPersonName(e.target.value)}
                  />
                  <Button
                    variant="primary"
                    size='lg'
                    onClick={handleOnClickSearchFilter}
                  >
                    Search
                  </Button>
                </Stack>
                <Form.Control.Feedback type="invalid">
                  {personNameError}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>Is it notable</Form.Label>
                <Form.Check
                  type="checkbox"
                  size='lg'
                  label="Yes"
                  checked={checkedNotable}
                  onChange={() => setCheckedNotable(!checkedNotable)}
                />
              </Form.Group>
            </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditPlayersAndCoachesModalClose}>Close</Button>
          <Button variant="primary" onClick={handleUpdatePlayerAndCoachesUnderAgent}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddCareerHistoryModal} onHide={handleAddCareerHistoryModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Add Career History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedCareerHistory}>
            <Row className='mb-3'>
              <Form.Group as={Col} sm='4'>
                <Form.Label className='custom-label'>From Year
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="number"
                  size='lg'
                  placeholder="Enter the year e.g. 2012"
                  value={fromYear}
                  onChange={(e) => setFromYear(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fromYearError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm='4'>
                <Form.Label className='custom-label'>To Year
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="number"
                  size='lg'
                  placeholder="Enter the year e.g. 2013"
                  value={toYear}
                  onChange={(e) => setToYear(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {toYearError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm='4'>
                <Form.Label className='custom-label'>Company Name
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the name of the company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {companyError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Summary</Form.Label>
                <Form.Control
                  className='input'
                  as="textarea"
                  rows={5}
                  placeholder="Enter the summary regarding your career"
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} sm='4'>
                <Form.Label className='custom-label'>Company Email</Form.Label>
                <Form.Control
                  className='input'
                  type="email"
                  size='lg'
                  placeholder="Enter the email address of your company"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>Company Contact Number</Form.Label>
                <Form.Control
                  className='input'
                  type='number'
                  size='lg'
                  placeholder="Enter the contact number of your company"
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {contactNoError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>Company Address Lane</Form.Label>
                <Form.Control
                  className='input'
                  type='text'
                  size='lg'
                  placeholder="Enter the address lane of your company"
                  value={addressLane}
                  onChange={(e) => setAddressLane(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {addressLaneError}
                </Form.Control.Feedback>
              </Form.Group>

            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Company Zip Code</Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the zip code of your company"
                  value={pin}
                  onChange={(e) => setPin(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {pinError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>Company State</Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the state of your company"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {stateError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>Company Country
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Select country name of your company"
                  value={country}
                  onChange={e => handleSearchCountryFilter(e.target.value)}
                  required
                />
                {isCountryLOVloading &&
                  <div className='lov-content mt-1'>
                    <ul className='lov-list' style={{ padding: 5 }}>
                      {
                        searchCountryLOVData.length > 0 ?
                          searchCountryLOVData.map((item) => (
                            <li className='lov-list-element' key={item.id} onClick={() => handleSelectCountry(item)}>
                              {item.label}
                            </li>
                          ))

                          : <li className='lov-list-element' onClick={() => { setCountry(''); setIsCountryLOVLoading(false); }}>
                            No data found. Please select a valid country.
                          </li>
                      }
                    </ul>
                  </div>
                }
                <Form.Control.Feedback type="invalid">
                  {countryError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Key Achievement</Form.Label>
                <Form.Control
                  className='input'
                  as="textarea"
                  rows={5}
                  placeholder="Enter the key achievements"
                  value={keyAchievements}
                  onChange={(e) => setKeyAchievements(e.target.value)}
                />
              </Form.Group>
            </Row>

            <Card>
              <Card.Header as="h5" style={{ backgroundColor: '#e3f2fd' }}>Players and Coaches under me</Card.Header>
              <Card.Body>

                {athleteName.map((val, i) =>
                  <Row>
                    <Form.Group as={Col} sm="4">
                      <Form.Label className='custom-label'>Profile Type
                        <span className='text-danger'> * </span>
                      </Form.Label>
                      <Form.Select
                        className='input'
                        size='lg'
                        value={val.type}
                        onChange={(e) => handleChangeProfileTypeAthlete(e, i)}
                        // onChange={handleOnChangeProfileType}
                        // style={{ fontSize: 11, padding: 10 }}
                        required
                      >
                        <option value=''>Open this select menu</option>
                        <option value="player">Player</option>
                        <option value="coach">Coach</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {profileTypeError}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} sm="4">
                      <Form.Label className='custom-label'>Name
                        <span className='text-danger'> * </span>
                      </Form.Label>
                      <Stack direction="horizontal" gap={2}>
                        <Form.Control
                          className='input'
                          type="text"
                          size='lg'
                          placeholder="Enter the name of a person"
                          name="name"
                          value={val.name}
                          onChange={(e) => handleChangeAthlete(e, i)}
                        />
                        <Button
                          variant="primary"
                          size='lg'
                          onClick={(e) => handleOnClickSearchAthleteFilter(e, val.type, i)}
                        >
                          Search
                        </Button>
                      </Stack>
                      <Form.Control.Feedback type="invalid">
                        {personNameError}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} sm="2">
                      <Form.Label className='custom-label'>Is it notable</Form.Label>
                      <Form.Check
                        type="checkbox"
                        size='lg'
                        label="Yes"
                        name="is_notable"
                        checked={val.is_notable}
                        onChange={() => handleChangeAthleteIsNotable(!val.is_notable, i)}
                      />
                    </Form.Group>

                    <Form.Group as={Col} sm="2">
                      <div className='d-flex justify-content-end mt-5'>
                        <div className='ms-2'>
                          <FontAwesomeIcon
                            icon={faPlus}
                            color='green'
                            style={{ padding: 11, border: '1px solid green', borderRadius: 5, cursor: 'pointer' }}
                            onClick={() => handleAddFieldAthlete(i)}
                          />
                        </div>
                        <div className='ms-2'>
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            color='red'
                            style={{ padding: 11, border: '1px solid red', borderRadius: 5, cursor: 'pointer' }}
                            onClick={() => handleDeleteAthlete(i)}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Row>
                )}

              </Card.Body>
            </Card>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddCareerHistoryModalClose}>Close</Button>
          <Button variant="primary" onClick={handleAddAgentCareerHistory}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditCareerHistoryModal} onHide={handleEditCareerHistoryModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Edit Career History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedCareerHistory}>
            <Row className='mb-3'>
              <Form.Group as={Col} sm='4'>
                <Form.Label className='custom-label'>From Year
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="number"
                  size='lg'
                  placeholder="Enter the year e.g. 2012"
                  value={fromYear}
                  onChange={(e) => setFromYear(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fromYearError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm='4'>
                <Form.Label className='custom-label'>To Year
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="number"
                  size='lg'
                  placeholder="Enter the year e.g. 2013"
                  value={toYear}
                  onChange={(e) => setToYear(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {toYearError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm='4'>
                <Form.Label className='custom-label'>Company Name
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the name of the company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {companyError}
                </Form.Control.Feedback>
              </Form.Group>

            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col}>
                <Form.Label>Summary</Form.Label>
                <Form.Control
                  className='input'
                  as="textarea"
                  rows={5}
                  placeholder="Enter the summary regarding your career"
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} sm='4'>
                <Form.Label className='custom-label'>Company Email
                </Form.Label>
                <Form.Control
                  className='input'
                  type="email"
                  size='lg'
                  placeholder="Enter the email address of your company"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>Company Contact Number
                </Form.Label>
                <Form.Control
                  className='input'
                  type='number'
                  size='lg'
                  placeholder="Enter the contact number of your company"
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {contactNoError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>Company Address Lane
                </Form.Label>
                <Form.Control
                  className='input'
                  type='text'
                  size='lg'
                  placeholder="Enter the address lane of your company"
                  value={addressLane}
                  onChange={(e) => setAddressLane(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {addressLaneError}
                </Form.Control.Feedback>
              </Form.Group>

            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Company Zip Code</Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the zip code of your company"
                  value={pin}
                  onChange={(e) => setPin(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {pinError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>Company State</Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the state of your company"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {stateError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm="4">
                <Form.Label className='custom-label'>Country
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Select the country name of your company"
                  value={country}
                  onChange={e => handleSearchCountryFilter(e.target.value)}
                  required
                />
                {isCountryLOVloading &&
                  <div className='lov-content mt-1'>
                    <ul className='lov-list' style={{ padding: 5 }}>
                      {
                        searchCountryLOVData.length > 0 ?
                          searchCountryLOVData.map((item) => (
                            <li className='lov-list-element' key={item.id} onClick={() => handleSelectCountry(item)}>
                              {item.label}
                            </li>
                          ))

                          : <li className='lov-list-element' onClick={() => { setCountry(''); setIsCountryLOVLoading(false); }}>
                            No data found. Please select a valid country.
                          </li>
                      }
                    </ul>
                  </div>
                }
                <Form.Control.Feedback type="invalid">
                  {countryError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Key Achievement</Form.Label>
                <Form.Control
                  className='input'
                  as="textarea"
                  rows={5}
                  placeholder="Enter the key achievements"
                  value={keyAchievements}
                  onChange={(e) => setKeyAchievements(e.target.value)}
                />
              </Form.Group>
            </Row>

            <Card>
              <Card.Header as="h5" style={{ backgroundColor: '#e3f2fd' }}>Players and Coaches under me</Card.Header>
              <Card.Body>
                <Table striped responsive bordered hover>
                  <thead>
                    <tr>
                      <th>Edit</th>
                      <th>Name</th>
                      <th>Profile Type</th>
                      <th>Is Notable</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agentPlayersAndCoaches && agentPlayersAndCoaches.length > 0 ?
                      agentPlayersAndCoachesUnderMeList :
                      <tr>
                        <td>
                          No data found
                        </td>
                      </tr>
                    }
                  </tbody>
                </Table>

                {updateAthleteName.map((val, i) =>
                  <Row>
                    <Form.Group as={Col} sm="4">
                      <Form.Label className='custom-label'>Profile Type
                        <span className='text-danger'> * </span>
                      </Form.Label>
                      <Form.Select
                        className='input'
                        size='lg'
                        value={val.type}
                        onChange={(e) => handleChangeProfileTypeAthleteUpdate(e, i)}
                        // onChange={handleOnChangeProfileType}
                        // style={{ fontSize: 11, padding: 10 }}
                        required
                      >
                        <option value=''>Open this select menu</option>
                        <option value="player">Player</option>
                        <option value="coach">Coach</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {profileTypeError}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} sm="4">
                      <Form.Label className='custom-label'>Name
                        <span className='text-danger'> * </span>
                      </Form.Label>
                      <Stack direction="horizontal" gap={2}>
                        <Form.Control
                          className='input'
                          type="text"
                          size='lg'
                          placeholder="Enter the name of a person"
                          name="name"
                          value={val.name}
                          onChange={(e) => handleChangeAthleteUpdate(e, i)}
                        />
                        <Button
                          variant="primary"
                          size='lg'
                          onClick={(e) => handleOnClickSearchAthleteUpdateFilter(e, val.type, i)}
                        >
                          Search
                        </Button>
                      </Stack>
                      <Form.Control.Feedback type="invalid">
                        {personNameError}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} sm="2">
                      <Form.Label className='custom-label'>Is it notable</Form.Label>
                      <Form.Check
                        type="checkbox"
                        size='lg'
                        label="Yes"
                        name="is_notable"
                        checked={val.is_notable}
                        onChange={() => handleChangeUpdateAthleteIsNotable(!val.is_notable, i)}
                      />
                    </Form.Group>

                    <Form.Group as={Col} sm="2">
                      <div className='d-flex justify-content-end mt-5'>
                        <div className='ms-2'>
                          <FontAwesomeIcon
                            icon={faPlus}
                            color='green'
                            style={{ padding: 11, border: '1px solid green', borderRadius: 5, cursor: 'pointer' }}
                            onClick={() => handleAddFieldUpdateAthlete(i)}
                          />
                        </div>
                        <div className='ms-2'>
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            color='red'
                            style={{ padding: 11, border: '1px solid red', borderRadius: 5, cursor: 'pointer' }}
                            onClick={() => handleDeleteUpdateAthlete(i)}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Row>
                )}

              </Card.Body>
            </Card>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditCareerHistoryModalClose}>Close</Button>
          <Button variant="primary" onClick={handleUpdateAgentCareerHistory}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddVideosModal} onHide={handleAddVideosModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Add Video Clip</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validatedVideos}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Title
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the title of the video clip"
                  value={videoTitle}
                  onChange={(e) => setVideoTitle(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoTitleError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Url
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the url of the video clip posted on youtube"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoUrlError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddVideosModalClose}>Close</Button>
            <Button variant="primary" onClick={handleSubmitVideoClip}>Save Changes</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEditVideosModal} onHide={handleEditVideosModalClose} size="xl" scrollable={true}>
        <Modal.Header closeButton style={{ backgroundColor: '#e3f2fd' }}>
          <Modal.Title>Edit Video Clip</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validatedVideos}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Title
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the title of the video clip"
                  value={videoTitle}
                  onChange={(e) => setVideoTitle(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoTitleError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className='custom-label'>Url
                  <span className='text-danger'> * </span>
                </Form.Label>
                <Form.Control
                  className='input'
                  type="text"
                  size='lg'
                  placeholder="Enter the url of the video clip posted on youtube"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {videoUrlError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditVideosModalClose}>Close</Button>
            <Button variant="primary" onClick={handleUpdateVideoClip}>Save Changes</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={true} />
      {
        loading &&
        <div className='overlay'>
          <div className="container">
            <div className='search-content' style={{ marginTop: 60, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
              <ul className='content-list' style={{ padding: 10 }}>
                {
                  searchData.length > 0 ?
                    searchData.slice(0, 3).map((item) => (
                      <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                        {item.first_name} {item.last_name}
                      </li>
                    ))

                    : <li className='list-element'>No data found</li>
                }
                {searchData.length > 0 ?
                  <>
                    <hr />
                    <li className='see-all-btn'>
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ width: 280 }}
                        onClick={() => navigateToSearchAllWithParams(searchData)}
                      >
                        See all
                      </button>
                    </li>
                  </> :
                  null}
              </ul>
            </div>
          </div>
        </div>
      }

      <Container>
        <Row>

          <ProfileBuilderNavBar
            playerIsActive={selectedPlayerSwitchOption}
            coachIsActive={selectedCoachSwitchOption}
            agentIsActive={selectedAgentSwitchOption}
          />

          <Col xs={9}>
            <Card className='mt-3'>
              <Card.Body>
                <h2 className='text-center'>Agent</h2>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                  justify
                  variant='underline'
                >
                  <Tab eventKey="general" title="GENERAL">
                    <Card>
                      <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>Agent Information</Card.Header>
                      <Card.Body>
                        <Table striped responsive bordered hover>
                          <thead>
                            <tr>
                              <th>Edit</th>
                              <th>Name</th>
                              <th>Countries I operate in</th>
                            </tr>
                          </thead>
                          <tbody>
                            {agentInfo !== '' ?
                              <tr>
                                <td>
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    color='#2222e785'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      getAgentInfo(agentInfo.id);
                                      setShowEditGeneralModal(true);
                                    }}
                                  />
                                </td>
                                <td>{userPersonalData.first_name} {userPersonalData.last_name}</td>
                                <td>
                                  {agentInfo.country_name !== null && agentInfo.country_name.map(item => {
                                    return (
                                      <div>{item.name}</div>
                                    )
                                  })}
                                </td>
                              </tr> :
                              <tr>
                                <td>
                                  No data found
                                </td>
                              </tr>
                            }
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Tab>

                  <Tab eventKey="license" title="LICENSE">
                    <Stack direction="horizontal" gap={2}>
                      <div className="p-2 ms-auto">
                        <Button
                          variant="primary"
                          onClick={() => setShowAddLicenseModal(true)}
                        >
                          Create
                        </Button>
                      </div>
                    </Stack>
                    <div style={{ height: '350px', overflowY: 'scroll' }}>
                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of License</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>Name</th>
                                <th>License</th>
                                <th>Certificate</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {agentLicense && agentLicense.length > 0 ? agentLicenseList :
                                <tr>
                                  <td colSpan={5} className='text-center'>
                                    No data found
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>


                  <Tab eventKey="careerhistory" title="CAREER HISTORY">
                    <Stack direction="horizontal" gap={2}>
                      <div className="p-2 ms-auto">
                        <Button
                          variant="primary"
                          onClick={() => setShowAddCareerHistoryModal(true)}
                        >
                          Create
                        </Button>
                      </div>
                    </Stack>
                    <div style={{ height: '350px', overflowY: 'scroll' }}>
                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of Career Histories</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>From Year</th>
                                <th>To Year</th>
                                <th>Company Name</th>
                                <th>Contact Number</th>
                                <th>Email</th>
                                <th>State</th>
                                <th>Country</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {agentCareerHistory && agentCareerHistory.length > 0 ? agentCareerHistoryList :
                                <tr>
                                  <td colSpan={9} className='text-center'>
                                    No data found
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>

                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of personal achievements</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>From Year</th>
                                <th>To Year</th>
                                <th>Achievement Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {agentCareerHistory && agentCareerHistory.length > 0 ? personalAchievementList :
                                <tr>
                                  <td colSpan={3} className='text-center'>
                                    No data found
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>

                  <Tab eventKey="playersandcoaches" title="PLAYER AND COACHES UNDER ME">
                    <Stack direction="horizontal" gap={2}>
                      <div className="p-2 ms-auto">
                        <Button
                          variant="primary"
                          onClick={() => setShowAddPlayersAndCoachesEndorsementModal(true)}
                        >
                          Create
                        </Button>
                      </div>
                    </Stack>
                    <div style={{ height: '350px', overflowY: 'scroll' }}>
                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of players and coaches</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>Name</th>
                                <th>Profile Type</th>
                                <th>Is Notable</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {agentCareerHistory && agentCareerHistory.length > 0 ?
                                agentCareerHistory.map((history) => (
                                  <>
                                    <tr key={history.id}>
                                      <td colSpan="5"><strong>{history.from_year} - {history.to_year}</strong></td>
                                    </tr>
                                    {history.players_and_coaches_under_me.map((item) => (
                                      <tr key={item.id}>
                                        <td>
                                          <FontAwesomeIcon
                                            icon={faEdit}
                                            color='#2222e785'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              getPlayersAndCoachesUnderAgent(item.id);
                                              setShowEditPlayersAndCoachesEndorsementModal(true);
                                            }}
                                          />
                                        </td>
                                        <td>{item.name}</td>
                                        <td>{item.type}</td>
                                        <td>{item.is_notable ? 'Yes' : 'No'}</td>
                                        <td>
                                          <FontAwesomeIcon
                                            icon={faTrashCan}
                                            color='red'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handlePlayersAndCoachesUnderAgentDelete(item.id)}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                ))
                                :
                                <tr>
                                  <td colSpan={5} className='text-center'>
                                    No data found
                                  </td>
                                </tr>
                              }
                            </tbody >
                          </Table>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>

                  <Tab eventKey="videos" title="VIDEOS">
                    <Stack direction="horizontal" gap={2}>
                      <div className="p-2 ms-auto">
                        <Button
                          variant="primary"
                          onClick={() => setShowAddVideosModal(true)}
                        >
                          Create
                        </Button>
                      </div>
                    </Stack>
                    <div style={{ height: '350px', overflowY: 'scroll' }}>
                      <Card>
                        <Card.Header style={{ backgroundColor: '#2222e785', color: '#fff', fontWeight: '700', fontSize: 15 }}>List of videos</Card.Header>
                        <Card.Body>
                          <Table striped responsive bordered hover>
                            <thead>
                              <tr>
                                <th>Edit</th>
                                <th>Video Title</th>
                                <th>URL</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                videoClip && videoClip.length > 0 ?
                                  videoClipList :
                                  <tr>
                                    <td colSpan={4} className='text-center'>
                                      No data found
                                    </td>
                                  </tr>
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>

          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  )
}
