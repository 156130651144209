import React, { useState, useContext, useEffect } from 'react'
import Navbar from '../components/Navbar'
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import AuthContext from '../context/AuthContext'
import { USER_URL } from '../services/Constants';
import InstituteProfile from '../components/InstituteProfile';

export const ViewInstituteProfile = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const instituteId = params.get('institute_id');

    // State variable of search user profile in Navbar

    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);

    //  State variable to manage user profile info

    let { user, authTokens } = useContext(AuthContext)

    // const [userData, setUserData] = useState('')

    const [userPersonalData, setUserPersonalData] = useState('')
    const [permanentAddress, setPermanentAddress] = useState('')
    const [profileBio, setProfileBio] = useState('')
    const [profileDesc, setProfileDesc] = useState([])
    const [sportProfile, setSportProfile] = useState([])
    const [clubData, setClubData] = useState([])
    const [clubHistory, setClubHistory] = useState([])

    // function responsible for handling navigation with params

    const navigateToSearchDataWithParams = (item) => {
        console.log('navigateToSearchDataWithParams');
        if (item.account_type === 'user')
            navigate(`/user/viewprofile?user_id=${item.id}`);
        else
            navigate(`/institute/viewprofile?institute_id=${item.id}`);
    };

    const navigateToSearchAllWithParams = (item) => {
        console.log('navigateToSearchAllWithParams');
        console.log('Search Data:', item);
        navigate("/searchall", { state: { item } });
    };

    // get user info

    const userInfo = async () => {
        // e.preventDefault();
        console.log('user Personal Information');
        let response = fetch(`${USER_URL}/${instituteId}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            },
        })
        const data = (await response).json()
        // console.log('data:', data)
        // console.log('response:', response)
        const status = (await response).status
        if (status === 200) {
            const personalInfo = (await data)
            console.log('personalInfo: ', personalInfo)
            setUserPersonalData(personalInfo)
            // Profile Description
            const desc = (await personalInfo.bio)
            if (desc !== null) {
                const words = desc.split(" ");
                const profileInfo = words.slice(0, 50).join(" ");
                setProfileBio(profileInfo)
                setProfileDesc(desc)
            }
            // Address
            const pmAddress = (await personalInfo.permanent_address[0])
            if (pmAddress) {
                setPermanentAddress(pmAddress)
            }
            // Institute
            const institute = (await personalInfo.club[0])
            console.log('institute', institute);
            if (institute) {
                setClubData(institute)
                const career = (await institute.club_history)
                if (career) {
                    setClubHistory(career)
                }
            }
            // Current profile type of a User
            const profileType = (await personalInfo.sport_profile_type)
            console.log('profileType: ', profileType)
            setSportProfile(profileType)
        }
    }

    useEffect(() => {
        userInfo()
    }, [user, authTokens])

    const clubHistoryList = clubHistory.map((club) => {
        return (
            <tr key={club.id}>
                <td>{club.from_year} - {club.to_year}</td>
                <td>{club.league_name}</td>
                <td>{club.games_played}</td>
                <td>{club.games_won}</td>
                <td>{club.games_tied}</td>
                <td>{club.games_lost}</td>
                <td>{club.tournament}</td>
                <td>{club.points}</td>
                <td>{club.position}</td>
            </tr>
        );
    })

    const achievementList = clubHistory.map(club => {
        // if (club.achievements !== null || club.achievements !== '') {
        const parsedData = club.achievement !== null && club.achievement !== '' ? club.achievement.split('\n') : null;
        return (
            <>
                {
                    club.achievement !== null && club.achievement !== '' ?
                        <div key={club.id} className='d-flex flex-row justify-content-between'>
                            <div className='col-md-5'>
                                <p className='card-text'>{club.from_year} - {club.to_year}</p>
                            </div>
                            <div className='col-md-7'>
                                {/* <p className='card-text'>{club.achievements}</p> */}
                                {parsedData && parsedData.length > 0 &&
                                    <ul>
                                        {parsedData.map((item, index) => (
                                            <li key={index} className='card-text'>{item}</li>
                                        ))}
                                    </ul>
                                }
                            </div>
                        </div>
                        : null
                }
            </>
        )
        // }
    })

    return (
        <>
            <div className="modal fade modal-xl" id="profileModal" tabIndex="-1" aria-labelledby="profileModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="profileModalLabel"><b>Profile Info</b></h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='col'>
                                <p className='card-text'>{profileDesc}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Navbar loading={loading} setLoading={setLoading} searchData={searchData} setSearchData={setSearchData} flag={user.is_flag} profileType={'institute'} />
            {
                loading &&
                <div className='overlay'>
                    <div className="container">
                        <div className='search-content' style={{ marginTop: 60, width: 300, backgroundColor: 'whitesmoke', zIndex: 10, borderRadius: 5 }}>
                            <ul className='content-list' style={{ padding: 10 }}>
                                {
                                    searchData.length > 0 ?
                                        searchData.slice(0, 3).map((item) => (
                                            <li className='list-element' key={item.id} onClick={() => navigateToSearchDataWithParams(item)}>
                                                {item.account_type === 'user' ? item.first_name + ' ' + item.last_name : item.club_name}
                                            </li>
                                        ))

                                        : <li className='list-element'>No data found</li>
                                }
                                {searchData.length > 0 ?
                                    <>
                                        <hr />
                                        <li className='see-all-btn'>
                                            <button
                                                type="button"
                                                className="btn btn-outline-dark"
                                                style={{ width: 280 }}
                                                onClick={() => navigateToSearchAllWithParams(searchData)}
                                            >
                                                See all
                                            </button>
                                        </li>
                                    </> :
                                    null
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            }

            <div className='container'>
                <div className='d-flex flex-row my-2'>
                    <div className="col-md-2">
                        <img src={userPersonalData.profile_photo} className="img-thumbnail" alt="..." style={{ width: 150, height: 150, objectFit: 'cover' }} />
                    </div>
                    <div className='d-flex flex-column'>
                        <div className="col-auto">
                            <h5>
                                {userPersonalData.club_name}
                            </h5>
                        </div>
                        <div className="col-auto">
                            {permanentAddress.country}
                        </div>
                    </div>
                </div>
                <div className='d-flex row'>
                    <div className='col-md-4'>
                        <div className="card text-bg-light mb-3">
                            <div className="card-header"><b>Institute Information</b></div>
                            <div className="card-body">
                                <div className='d-flex flex-row justify-content-between'>
                                    <div className='col-md-6'>
                                        <p className='card-text'>Established</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <p className='card-text'>{userPersonalData.dob}</p>
                                    </div>
                                </div>
                                <div className='d-flex flex-row justify-content-between mt-2'>
                                    <div className='col-md-6'>
                                        <p className='card-text'>Location</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <p className='card-text'>{permanentAddress.city}, {permanentAddress.country}</p>
                                    </div>
                                </div>
                                {/* <div className='d-flex flex-row justify-content-between mt-2'>
                                    <div className='col-md-6'>
                                        <p className='card-text'>Sport</p>
                                    </div>
                                    <div className='col-md-6'>
                                        <p className='card-text'>{userPersonalData.sport_type}</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="card text-bg-light mb-3">
                            <div className="card-header"><b>Achievements</b></div>
                            <div className="card-body">
                                {achievementList}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className='profileInfo mx-3'>
                            <h4><b>Profile</b></h4>
                            <p>
                                {profileBio}...
                            </p>
                            {profileBio.length > 100 &&
                                <div className='float-end'>
                                    <button type="button" className="btn btn-link more-text-format" data-bs-toggle="modal" data-bs-target="#profileModal">More...</button>
                                </div>
                            }
                        </div>

                        {sportProfile.map(profile => {
                            if (profile.profile_type === 'Institution' && profile.is_active) {
                                return (
                                    <InstituteProfile
                                        website={clubData.website}
                                        clubHistoryList={clubHistoryList}
                                    />
                                )
                            }
                        })}

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

